type FormatTableDataFromJSONTypes = {
  folderId: string,
  tableData: any[],
}

export function formatTableDataFromJSON({folder, forms}: {folder:string, forms: any[]}): FormatTableDataFromJSONTypes {
  const tableData = forms || [];
  return {
    folderId: folder,
    tableData,
  };
}

export function createTableFromSearchJSON({searchResults}: { searchResults: any[] }): FormatTableDataFromJSONTypes {
  const tableData = searchResults || [];
  return formatTableDataFromJSON({
    folder: '',
    forms: tableData,
  });
}

export const getIsNgOrLegacy = ({itemType, isNgLiveViewExcluded, isNgLiveViewIncompatible}): string => {
  // If the form is a Doc, the form cannot be NG so we leave it blank
  if (itemType === 'Doc') {
    return '';
  } else if (isNgLiveViewExcluded || isNgLiveViewIncompatible) {
    // The form has been excluded or found incompatible with NG live view
    return 'Legacy';
  }
  // We can assume that the form is NG because this column is only used when the
  // account-level feature flag for NG live view is enabled
  return 'Spark'; // We have renamed "NG" to "Spark" in the UI
};

export const getNgOrLegacyHoverText = ({itemType, isNgLiveViewExcluded, isNgLiveViewIncompatible}): string | null => {
  if (
    itemType === 'Doc' ||
    (!isNgLiveViewExcluded && !isNgLiveViewIncompatible)
  ) {
    return null;
  }
  const reasons: string[] = [];
  if (isNgLiveViewExcluded) {
    reasons.push('Opted out');
  }
  if (isNgLiveViewIncompatible) {
    reasons.push('Incompatible');
  }
  return reasons.join(', ');
};
