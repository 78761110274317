import React, { forwardRef, useImperativeHandle } from 'react';
import FormDropDown from '../FormDropDown/FormDropDown';
import style from './FormFullDate.css';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';


export const generateYears = (startYear: number, endYear: number) => {
  const calculationStart: number = Number.isInteger(endYear) ? endYear : (new Date()).getFullYear() - 80;
  const calculationEnd: number = Number.isInteger(startYear) ? startYear : calculationStart + 90;
  const length: number = calculationEnd - calculationStart + 1;
  return Array.from({ length }, (v, k) => ({ value: k + calculationStart, label: k + calculationStart }));
};

const days = [{ value: '', label: 'DD' }, ...(Array.from({ length: 31 }, (v, k) => ({ value: k + 1, label: k + 1 })))];
const months = [
  { value: '', label: 'MM' },
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

type Props = {
  id: string,
  label?: string,
  fieldState?: ControlState | null,
  updateForm?: Function | undefined,
  specialSettings?: {
    label?: string,
    labelAlign: LabelAlignment,
    showLabel?: boolean,
    startYear: string,
    endYear: string,
    requiredFields: {
      day: boolean,
      month: boolean,
      year: boolean,
    },
  },
  cssClass?: string,
};

function FormFullDate({
  id,
  label = 'Full Date',
  fieldState,
  updateForm,
  specialSettings = {
    showLabel: true,
    startYear: '',
    endYear: '',
    requiredFields: { day: false, month: false, year: false },
    labelAlign: 'auto',
  },
  cssClass = sharedStyle.FormControlGroup,
  hoverText = '' }: Props & Partial<any>, ref) {
  let monthRef;
  let dayRef;
  let yearRef;

  const { startYear, endYear } = specialSettings;

  const years = [{ value: '', label: 'YYYY' }, ...generateYears(Number.parseInt(startYear, 10), Number.parseInt(endYear, 10))];

  const handleChange = fieldName => event => {
    const fs = {
      ...fieldState?.fields,
      [fieldName]: event.target.value,
    };
    updateForm && updateForm({
      fields: fs,
    });
  };

  const selfValidate = () => monthRef.validate() && dayRef.validate() && yearRef.validate();

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'form_control_group')} id={`container_${id}`}>
        <fieldset id={id} className={sharedStyle.Fieldset}>
          { specialSettings?.showLabel &&
            <legend className={getAlignmentClass(specialSettings.labelAlign, sharedStyle)}>{label}</legend> }
          <div className={style.SubContainer}>
            <div>
              <FormDropDown
                label='Month'
                id={`${id}_month`}
                options={months}
                onChange={handleChange('month')}
                required={specialSettings?.requiredFields?.month}
                ref={el => {
                  monthRef = el;
                }}
              /></div>
            <div>
              <FormDropDown
                label='Day'
                id={`${id}_day`}
                options={days}
                onChange={handleChange('day')}
                required={specialSettings?.requiredFields?.day}
                ref={el => {
                  dayRef = el;
                }}
              />
            </div>
            <div>
              <FormDropDown
                label='Year'
                id={`${id}_year`}
                options={years}
                onChange={handleChange('year')}
                required={specialSettings?.requiredFields?.year}
                ref={el => { yearRef = el; }}
              /></div>
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormFullDate));
