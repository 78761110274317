import {combineReducers} from 'redux';
import paymentGatewaySidebar, * as paymentGatewaySidebarSelectors from './paymentGatewaySidebar';
import dataSyncSidebar, * as dataSyncSidebarSelectors from './dataSyncSidebar';
import automatedProcessBuilder, * as apbSelectors from 'reducers/ui/automatedProcessBuilder';
import participantsSidebar, * as participantsSidebarSelectors from './participantsSidebar';
import dataDictionarySidebar, * as dataDictionarySidebarSelectors from './dataDictionarySidebar';
import govOsPayModal, * as govOsPayModalSelectors from 'embed/reducers/ui/govOsPayModal';
import digitalServiceFeeSidebar, * as digitalServiceFeeSelectors from './digitalServiceFeeSidebar';

export default combineReducers({
  automatedProcessBuilder,
  paymentGatewaySidebar,
  dataSyncSidebar,
  participantsSidebar,
  dataDictionarySidebar,
  govOsPayModal,
  digitalServiceFeeSidebar,
});

/* PAYMENT GATEWAY SIDEBAR */
export const getIsPaymentGatewaySidebarOpen = state =>
  paymentGatewaySidebarSelectors.getIsModalOpen(state.paymentGatewaySidebar);

export const getPaymentFormId = state =>
  paymentGatewaySidebarSelectors.getFormId(state.paymentGatewaySidebar);

export const getConfirmationConfig = state =>
  paymentGatewaySidebarSelectors.getConfirmationConfig(state.paymentGatewaySidebar);

export const getShouldRenderConfirmation = state =>
  paymentGatewaySidebarSelectors.getShouldRenderConfirmation(state.paymentGatewaySidebar);

/*  APB */
export const isApbVisible = state => apbSelectors.isApbVisible(state.automatedProcessBuilder);

export const getVisibleEmailRuleId = state =>
  apbSelectors.getVisibleEmailRuleId(state.automatedProcessBuilder);

export const isApbLoading = state => apbSelectors.isApbLoading(state.automatedProcessBuilder);

export const getApbFormId = state => apbSelectors.getLegacyFormId(state.automatedProcessBuilder);

/* Data Sync */
export const getIsDataSyncSidebarOpen = state =>
  dataSyncSidebarSelectors.getIsModalOpen(state.dataSyncSidebar);

export const getInitialConfig = state =>
  dataSyncSidebarSelectors.getInitialConfig(state.dataSyncSidebar);

export const getCurrentConfig = state =>
  dataSyncSidebarSelectors.getCurrentConfig(state.dataSyncSidebar);


/* Participants */
export const getIsParticipantsSidebarOpen = state =>
  participantsSidebarSelectors.getIsModalOpen(state.participantsSidebar);

export const getParticipantsFormId = state =>
  participantsSidebarSelectors.getFormId(state.participantsSidebar);

export const getParticipantsErrors = state =>
  participantsSidebarSelectors.getErrors(state.participantsSidebar);

export const getIsAddParticipantTabVisible = state =>
  participantsSidebarSelectors.getIsAddParticipantTabVisible(state.participantsSidebar);

export const getParticipantType = state =>
  participantsSidebarSelectors.getParticipantType(state.participantsSidebar);

export const getCurrentParticipant = state =>
  participantsSidebarSelectors.getCurrentParticipant(state.participantsSidebar);

export const getExistingParticipants = state =>
  participantsSidebarSelectors.getExistingParticipants(state.participantsSidebar);

export const getIsEditEmailUiVisible = state =>
  participantsSidebarSelectors.getIsEditEmailUiVisible(state.participantsSidebar);

export const getEmailTypeBeingEdited = state =>
  participantsSidebarSelectors.getEmailTypeBeingEdited(state.participantsSidebar);

export const getSubmitter = state =>
  participantsSidebarSelectors.getSubmitter(state.participantsSidebar);

export const getIncludeLegalDisclaimer = state =>
  participantsSidebarSelectors.getIncludeLegalDisclaimer(state.participantsSidebar);

export const getSendSignatureReminders = state =>
  participantsSidebarSelectors.getSendSignatureReminders(state.participantsSidebar);

export const getHasCurrentParticipantChanged = state =>
  participantsSidebarSelectors.getHasCurrentParticipantChanged(state.participantsSidebar);

export const getIndexOfParticipantBeingEdited = state =>
  participantsSidebarSelectors.getIndexOfParticipantBeingEdited(state.participantsSidebar);

export const getCurrentEmailSetting = state =>
  participantsSidebarSelectors.getCurrentEmailSetting(state.participantsSidebar);

export const getHasCurrentEmailSettingChanged = state =>
  participantsSidebarSelectors.getHasCurrentEmailSettingChanged(state.participantsSidebar);

export const getHasParticipantsDataChanged = state =>
  participantsSidebarSelectors.getHasParticipantsDataChanged(state.participantsSidebar);

export const getAreParticipantsLoading = state =>
  participantsSidebarSelectors.getAreParticipantsLoading(state.participantsSidebar);

export const getIsAddGroupTabVisible = state =>
  participantsSidebarSelectors.getIsAddGroupTabVisible(state.participantsSidebar);

export const getCurrentGroup = state =>
  participantsSidebarSelectors.getCurrentGroup(state.participantsSidebar);

export const getParallelGroups = state =>
  participantsSidebarSelectors.getParallelGroups(state.participantsSidebar);

export const getIndexOfGroupBeingEdited = state =>
  participantsSidebarSelectors.getIndexOfGroupBeingEdited(state.participantsSidebar);

export const getHasCurrentGroupChanged = state =>
  participantsSidebarSelectors.getHasCurrentGroupChanged(state.participantsSidebar);

export const getIsLabelInputInFocus = state =>
  participantsSidebarSelectors.getIsLabelInputInFocus(state.participantsSidebar);

/* DATA DICTIONARY */
export const isDataDictionarySidebarVisible = state =>
  dataDictionarySidebarSelectors.isDataDictionarySidebarVisible(state.dataDictionarySidebar);

/* GOV OS PAY MODAL */
export const isGovOsPayModalVisible = state => govOsPayModalSelectors.isGovOsPayModalVisible(state.govOsPayModal);

export const getGovOsPaymentFormId = state =>
  govOsPayModalSelectors.getFormId(state.govOsPayModal);

/* DIGITAL SERVICE FEE SIDEBAR */
export const getIsDigitalServiceFeeSidebarOpen = state =>
  digitalServiceFeeSelectors.isSidebarOpen(state.digitalServiceFeeSidebar);

export const getDigitalServiceFeeFormId = state => digitalServiceFeeSelectors.getFormId(state.digitalServiceFeeSidebar);
