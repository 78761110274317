import React, {ChangeEvent, FocusEvent, forwardRef, memo, useState} from 'react';
import {ControlState, LabelAlignment, ShowLabelValue} from 'types/liveView';
import {getAlignmentClass} from 'utils/formLiveView/formLiveView';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import styles from './CurrencyFormat.css';
import {formattingHandlers} from 'utils/currencyFormatUtils';

export interface CurrencyFormatProps {
  id: string;
  label: string;
  isDisabled: boolean;
  elementType: string;
  excludedFromPreview: boolean;
  extraData: {
    alignment: LabelAlignment;
    label: string;
    labelAlign: LabelAlignment;
    showLabel: ShowLabelValue;
  };
  fieldState: ControlState | null;
  properties?: any;
  required: boolean;
  showOtherOption: boolean;
  specialSettings: {
    formatting: string;
    isRequired: boolean;
    label: string;
    labelAlign: LabelAlignment;
    showLabel: boolean;
  };
  updateForm?: (s: { fields: { [p: string]: string | null } }) => void;
}


const CurrencyFormat = forwardRef((props: CurrencyFormatProps, ref) => {
  const {
    id,
    required,
    updateForm,
    specialSettings: { label, formatting, showLabel, labelAlign },
  } = props;

  const [error, setError] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  const selfValidate = (): boolean => {
    const isValid = required ? !!inputValue.trim() : true;
    setError(isValid ? '' : 'This field is required');
    return isValid;
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    const formattedValue = formattingHandlers[formatting]?.(inputValue) || inputValue;
    setInputValue(formattedValue);
    selfValidate();

    if (updateForm) {
      const s = {
        fields: { [label]: formattedValue },
      };
      updateForm(s);
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputValueStr = e.target.value || '';
    setInputValue(inputValueStr);
  };
  return (
    <div ref={ref as React.RefObject<HTMLDivElement>} data-testid='currencyFormat'>
      {showLabel ? (
        <label
          aria-label={label}
          htmlFor={id}
          className={classNames(sharedStyle.FormLabel, { [sharedStyle.Required]: required }, 'field_label', getAlignmentClass(labelAlign, sharedStyle))}
        >
          {label}
        </label>
      ) : null}
      <ErrorContainer error={error}>
        <input
          id={id}
          value={inputValue}
          onBlur={onBlurHandler}
          disabled={props.isDisabled}
          className={classNames(styles.FormInput, 'field_input', `${id}-font-settings`)}
          onChange={onChangeHandler}
          data-testid='currencyFormatInput'
        />
      </ErrorContainer>
    </div>
  );
});

export default memo(CurrencyFormat);
