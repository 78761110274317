import React from 'react';
import color from 'react-color/lib/helpers/color';
import { ColorWrap, Swatch } from 'react-color/lib/components/common';
import { FONT_COLORS_FOR_PICKER } from 'utils/richTextEditor/colorsForPicker';
import styles from './styles.css';

const swatchStyle = {
  flexGrow: 1,
  height: 30,
  borderRadius: 4,
  marginRight: 8,
};


export const ColorPicker = ({ onChange }) => {
  const handleChange = (hex, event) => {
    color.isValidHex(hex) && onChange(event, {
      hex,
      source: 'hex',
    });
  };

  return (
    <div className={styles.colorPickerWrapper} role='presentation'>
      <div className={styles.colorPickerBody}>
        {FONT_COLORS_FOR_PICKER.map((_color, key) => (
          <Swatch
            key={key}
            color={_color}
            hex={_color}
            style={swatchStyle}
            onClick={handleChange} />
        ))}
      </div>
    </div>
  );
};

export default ColorWrap(ColorPicker);
