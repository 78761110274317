import React from 'react';
import { connect } from 'react-redux';
import { map, without } from 'lodash';
import FieldItem, { RichField } from 'components/FieldItem';
import fieldStyles from 'components/FieldItem/FieldItem.css';
import styles from 'components/SubmissionDetails/SubmissionDetails.css';
import FieldBox from 'components/SubmissionDetails/FieldBox';
import PaymentBoxContent from 'components/SubmissionDetails/PaymentBoxContent';
import PaymentContent from 'containers/SubmissionsManager/PaymentContent';
import GovOSPaymentContent from 'containers/SubmissionsManager/GovOSPaymentContent';
import IdVerificationContent from 'containers/SubmissionsManager/IdVerificationContent';
import AttachmentBoxContent from 'components/SubmissionDetails/AttachmentBoxContent';
import SignatureStatusBox from 'components/SubmissionDetails/SignatureStatusBox';
import AssignmentsBox from 'components/SubmissionDetails/AssignmentsBox';
import CompletionStatusCell from 'components/SubmissionTable/CompletionStatusCell';
import EncryptedCell from 'components/SubmissionTable/EncryptedCell';
import TagsBox from 'components/SubmissionDetails/TagsBox';
import StagesBox from 'components/SubmissionDetails/StagesBox';
import Loader from 'components/SidebarLoader';
import { pluralize } from 'humanize-plus';
import * as dateHelper from 'utils/dateHelper';
import injectFeatures from 'decorators/injectFeatures';
import injectPermissions from 'decorators/injectPermissions';
import { filterLabelsByConditions, shouldDisplayCompletionColumn } from 'utils/submissionsManager/tableHelper';
import {
  ASSIGNMENTS_COLUMN_LABEL,
  COMPLETION_COLUMN_LABEL,
  TAGS_COLUMN_LABEL,
  STAGES_COLUMN_LABEL,
  PAYMENT_STATUS_COLUMN_LABEL,
  GOVOS_PAYMENT_STATUS_COLUMN_LABEL,
  ID_VERIFICATION_STATUS_COLUMN_LABEL,
} from 'constants/tableConsts';
import { isPaymentKey, findPaymentField, mapPaymentNodeToObject } from 'utils/submissionsManager/payment';
import { submissionManagerPermissions } from 'constants/submissionManager';
import { getUserRole } from 'reducers/index';
import { isVisitor } from 'utils/auth';

class SubmissionDetails extends React.Component<any, any> {
  renderCompletionStatus = () => {
    const { selectedRowData } = this.props;
    return (
      <li className={styles.withBottomBorder} key={COMPLETION_COLUMN_LABEL}>
        <RichField
          label='Status'
          fieldContent={
            <CompletionStatusCell isComplete={false} showButton submissionId={selectedRowData.submissionId} />
          }
        />
      </li>
    );
  };

  handleOpenDecryptModal = nodeData => {
    const { selectedRowIndex, openDecryptModal } = this.props;
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      openDecryptModal(nodeData.raw_value, selectedRowIndex, nodeData.input_name);
    }
  };

  render() {
    const {
      editSignerVisible,
      features,
      handleReNotify,
      onEditSignerModalToggle,
      onRefundModalToggle,
      onSignerDetailsToggle,
      recordDetails,
      refundVisible,
      selectedRowData,
      sortedFilteredColumns,
      signerDetailsVisible,
      trackDownloadFile,
      shouldRenderLoader,
      onSignNow,
      isArchiveView,
      isVisitorUser,
    } = this.props;

    if (!selectedRowData) {
      return null;
    }

    if (shouldRenderLoader) {
      return <Loader />;
    }

    const { attachments, createdTs, nodes } = selectedRowData;

    const isPaymentReadOnly: boolean = !!(
      selectedRowData.permissions &&
      !selectedRowData.permissions.includes(submissionManagerPermissions.canExecuteTransactions)
    ) || isArchiveView || selectedRowData.isArchived || isVisitorUser;

    const signatureHistoryBox = recordDetails && (
      <li key='signatureStatus'>
        <FieldBox label='Signature Status'>
          <SignatureStatusBox
            signatureHistory={recordDetails.signatureHistory}
            signatureStatus={recordDetails.signatureHistory.status}
            onEditSignerModalToggle={onEditSignerModalToggle}
            handleReNotify={handleReNotify}
            editSignerVisible={editSignerVisible}
            signerDetailsVisible={signerDetailsVisible}
            onSignerDetailsToggle={onSignerDetailsToggle}
            onSignNow={onSignNow}
          />
        </FieldBox>
      </li>
    );

    const shouldRenderField = fieldObject => {
      const hasNoSignatureRawData =
        !!fieldObject && !!fieldObject.raw_value && !fieldObject.raw_value.includes('{"fullname":');
      const hasNoPaymentData = !!fieldObject && !!fieldObject.input_name && !isPaymentKey(fieldObject.input_name);
      return (
        !!fieldObject &&
        !!fieldObject.raw_value &&
        !!fieldObject.input_name &&
        hasNoPaymentData &&
        hasNoSignatureRawData
      );
    };

    let details: any[] | null = null;
    if (nodes && sortedFilteredColumns.length > 0) {
      const conditionalColumns = [
        {
          label: PAYMENT_STATUS_COLUMN_LABEL,
          shouldDisplay: !selectedRowData.isIncomplete && !!selectedRowData.payments,
        },
        {
          label: GOVOS_PAYMENT_STATUS_COLUMN_LABEL,
          shouldDisplay: !selectedRowData.isIncomplete && !!selectedRowData.govOsPayments,
        },
        {
          label: COMPLETION_COLUMN_LABEL,
          shouldDisplay: shouldDisplayCompletionColumn([selectedRowData], features),
        },
        {
          label: ASSIGNMENTS_COLUMN_LABEL,
          shouldDisplay:
            this.props.canUserReadAssignedSubmissions() &&
            !selectedRowData.isIncomplete &&
            !!features.ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT,
        },
        {
          label: STAGES_COLUMN_LABEL,
          shouldDisplay: !selectedRowData.isIncomplete && !!features.STAGES_IN_SUBMISSION_MANAGER_SUPPORT,
        },
        {
          label: TAGS_COLUMN_LABEL,
          shouldDisplay:
            this.props.canUserAccessTags() &&
            !selectedRowData.isIncomplete &&
            !!features.TAGS_IN_SUBMISSION_MANAGER_SUPPORT,
        },
        {
          label: ID_VERIFICATION_STATUS_COLUMN_LABEL,
          shouldDisplay: selectedRowData.idVerificationData &&
            selectedRowData.idVerificationData.length > 0 &&
            features.ID_VERIFICATION,
        },
      ];
      const columnsAtTop = filterLabelsByConditions(conditionalColumns);
      const newTableSortOrder = [
        'createdTs',
        ...columnsAtTop,
        'fieldsTitle',
        ...without(sortedFilteredColumns, ...map(conditionalColumns, 'label'), 'attachment', 'createdTs'),
      ];
      details = newTableSortOrder.map(node => {
        switch (node) {
          case COMPLETION_COLUMN_LABEL:
            return this.renderCompletionStatus();
          case ASSIGNMENTS_COLUMN_LABEL:
            return (
              <li className={styles.withBottomBorder} key={ASSIGNMENTS_COLUMN_LABEL}>
                <RichField
                  label='Assigned to'
                  fieldContent={
                    <AssignmentsBox
                      assigneeIds={selectedRowData.assignees}
                      isReadOnly={
                        !!(
                          selectedRowData.permissions &&
                          !selectedRowData.permissions.includes(submissionManagerPermissions.canEditAssignees)
                        ) || isArchiveView || selectedRowData.isArchived
                      }
                      submissionId={selectedRowData.submissionId}
                    />
                  }
                />
              </li>
            );
          case TAGS_COLUMN_LABEL:
            return (
              <li className={styles.withBottomBorder} key={TAGS_COLUMN_LABEL}>
                <RichField
                  label='Tags'
                  fieldContent={
                    <TagsBox
                      isReadOnly={
                        !!(
                          selectedRowData.permissions &&
                          !selectedRowData.permissions.includes(submissionManagerPermissions.canEditTags)
                        ) || isArchiveView || selectedRowData.isArchived
                      }
                      submissionId={selectedRowData.submissionId}
                      tagNames={selectedRowData.tags}
                    />
                  }
                />
              </li>
            );
          case STAGES_COLUMN_LABEL:
            return (
              <li className={styles.withBottomBorder} key={STAGES_COLUMN_LABEL}>
                <RichField
                  label='Stage'
                  fieldContent={
                    <StagesBox
                      isReadOnly={
                        !!(
                          selectedRowData.permissions &&
                          !selectedRowData.permissions.includes(submissionManagerPermissions.canEditStages)
                        ) || isArchiveView || selectedRowData.isArchived
                      }
                      submissionId={selectedRowData.submissionId}
                      stageName={selectedRowData.stageName}
                    />
                  }
                />
              </li>
            );
          case PAYMENT_STATUS_COLUMN_LABEL: {
            return (
              <li key={PAYMENT_STATUS_COLUMN_LABEL}>
                <PaymentContent
                  features={features}
                  paymentDetails={selectedRowData.payments}
                  isReadOnly={isPaymentReadOnly}
                />
              </li>
            );
          }
          case GOVOS_PAYMENT_STATUS_COLUMN_LABEL: {
            return (
              <li key={GOVOS_PAYMENT_STATUS_COLUMN_LABEL}>
                <GovOSPaymentContent
                  features={features}
                  paymentDetails={selectedRowData.govOsPayments}
                  isReadOnly={isPaymentReadOnly}
                />
              </li>
            );
          }
          case ID_VERIFICATION_STATUS_COLUMN_LABEL: {
            return (
              <li key={ID_VERIFICATION_STATUS_COLUMN_LABEL}>
                <IdVerificationContent
                  idVerificationData={selectedRowData.idVerificationData}
                />
              </li>
            );
          }
          case 'createdTs':
            return (
              <li key='createdTs' className={styles.createdTs}>
                Created on: {dateHelper.getFormattedTime(createdTs, dateHelper.TIME_FORMAT_LONG)}
              </li>
            );
          case 'signatureStatus':
            return signatureHistoryBox;
          case 'fieldsTitle':
            return (
              <li className={styles.fieldsTitle} key='fieldsTitle'>
                Fields
              </li>
            );
          default:
            if (shouldRenderField(nodes[node])) {
              return (
                <li key={node} className={styles.otherFields}>
                  {nodes[node].isEncrypted && nodes[node].raw_value ? (
                    <div className={styles.encrypted}>
                      <p className={fieldStyles.fieldsLabel}>{nodes[node].label}</p>
                      <EncryptedCell onClick={() => this.handleOpenDecryptModal(nodes[node])} />
                    </div>
                  ) : (
                    <FieldItem
                      label={nodes[node].label}
                      fieldValue={nodes[node].raw_value}
                      fieldMap={nodes[node].field_map}
                    />
                  )}
                </li>
              );
            }
            return null;
        }
      });
    }

    // payment data from backend is still missing processor: i.e. "SeamlessPay"
    let payment;
    let processor;
    const paymentField = findPaymentField(selectedRowData);
    if (paymentField) {
      payment = mapPaymentNodeToObject(selectedRowData.payment);
      processor = paymentField.label;
    }

    return (
      <div className={styles.detailsContainer}>
        <div className={styles.metaCard}>
          <ul className={styles.fieldsList}>
            {details}
            {payment && processor && (
              <li>
                <FieldBox label='Payment'>
                  <PaymentBoxContent
                    amount={payment.amount}
                    attribute={payment.attribute}
                    status={payment.status}
                    processor={processor}
                    transactionType={payment.transactionType}
                    transactionDate={payment.transactionDate}
                    transactionMode={payment.transactionMode}
                    paymentEffectiveDate={payment.paymentEffectiveDate}
                    paymentString={payment.paymentString}
                    transactionID={payment.transactionId}
                    authCode={payment.authCode}
                    refundVisible={refundVisible}
                    onRefundModalToggle={onRefundModalToggle}
                  />
                </FieldBox>
              </li>
            )}
            {attachments.length > 0 && (
              <li>
                <FieldBox label={`${attachments.length} ${pluralize(attachments.length, 'Attachment')}`}>
                  <AttachmentBoxContent attachments={attachments} trackDownloadFile={trackDownloadFile} />
                </FieldBox>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { submissionTable: { isArchiveView } } = state;
  return {
    isArchiveView,
    isVisitorUser: isVisitor(getUserRole(state)),
  };
};

export default connect(
  mapStateToProps,
)(injectPermissions(injectFeatures(SubmissionDetails)));
