import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CustomPicker } from 'react-color';
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common';
import style from './CanvasSketch.css';
import useOutsideClick from 'hooks/useOutsideClick';

interface AccessibleEditableInputProps {
  value: string;
  onChange: (color: { hex: string }) => void;
  label: string;
}

const AccessibleEditableInput: React.FC<AccessibleEditableInputProps> = ({
  value,
  onChange,
  label,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const inputElement = inputRef.current.querySelector('input');

      if (inputElement) {
        inputElement.setAttribute('aria-label', label);
      }
    }
  }, [label]);

  return (
    <div ref={inputRef}>
      <EditableInput
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

const ColorPicker = ({ hex, hsl, hsv, onChange }) => {
  const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false);
  const handleOutsideClick = useCallback(() => setIsPickerVisible(false), []);
  const handleClick = useCallback(() => setIsPickerVisible(true), []);
  const ref = useOutsideClick(handleOutsideClick);

  const handleChangeComplete = (color: any) => {
    onChange(color);
  };

  return (
    <div
      aria-label='Color picker'
      ref={ref}
      onClick={handleClick}
    >
      <div className={style.ColorPickerInput}>
        <AccessibleEditableInput
          value={hex}
          onChange={handleChangeComplete}
          label='Hex color code'
        />
      </div>
      { isPickerVisible && (<div
        role='dialog'
        aria-modal='true'
        aria-label='Color selection dialog'
        className={style.ColorPickerModal}
      >
        <div className={style.Hue}>
          <Hue hsl={hsl} onChange={onChange} />
        </div>
        <div className={style.Saturation}>
          <Saturation hsl={hsl} hsv={hsv} onChange={onChange} />
        </div>
      </div>
      )}
    </div>
  );
};


export default CustomPicker(ColorPicker);

