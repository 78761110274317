import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from '../Modals/BulkActionWrapper.css';
import { OutlinedInput } from '@material-ui/core';

const StagesSelect = ({selectedStage, handleChange, options = []}) => (<Select
  displayEmpty
  value={selectedStage}
  onChange={handleChange}
  classes={{ select: styles.select }}
  MenuProps={{
    disablePortal: true,
  }}
  input={
    <OutlinedInput
      name={'move'}
      id={'move'}
      labelWidth={0}
      classes={{ root: styles.input, focused: styles.focusedInput }} />
  }
  variant='outlined'
  renderValue={selected => {
    if (!selected) {
      return <span>Select a stage</span>;
    }
    return selected as string;
  }}>
  {options.map(stage => (
    // @ts-ignore
    <MenuItem key={stage.label} value={stage.label} className={styles.option}>
      {/** @ts-ignore */}
      {stage.label}
    </MenuItem>
  ))}
  {!options.length && <MenuItem value='No stages' className={styles.option} disabled>
    No stages
  </MenuItem>}
</Select>);

export default StagesSelect;
