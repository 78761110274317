import React, { Dispatch, memo, MouseEvent, SetStateAction } from 'react';
import styles from '../govOsPay.css';
import { PaymentAmounts, PaymentType } from 'types/govOsPay/govOsPaymentSettings';

type Props = {
  icon: string;
  paymentType: PaymentType;
  paymentAmounts: PaymentAmounts;
  name: string;
  feeName: string;
  setGovOsPaymentMethodsModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPaymentSummaryType: Dispatch<SetStateAction<PaymentType | null>>;
}


const PaymentCard = (props: Props) => {
  const {
    icon,
    paymentType,
    name,
    setGovOsPaymentMethodsModalOpen,
    setSelectedPaymentSummaryType,
  } = props;
  const selectPaymentHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setGovOsPaymentMethodsModalOpen(false);
    setSelectedPaymentSummaryType(paymentType);
  };
  return (
    <div
      role='group'
      className={styles.paymentCard}
      aria-labelledby={`paymentMethod-${paymentType}`}
    >
      <div className={styles.paymentMethodIconContainer}>
        <h2 id={`paymentMethod-${paymentType}`} className={styles.visuallyHidden}>{`${paymentType} payment card`}</h2>
        <img src={icon} alt={`${name} icon`} />
      </div>
      <span
        className={styles.paymentCardTitle}
        id={`paymentMethodTitle-${name}`}
      >
        {name}
      </span>
      <button
        className={styles.paymentCardPayButton}
        onClick={selectPaymentHandler}
        aria-label={`Select payment form ${name}`}
      >
        Select
      </button>
    </div>
  );
};

export default memo(PaymentCard);
