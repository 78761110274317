import React from 'react';
import ReactDOM from 'react-dom/client';
import { syncHistoryWithStore } from 'react-router-redux';
import { browserHistory } from 'react-router';
import { initSplitSdk } from '@splitsoftware/splitio-redux';
import Root from 'containers/Root';
import '@kofile/gds-foundations';
import './styles/main.css';
import './styles/fixed-data-table.css';
import './styles/filestack.css';
import store from './store';

const appContainerElement = document.getElementById('app');
if (appContainerElement) {
  const root = ReactDOM.createRoot(appContainerElement);

  const CONFIG = {
    core: {
      authorizationKey: 'nf6la0prrjrks14opg5fuqno3s2nlje1njt8',
      key: '55epev8a305806cuqsclv2cs9ormosrk0o9d',
    },
  };

  store.dispatch(
    initSplitSdk({
      config: CONFIG,
    }) as any
  );

  const history = syncHistoryWithStore(browserHistory, store);
  root.render(
    <Root store={store} history={history} />,
  );

  if ((module as any).hot) {
    (module as any).hot.accept('./containers/Root', () => {
      const NextRoot = require('./containers/Root').default;
      root.render(
        <NextRoot store={store} history={history} />,
      );
    });
  }
}

