import React from 'react';
import styles from './icons.css';
import AccessibleSVG from 'components/AccessibleSVG';


const CrossIcon = props => (
  <button {...props} className={styles.crossIcon}>
    <AccessibleSVG width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg' title='Collapse sidebar' aria-label='Collapse sidebar'>
      <line x1='7.58678' y1='7.77441' x2='19.7896' y2='19.9773' stroke='#6D7D8F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='19.7896' y1='7.81139' x2='7.58669' y2='20.0143' stroke='#6D7D8F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </AccessibleSVG>
  </button>
);

export default CrossIcon;
