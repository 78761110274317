import React, { useState } from 'react';
import { EditorState, ContentState } from 'draft-js';

import Loader from 'components/Loader';
import Button from 'components/Button';
import LargeModal from 'components/Modals/LargeModal';
import RichTextEditor from 'components/RichTextEditor';
import ErrorContainer from '../../form-comps/ErrorContainer/ErrorContainer';
import { editorStateToStructure } from 'utils/richTextEditor/conversion';
import { shareSubmissionFromThankYouPage as shareSubmissionFromThankYouPageAction } from 'actions/forms';
import { validateEmailAddress } from 'utils/email';

import styles from './ShareModal.css';

type Props = {
  closeModal: () => void,
  formId: string,
  formName: string,
  isOpen: boolean,
  isLoading: boolean,
  maid: string,
  shareSubmissionError: string | null,
  shareSubmissionFromThankYouPage: typeof shareSubmissionFromThankYouPageAction,
  token: string,
}

const ShareModal = ({
  closeModal,
  formId = '',
  formName = '',
  isOpen = false,
  isLoading = false,
  maid = '',
  shareSubmissionError = null,
  shareSubmissionFromThankYouPage,
  token = '',
}: Props) => {
  const [recipientsInput, setRecipientsInput] = useState<string>('');
  const [recipientsError, setRecipientsError] = useState<string>('');
  const [subjectInput, setSubjectInput] = useState<string>(`Submission Shared: ${formName}`);
  const [message, setMessage] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText('I want to share this submission document with you.'))
  );

  const idForError = 'recipientsInputError';

  const validate = (): boolean => {
    if (!recipientsInput) {
      setRecipientsError('Please enter at least one recipient email address.');
      return false;
    }
    if (!recipientsInput.split(',').every(validateEmailAddress)) {
      setRecipientsError('Please enter valid email addresses.');
      return false;
    }
    setRecipientsError('');
    return true;
  };

  const handleSend = () => {
    if (!validate()) return;
    const recipients = recipientsInput.split(',').map(r => r.trim());
    const messageData = editorStateToStructure(message);
    const subject = subjectInput;
    if (!formId || !maid || !token) return;
    shareSubmissionFromThankYouPage(formId, maid, messageData, recipients, subject, token);
  };

  const renderActions = () =>
    <div className={styles.ButtonWrapper}>
      <Button primary={false} label='Cancel' onClick={closeModal} />
      <Button primary={false} primaryBlue label='Send' onClick={handleSend} />
    </div>;

  const renderLoader = () => <Loader />;

  const renderBody = () => (
    <form onSubmit={e => e.preventDefault()}>
      <div className={styles.FieldWrapper}>
        <label htmlFor='recipientsInput' className={styles.Label}>Recipient(s)</label>
        <ErrorContainer error={recipientsError} id={idForError}>
          <input
            type='text'
            id='recipientsInput'
            value={recipientsInput}
            onChange={e => setRecipientsInput(e.target.value)}
            className={styles.Input}
            placeholder="Separate recipients' email addresses with a comma"
            onBlur={validate}
            required
            aria-required
            aria-describedby={recipientsError ? idForError : undefined}
            aria-invalid={!!recipientsError}
          />
        </ErrorContainer>
      </div>
      <div className={styles.FieldWrapper}>
        <label htmlFor='subjectInput' className={styles.Label}>Subject</label>
        <input
          type='text'
          id='subjectInput'
          value={subjectInput}
          onChange={e => setSubjectInput(e.target.value)}
          className={styles.Input}
        />
      </div>
      <div className={styles.FieldWrapper}>
        <label htmlFor='message' className={styles.Label}>Message:</label>
        <RichTextEditor
          id='message' editorState={message}
          onChange={data => setMessage(data)}
          ariaLabel='Message input field'
          ariaDescribedBy='text editor'
        />
      </div>
      {shareSubmissionError && <div className={styles.ErrorWrapper}>{shareSubmissionError}</div>}
    </form>
  );

  return (
    <LargeModal
      title='Share By Email'
      titleIcon='share'
      onClose={closeModal}
      open={isOpen}
      actions={renderActions()}
    >
      {isLoading ? renderLoader() : renderBody()}
    </LargeModal>
  );
};

export default ShareModal;
