import React, { memo, useEffect, useRef } from 'react';
import arrowBack from 'icons/arrowBack.svg';
import styles from '../govOsPay.css';

type ModalWithOverlayProps = {
  open: boolean;
  closeModal: () => void;
  isSelectPaymentTypeModal?: boolean;
  isSummaryModal?: boolean;
  children: React.ReactNode;
}

const OverlayModalContainer = (
  {
    open,
    closeModal,
    isSelectPaymentTypeModal,
    isSummaryModal,
    children,
  }: ModalWithOverlayProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open && modalRef.current) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ) as NodeListOf<HTMLElement>;
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            if (document.activeElement === firstElement) {
              lastElement.focus();
              event.preventDefault();
            }
          } else {
            if (document.activeElement === lastElement) {
              firstElement.focus();
              event.preventDefault();
            }
          }
        } else if (event.key === 'Escape') {
          closeModal();
        }
      };

      const handleFocus = (event: FocusEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          firstElement?.focus();
        }
      };

      firstElement?.focus();
      modalRef.current.addEventListener('keydown', handleKeyDown);
      document.addEventListener('focus', handleFocus, true);
      return () => {
        modalRef.current?.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('focus', handleFocus, true);
      };
    }

    return () => {
    };
  }, [open, closeModal]);

  return (
    <div className={styles.modalOverlay} >
      <div
        role='dialog'
        aria-modal='true'
        ref={modalRef}
        className={
          isSelectPaymentTypeModal || isSummaryModal ?
            styles.modalContainer :
            styles.govOsPaymentMethodModalContainer
        }
        aria-labelledby='modal-header'
        tabIndex={-1}
      >
        <button
          onClick={closeModal}
          className={styles.backToFormButton}
          aria-label='Close modal and go back to form'
        >
          <img
            src={arrowBack}
            alt=''
            aria-hidden='true' />
          Back to form
        </button>
        <div
          className={
            isSelectPaymentTypeModal ?
              styles.modalContentWrapper :
              styles.govOsPaymentMethodModal
          }>
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(OverlayModalContainer);
