import React, {useEffect, ChangeEventHandler} from 'react';
import RecipientsSelector from './RecipientsSelector';
import RichTextEditor from 'components/RichTextEditor';
import Checkbox from 'components/Checkbox';
import FormFieldSelect from 'components/FormFieldSelect';
import ToggleCheckbox from '@material-ui/icons/CheckBox';
import * as Colors from 'utils/colors';
import { FormFields } from 'types/formFields';
// import type {EditorState} from 'draft-js';
// import type {Email, SyntheticInputEventHandler} from 'types/shared';
// import type {Recipients} from 'types/automatedProcessBuilder';

import styles from './EmailEditor.css';

interface Props {
  email: any,
  formId: string,
  onBodyChange: ChangeEventHandler,
  onRecipientsChange: (recipientResolver: any) => void,
  onSubjectChange: ChangeEventHandler,
  setInitialEmail: (email: {
    initialBody: any,
    initialRecipientResolver: any[],
    initialSubject: string,
    initialHasLinkToSubmissionManager: boolean,
    initialAttachedPdf: boolean,
    initialFieldIds: string[]
  }) => void,
  onAttachedPdfChange: Function,
  onIncludeSMLinkChange: Function,
  onIncludeFieldsChange: (fields: FormFields) => void,
  formFields: FormFields,
}

function getProperSMLinkValue(hasLink: boolean | undefined | null) {
  switch (hasLink) {
    case undefined:
    case true:
      return true;
    default:
      return false;
  }
}

const EmailEditor = ({
  email: {body, recipientResolver, subject = '', attachedPdf, hasLinkToSubmissionManager, fieldIds = []},
  formId,
  onBodyChange,
  onRecipientsChange,
  onSubjectChange,
  onAttachedPdfChange,
  onIncludeSMLinkChange,
  onIncludeFieldsChange,
  setInitialEmail,
  formFields = [],
}: Props) => {
  const labelId = 'emailEditorBody';

  const checkSMLink = getProperSMLinkValue(hasLinkToSubmissionManager);
  useEffect(() => {
    setInitialEmail({
      initialBody: body,
      initialRecipientResolver: recipientResolver,
      initialSubject: subject,
      initialHasLinkToSubmissionManager: getProperSMLinkValue(hasLinkToSubmissionManager),
      initialAttachedPdf: attachedPdf,
      initialFieldIds: fieldIds,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.emailEditorAppearance} data-testid='custom-email-picker'>
      <div>
        <div className={styles.labeledRow}>
          <div className={styles.labeledRowLabel}>TO</div>
          <RecipientsSelector
            formId={formId}
            onChange={onRecipientsChange}
            value={recipientResolver}/>
        </div>
      </div>
      <div className={styles.labeledRow}>
        <div className={styles.labeledRowLabel}>SUBJECT</div>
        <input
          className={styles.emailEditorSubject}
          type='text'
          name='subject'
          placeholder="i.e. You've been assigned something"
          onChange={onSubjectChange}
          value={subject}/>
      </div>
      <div>
        <RichTextEditor ariaLabelledBy={labelId} editorState={body} onChange={onBodyChange}/>
      </div>
      <div className={styles.labeledRow}>
        <Checkbox
          checked={checkSMLink}
          label='Include link to Submission Manager'
          labelStyle={{fontSize: '14px', fontWeight: 400, width: 'calc(100% - 12px)'}}
          onCheck={onIncludeSMLinkChange as React.ChangeEventHandler}
          checkedIcon={<ToggleCheckbox style={{fill: Colors.blue500}}/>}
          style={{display: 'inline-block'}}/>

        <Checkbox
          checked={attachedPdf || false}
          label='Include PDF as an attachment'
          labelStyle={{fontSize: '14px', fontWeight: 400, width: 'calc(100% - 12px)'}}
          onCheck={onAttachedPdfChange as React.ChangeEventHandler}
          checkedIcon={<ToggleCheckbox style={{fill: Colors.blue500}}/>}
          style={{display: 'inline-block'}}/>
      </div>
      <FormFieldSelect
        fieldIds={fieldIds}
        onFieldsChange={onIncludeFieldsChange}
        formFields={formFields}
      />
    </div>
  );
};

export default EmailEditor;
