import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getAccountKey } from 'actions/auth';
import { getFormPublicInfo } from 'actions/forms';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import classNames from 'classnames';
import { ACCESS_CODE } from 'constants/route';
import { getSubmissionDetails, getSubmissionUrl, logSession } from '../../simpleApi/submissions';
import { getProfile, isLoggedIn } from 'utils/auth';
import useWindowDimensions from 'utils/dimensions';
import print from 'print-js';
import Dialog from '@material-ui/core/Dialog';
import styles from './SubmissionView.css';
import brandLogo from 'images/brand-logo.svg';
import ZoomIn from 'icons/submissions/zoomIn.svg';
import ZoomOut from 'icons/submissions/zoomOut.svg';
import Print from 'icons/submissions/print.svg';
import Download from 'icons/submissions/download.svg';
import Attachment from 'icons/submissions/attachment.svg';
import Warning from 'icons/warning-triangle.svg';
import Loader from 'containers/FormLiveView/Loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

interface Props {
  formId: string;
  submissionId: string;
  userId: string;
  maid: string;
  dispatch: Dispatch<any>;
  companyName: string;
  logo: string;
  form?: any;
}

const SESSION_TIMEOUT = 10 * 60 * 1000;

function SubmissionView({
  formId,
  submissionId,
  attachmentId,
  accessCode,
  userId,
  maid,
  companyName,
  logo,
  dispatch,
  form,
  router,
  ...props
}: Props & Partial<any>) {
  const [pdf, setPdf] = useState<Blob | null>(null);
  const [pdfInfo, setPdfInfo] = useState<any>(null);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [submission, setSubmission] = useState<any>(null);
  const [loadError, setLoadError] = useState<Error | null>(null);
  const [expired, setExpired] = useState(false);
  const [touchesDist, setTouchesDist] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { width: screenWidth } = useWindowDimensions();
  let clear: any = null;
  const zoomStep = 5;

  const getPDFBlob = async(pdfUrl: string): Promise<Blob> => {
    try {
      const response = await fetch(pdfUrl);

      if (!response.ok) {
        setLoadError(new Error(`Failed to load PDF: ${response.status} ${response.statusText}`));
      }

      return await response.blob();
    } catch (error) {
      throw new Error(`Failed to load PDF: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const navigateToAttachment = (attachments: any[]) => {
    window.location.href = attachments.find(attachment => attachment.attachmentId === attachmentId)?.url;
  };

  const getSetSubDetails = async() => {
    try {
      setIsLoading(true);
      const sub = await getSubmissionDetails(formId, submissionId, accessCode, maid);
      const submissionUrlResponse = await getSubmissionUrl(submissionId, accessCode, maid);
      if (attachmentId) {
        navigateToAttachment(sub.attachments);
      }
      sub.submissionPDFUrlSigned = submissionUrlResponse?.result?.signedUrl;
      const blob = await getPDFBlob(submissionUrlResponse?.result?.signedUrl);
      setSubmission(sub);
      setPdf(blob);
    } catch (error) {
      setLoadError(error);
      router.push(ACCESS_CODE);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!maid) {
      props.getAccountKey(dispatch);
    }
    return () => {
      clearTimeout(clear);
    };
  }, []);

  useEffect(() => {
    if (maid && formId && submissionId) {
      logSession(submissionId);
      props.getFormPublicInfo(formId, maid);
      getSetSubDetails();
    }
  }, [maid, formId, submissionId]);

  const waitForExpiration = () => {
    clear = setTimeout(() => {
      clearTimeout(clear);
      setExpired(true);
    }, SESSION_TIMEOUT);
  };

  const onPDFLoaded = ({ numPages }: { numPages: number }) => {
    setPdfInfo({ numPages });
    if (!isLoggedIn()) {
      waitForExpiration();
    }
  };

  const onClickSidePage = (pageIndex: number): MouseEventHandler => () => {
    const el = document.querySelector(`.${styles.viewer}`);
    if (el) {
      const pageEl = el.querySelectorAll(`.${styles.PdfPage}`)[pageIndex];
      const h = pageEl?.clientHeight ?? 0;
      el.scrollTop = h * pageIndex;
    }
  };

  const renderAllPages = (
    width: number,
    shouldZoom: boolean = false,
    pageNumbers: boolean = false,
    addClickHandler: boolean = false,
  ) => {
    const { numPages } = pdfInfo || {};
    const arr = Array(numPages || 0).fill(0);
    return arr.map((_, index) => (
      <div
        key={index}
        className={styles.PdfPage}
        onClick={addClickHandler ? onClickSidePage(index) : () => {}}
      >
        <Page
          pageNumber={index + 1}
          width={width}
          scale={shouldZoom ? zoomLevel / 100 : 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
        {pageNumbers ? <div className={styles.page__number}>{index + 1}</div> : null}
      </div>
    ));
  };

  const renderAttachments = () => {
    if (submission?.attachments?.length) {
      return submission.attachments.map((a: any) => (
        <li key={a.url}>
          <a title={a.label} href={a.url} target='_blank'>
            <img src={Attachment} alt={a.label} />{a.label}
          </a>
        </li>
      ));
    }
    return 'No Attachments';
  };

  const errorMessage = (
    <div className={styles.errorContainer}>
      <img src={Warning} alt='access denied' />
      <h1>Access Denied</h1>
      <span>You do not have permission to view this submission.<br />Please contact account administrator.</span>
    </div>
  );

  const onKeepWorking = () => {
    setExpired(false);
    waitForExpiration();
  };

  const onEndSession = () => {
    props.router.replace('/ng/login');
  };

  const expirationModal = (
    <Dialog style={{ borderRadius: 5 }} open={expired}>
      <div className={styles.expiration__modal}>
        <img src={Warning} alt='session expire' />
        <div><h2 style={{ fontSize: '2rem' }}>Session Expiring</h2></div>
        <div>
          <p style={{ color: '#888' }}>Your session is about to expire. If you need more time, click below to continue.</p>
          <div>
            <button onClick={onEndSession}>End Session</button>&nbsp;&nbsp;&nbsp;
            <button onClick={onKeepWorking}>Keep Working</button>
          </div>
        </div>
      </div>
    </Dialog>
  );

  const onZoomIn: MouseEventHandler = () => {
    if (zoomLevel >= 200) return;
    setZoomLevel(zoomLevel + 10);
  };

  const onZoomOut: MouseEventHandler = () => {
    if (zoomLevel <= 50) return;
    setZoomLevel(zoomLevel - 10);
  };

  const onPrint: MouseEventHandler = () => {
    print({ printable: submission.submissionPDFUrlSigned });
  };

  const onDownload: MouseEventHandler = () => {
    const a = document.createElement('a');
    a.setAttribute('href', URL.createObjectURL(pdf as Blob));
    a.setAttribute('download', submission.submissionPDFUrl?.split('/').pop());
    a.click();
  };

  const onPDFLoadError = (error: Error) => {
    setLoadError(error);
  };

  const buttons = useMemo(() => (
    <div className={styles.button__container}>
      <button
        type='button'
        disabled={!pdf}
        title='Zoom In'
        aria-label='Zoom In'
        onClick={onZoomIn}
        hidden={!!loadError}
      >
        <img src={ZoomIn} alt='zoom in' />
      </button>
      <button
        type='button'
        disabled={!pdf}
        title='Zoom Out'
        aria-label='Zoom Out'
        onClick={onZoomOut}
        hidden={!!loadError}
      >
        <img src={ZoomOut} alt='zoom out' />
      </button>
      <button
        type='button'
        disabled={!pdf}
        title='Print'
        aria-label='Print'
        onClick={onPrint}
        hidden={!!loadError}
      >
        <img src={Print} alt='print' />
      </button>
      <button
        type='button'
        disabled={!pdf}
        title='Download'
        aria-label='Download'
        onClick={onDownload}
        hidden={!!loadError}
      >
        <img src={Download} alt='download' />
      </button>
    </div>
  ), [zoomLevel, pdf, loadError]);

  const renderAccountHeader = () => {
    const loggedIn = isLoggedIn();
    const profile = getProfile();
    const links = (
      <div className={styles.account__header}>
        {
          loggedIn
            ? (<span>Welcome back, {profile.name} - {loadError ?
              (<a href={'/ng/lobby'}>Go to Lobby</a>) :
              (<a href={`/ng/submissions/${formId}#${submissionId}`}>View in Submission Manager</a>)
            }</span>)
            : (<a href={`/ng/login?redirect_url=${location.pathname}`}>Log In or Sign Up</a>)
        }
      </div>
    );
    return (
      <header className={styles.header}>
        <div className={styles.headerWrapper}>
          <div className={styles.logo__name} title={form?.formName}>
            <img src={logo} alt={companyName} className={styles.logo__image} />
            <span>{form?.formName}</span>
          </div>
          {buttons}
          {screenWidth >= 1024 && links}
        </div>
        {screenWidth < 1024 && links}
      </header>
    );
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    if (event.touches.length === 2) {
      const newTouchesDist = Math.sqrt(
        Math.pow(event.touches[0].clientX - event.touches[1].clientX, 2) +
        Math.pow(event.touches[0].clientY - event.touches[1].clientY, 2)
      );
      setTouchesDist(newTouchesDist);
    }
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    if (event.touches.length === 2 && (zoomLevel <= 250 && zoomLevel >= 50)) {
      const newTouchesDist = Math.sqrt(
        Math.pow(event.touches[0].clientX - event.touches[1].clientX, 2) +
        Math.pow(event.touches[0].clientY - event.touches[1].clientY, 2)
      );
      setTouchesDist(newTouchesDist);
      const newZoomLevel = newTouchesDist > touchesDist ? zoomLevel + zoomStep : zoomLevel - zoomStep;
      if (newZoomLevel >= 50 && newZoomLevel <= 250) {
        setZoomLevel(newZoomLevel);
      }
    }
  };

  return (
    <div className={styles.container}>
      {renderAccountHeader()}
      <main className={styles.main}>
        <div className={styles.viewer} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loadError ? (
            errorMessage
          ) : isLoading || !pdf ? (
            <Loader />
          ) : (
            <Document
              file={pdf}
              onLoadSuccess={onPDFLoaded}
              onLoadError={onPDFLoadError}
              loading={<Loader />}
              noData={<Loader />}
            >
              <div>
                {renderAllPages(screenWidth < 800 ? screenWidth : 800, true)}
              </div>
            </Document>
          )}
        </div>
      </main>
      <footer
        className={classNames(styles.footer, !submission?.attachments?.length && styles.footer__noAttachments)}
        tabIndex={-1}
      >
        <div
          className={classNames(styles.footer__viewer,
            { [styles.footer__viewerScroll]: submission?.attachments?.length })}
        >
          {
            loadError || !pdf ? null :
              (<Document
                file={pdf}
                loading=''
                noData=''
              >
                {renderAllPages(150, false, true, true)}
              </Document>
              )
          }
        </div>
        {
          submission?.attachments?.length
            ? (<div className={classNames(styles.attachments, styles.attachments__scroll)} tabIndex={-1}>
              <div>
                <div>Attachments</div>
                <ul>{renderAttachments()}</ul>
              </div>
            </div>)
            : null
        }
      </footer>
      {expirationModal}
    </div>
  );
}

const mapStateToProps = ((state: any, { router }: any) => {
  const { auth, forms } = state;
  const formId = router.params.formId;
  return {
    formId,
    submissionId: router.params.submissionId,
    attachmentId: router.params.attachmentId,
    maid: auth.maid || '',
    userId: auth.userId || '',
    accountKey: auth.accountKey,
    accessCode: auth.accessCode,
    companyName: auth.companyName,
    logo: auth.organizationLogo || brandLogo,
    form: formId ? forms?.publicInfo?.[formId] || null : null,
    router,
  };
});

const mapDispatchToProps = ((dispatch: Dispatch<any>) => ({
  ...bindActionCreators({
    getAccountKey,
    getFormPublicInfo,
  }, dispatch),
  dispatch,
}));

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionView);
