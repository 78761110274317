import React from 'react';
import styles from './icons.css';
import AccessibleSVG from 'components/AccessibleSVG';

const ArrowIcon = ({ title }) => (
  <div className={styles.relativeContainer}>
    <AccessibleSVG className={styles.centeredIcon} width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg' title={title} aria-label={title}>
      <path d='M0.976807 9.86237L5.36087 5.48428' stroke='#1B2735' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='1.36873' y1='1.33691' x2='5.43552' y2='5.40371' stroke='#1B2735' strokeLinecap='round' strokeLinejoin='round' />
    </AccessibleSVG>
  </div>
);

export default ArrowIcon;
