import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as lobbyActions from 'actions/lobby/lobbyActions';
import * as tableApi from 'actions/lobby/tableApiActions';
import Loader from 'components/Loader';
import TableLoader from 'components/Loader/TableLoader';
import FormsTable from 'components/FormsTable';
import { INFINITE_SCROLL_TRIGGER } from 'constants/tableConsts';
import { isArchiveFolder } from 'utils/lobby/folders';

import {
  getCurrentFolderId,
  getFormsForLobbyById,
  getVisibleFormColumns,
  isQuickFilterSelected,
} from '../../reducers';


class FormsList extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { tableData, showLobbyWithFormId } = this.props;
    this.props.tableApi.requestInitialFormLoad();

    const formIndex = tableData.findIndex(form => form.formId === showLobbyWithFormId);
    if (~formIndex && showLobbyWithFormId) {
      this.props.lobbyActions.selectRow(formIndex, tableData[formIndex]);
      /*
      * Intentionally over-scroll below because the default scrolled to element
      * is at the bottom and tends to fall easibly below area of visibility
      * I don't need to protect index going out of bound since the table will auto
      * scroll to the last element when it does
      */
      this.props.lobbyActions.scrollToRow(formIndex + 5);
      setTimeout(() => {
        this.props.lobbyActions.resetScrollToRow();
      }, 0);
    }
  }

  rowClassNameGetter = index => {
    const { selectedRowIndex, archivingSelectedIndex } = this.props;
    if (_.isNumber(selectedRowIndex) && _.isEqual(selectedRowIndex, index)) {
      return 'selectedRow';
    }
    if ((archivingSelectedIndex === index)) {
      return 'archiving';
    }
    return '';
  };

  onScrollY = scrollY => {
    this.setState({ scrollY });
    this.onScrollUpdate(this.state.maxScrollY, scrollY);
  }

  onContentHeightChange = contentHeight => {
    const maxScrollY = contentHeight - this.props.height;
    this.setState({ maxScrollY });
    this.onScrollUpdate(maxScrollY, this.state.scrollY);
  }

  onScrollUpdate = (maxScrollY, scrollY) => {
    const { fetching } = this.props;
    if (
      maxScrollY != null
      && scrollY != null
      && !fetching
    ) {
      const pixelsToBottom = maxScrollY - scrollY;
      if (pixelsToBottom < INFINITE_SCROLL_TRIGGER) {
        this.props.tableApi.requestSubsequentFormLoad();
      }
    }
  };

  onRowClick = (event, index, selectedRowData) => {
    this.props.clearSearchBar();
    this.props.lobbyActions.selectRow(index, selectedRowData);
  };

  handleSetFormDetails = selectedRowData => {
    this.props.lobbyActions.setFormDetails(selectedRowData);
  };

  render() {
    const {
      containerStyles,
      width,
      height,
      mounted,
      tableData,
      scrollToRow,
      isInArchive,
      fetching,
      fetchingMoreResults,
      columns,
      canDragRows,
    } = this.props;

    const inlineStyles = {
      container: {
        order: '2',
        position: 'relative',
        width,
        ...containerStyles,
      },
    };
    const canDoubleClickRows = !isInArchive;
    const showSubmissionCount = !isInArchive;
    /**
     * If the table is mounted AND its fetching more forms
     * OR its not fetching the initial forms
     */
    if (mounted && (!fetching || fetchingMoreResults)) {
      return (
        <div style={inlineStyles.container} data-testid='formListContainer'>
          <FormsTable
            canDoubleClickRows={canDoubleClickRows}
            canDragRows={canDragRows}
            columns={columns}
            height={height}
            isInArchive={isInArchive}
            onContentHeightChange={this.onContentHeightChange}
            onRowClick={this.onRowClick}
            onScrollY={_.debounce(this.onScrollY, 200)}
            rowClassNameGetter={this.rowClassNameGetter}
            scrollToRow={scrollToRow}
            setFormDetails={this.handleSetFormDetails}
            showSubmissionCount={showSubmissionCount}
            tableData={tableData}
            width={width} />
          {fetchingMoreResults && <TableLoader />}
        </div>
      );
    }
    return (
      <div style={inlineStyles.container} data-testid='formListContainer'>
        <Loader top />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  lobbyActions: bindActionCreators(lobbyActions, dispatch),
  tableApi: bindActionCreators(tableApi, dispatch),
});

const mapStateToProps = state => {
  const {
    lobby: {
      scrollToRow,
      selectedRowIndex,
      showLobbyWithFormId,
    },
    lobbyTable: {
      archivingSelectedIndex,
      failure,
      fetching,
      fetchingMoreResults,
      tableData,
      nextPageToken,
    },
  } = state;
  const currentFolderId = getCurrentFolderId(state);
  const isInArchive = isArchiveFolder(currentFolderId);
  const isInQuickFilter = isQuickFilterSelected(state);
  return {
    archivingSelectedIndex,
    canDragRows: !isInArchive && !isInQuickFilter,
    columns: getVisibleFormColumns(state),
    failure,
    fetching,
    fetchingMoreResults,
    scrollToRow,
    selectedRowIndex,
    showLobbyWithFormId,
    tableData: getFormsForLobbyById(state, tableData),
    nextPageToken,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsList);
