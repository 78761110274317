import React, { CSSProperties, MouseEventHandler } from 'react';
import MaterialUIButton from '@material-ui/core/Button';
import * as Colors from 'utils/colors';
import buttonStyle from './button.css';
// import type {Style, SyntheticEventHandler} from 'types/shared';


const inlineStyles: CSSProperties = Object.freeze({
  backgroundColor: Colors.grey500,
  border: 'none',
  borderRadius: '4px',
  color: Colors.white,
  cursor: 'pointer',
  fontSize: '15px',
  height: '42px',
  lineHeight: '18px',
  minWidth: 0,
  outline: 0,
  padding: '12px 4px',
  textDecoration: 'none',
  transition: 'all 0.4s ease 0s',
  userSelect: 'none',
});

const ellipsifiedInlineStyles = {
  ...inlineStyles,
  maxWidth: '100%',
};

type Props = {
  disabled?: boolean,
  ellipsified?: boolean,
  label: string,
  onClick?: MouseEventHandler<HTMLButtonElement>;
  primary?: boolean,
  primaryBlue?: boolean, // transitional prop meant to become `primary` at some point
  secondary?: boolean,
  secondaryBlack?: boolean,
  // style?: Style,
  style?: CSSProperties,
  tabIndex?: number,
};

export default class Button extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    ellipsified: false,
    primary: false,
  };
  render() {
    const {
      disabled,
      ellipsified,
      label,
      onClick,
      primary,
      primaryBlue,
      secondary,
      secondaryBlack,
      style,
      tabIndex,
    } = this.props;
    const specificInlineStyles = {
      ...style,
      ...(ellipsified ? ellipsifiedInlineStyles : inlineStyles),
    };

    if (disabled) {
      specificInlineStyles.cursor = 'not-allowed';
      specificInlineStyles.backgroundColor = Colors.grey50;
      specificInlineStyles.color = Colors.textPrimary;
    } else if (primary) {
      specificInlineStyles.backgroundColor = Colors.orange500;
    } else if (primaryBlue) {
      specificInlineStyles.backgroundColor = Colors.seamlessDocsBlue;
    } else if (secondary) {
      specificInlineStyles.backgroundColor = Colors.grey400;
    } else if (secondaryBlack) {
      specificInlineStyles.backgroundColor = 'none';
      specificInlineStyles.color = Colors.newPrimaryText;
    }

    const labelKlass = ellipsified ? buttonStyle.ellipsifiedLabel : buttonStyle.label;
    return (
      <MaterialUIButton
        disabled={disabled}
        onClick={onClick}
        classes={{ root: buttonStyle.button, label: labelKlass }}
        variant='text'
        style={specificInlineStyles}
        tabIndex={tabIndex}>
        {label}
      </MaterialUIButton>
    );
  }
}
