import React, { useState } from 'react';
import classNames from 'classnames';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './ExpansionField.css';


export const ExpansionField = ({
  label,
  value,
  expansionItems,
  isOpenByDefault,
}) => {
  const [isVisible, setVisibility] = useState(isOpenByDefault);

  return (
    <div className={styles.expansionFieldContainer}>
      <div className={styles.expansionFieldTotalInfoContainer}>
        <div className={styles.expansionFieldLabelContainer}>
          <p className={styles.expansionFieldLabel}>{label}</p>

          {!!expansionItems.length && (
            <ExpandMoreIcon
              className={classNames(styles.expansionFieldIcon, {
                [styles.expansionFieldIcon_rotate]: isVisible,
              })}
              onClick={() => {
                setVisibility(!isVisible);
              }}
              data-testid='expansionFieldIcon'
            />
          )}
        </div>

        <p className={styles.expansionFieldValue}>{value}</p>
      </div>

      {isVisible && (
        <div className={styles.expansionFieldDetails}>
          {expansionItems.map((item, index) => (
            <div key={index} className={styles.expansionFieldLabelContainer}>
              <div className={styles.expansionFieldLabelContainer}>
                <p className={styles.expansionFieldLabel}>{item.label}</p>
              </div>
              <p className={styles.expansionFieldValue}>{item.value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ExpansionField.defaultProps = {
  isOpenByDefault: true,
};

export default ExpansionField;
