import React from 'react';
import PropTypes from 'prop-types';

interface RouterContext {
  router: any;
}

export default function injectRouter(Component: React.ComponentType<any>) {
  return class extends React.Component {
    static contextTypes = {
      router: PropTypes.object.isRequired,
    };

    context!: RouterContext;

    render() {
      return (
        <Component {...this.props} router={this.context.router} />
      );
    }
  };
}
