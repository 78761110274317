import React from 'react';
import styles from './Header.css';
import AccessibleSVG from 'components/AccessibleSVG';

const StagesIcon = () => (
  <div className={styles.container}>
    <AccessibleSVG className={styles.stagesIcon} width='24' height='30' viewBox='0 0 24 30' fill='none' xmlns='http://www.w3.org/2000/svg' aria-label='stages'>
      <path d='M23.2369 2.76895C23.2664 2.23927 23.0604 1.76844 22.7367 1.41532C22.3836 1.03278 21.8539 0.82679 21.3242 0.82679L8.11166 0.797363C7.9351 0.797363 7.69968 0.82679 7.52312 0.944496L0.549019 7.94803C0.519592 7.97745 0.519592 7.97745 0.490166 8.00688C0.343033 8.24229 0.28418 8.44828 0.28418 8.68369V27.6933C0.28418 28.7526 1.16698 29.6354 2.22634 29.6354H21.3242C22.3836 29.6354 23.2664 28.7526 23.2664 27.6933L23.2369 2.76895Z' fill='#6D7D8F' fillOpacity='0.22'/>
      <line x1='7.10837' y1='10.2333' x2='16.4424' y2='10.2333' stroke='white' strokeWidth='2.0312' strokeLinecap='round' strokeLinejoin='round'/>
      <line x1='7.10837' y1='16.5245' x2='16.4424' y2='16.5245' stroke='white' strokeWidth='2.0312' strokeLinecap='round' strokeLinejoin='round'/>
      <line x1='7.10837' y1='22.8155' x2='10.7598' y2='22.8155' stroke='white' strokeWidth='2.0312' strokeLinecap='round' strokeLinejoin='round'/>
    </AccessibleSVG>
  </div>
);

export default StagesIcon;
