import React, { memo } from 'react';
import { Button } from '@kofile/gds-react';
import buttonStyles from '@kofile/gds-foundations/build/css/components/button.module.css';
import styles from '../IdVerification.css';
import warningTriangleIcon from 'icons/warning-triangle_grey.svg';
import WithFocusTrap from '../WithFocustrap';

const ERROR_HEADER_TEXT = 'Error';
const ERROR_BODY_TEXT = 'An error occurred while fetching verified data.';
const RETRY_TEXT = 'Retry';
const CANCEL_TEXT = 'Start Over';

type Props = {
  onRetry: () => void;
  onCancel: () => void;
  open: boolean;
};

const FetchError: React.FC<Props> = ({ onRetry, onCancel, open }) => (
  <WithFocusTrap open={open} isContentLoaded>
    <div className={styles.fetchErrorContainer}>
      <div className={styles.fetchErrorContentWrapper}>
        <img src={warningTriangleIcon} alt='Warning icon' />
        <div className={styles.fetchErrorHeader}>{ERROR_HEADER_TEXT}</div>
        <div className={styles.fetchErrorText}>{ERROR_BODY_TEXT}</div>
      </div>
      <div className={styles.fetchErrorButtonContainer}>
        <Button
          onClick={onCancel}
          className={buttonStyles.button}
          variant='neutral'
          background='outlined'
          size='lg'
          width='full'
        >
          {CANCEL_TEXT}
        </Button>
        <Button
          onClick={onRetry}
          className={buttonStyles.button}
          variant='primary'
          size='lg'
          width='full'
        >
          {RETRY_TEXT}
        </Button>
      </div>
    </div>
  </WithFocusTrap>
);

export default memo(FetchError);
