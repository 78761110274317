
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const NgSettingsToggle = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#fff',
      '& + $bar': {
        backgroundColor: '#1093f5',
      },
    },
    '&$checked + $bar': {
      backgroundColor: '#1093f5',
      opacity: '1',
    },
  },
  checked: {},
  bar: {},
  colorPrimary: {},
})(Switch);

export default NgSettingsToggle;
