import React from 'react';

import styles from './FormLiveView.css';
import arrowBack from 'icons/arrowBack.svg';
import { PaymentMethod } from 'types/liveView';

type Props = {
  open: boolean,
  closeModalHandler: () => void,
  paymentMethods: PaymentMethod[],
  isPaymentOptional: boolean,
  onJustSubmit: React.MouseEventHandler,
}

export const PaymentMethodsModal = ({
  open,
  closeModalHandler,
  paymentMethods,
  isPaymentOptional = false,
  onJustSubmit,
}: Props) => {
  const renderPaymentMethod = (method: PaymentMethod) => {
    const { name, icon, amount, openPaymentModal } = method;

    return (
      <div className={styles.paymentMethod} key={`paymentMethod-${name}`}>
        <div className={styles.paymentIconContainer}>
          <img src={icon} alt={name} />
        </div>
        <span className={styles.paymentMethodName}>{name}</span>
        <p className={styles.paymentAmount}>{amount}</p>
        <button
          aria-label={`Pay with ${name}`}
          className={styles.selectPaymentMethodButton}
          onClick={openPaymentModal}>
          Pay
        </button>
      </div>
    );
  };

  const renderModal = () => (<div className={styles.modalOverlay}>
    <div className={styles.paymentMethodsModal}>
      <div className={styles.backToFormButton} onClick={closeModalHandler}>
        <img src={arrowBack} alt='Back to form button' className={styles.arrowBack} />
        Back to form
      </div>
      <div className={styles.paymentMethodsModalContent}>
        <h2 className={styles.paymentMethodModalHeader}>Select payment method</h2>
        <div className={styles.paymentsContainer}>
          {paymentMethods.map(method => renderPaymentMethod(method))}
        </div>
        {isPaymentOptional &&
          <button onClick={onJustSubmit} className={styles.submitWithoutPaying}>Submit without paying</button>}
      </div>
    </div>
  </div>);

  return open ? renderModal() : null;
};
