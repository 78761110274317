import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import injectFeatures from 'decorators/injectFeatures';
import ActiveDirectoryAccounts from 'components/Login/ActiveDirectoryAccounts';
import FieldContainer from '../../FieldContainer/FieldContainer';

import styles from './LoginForm.css';

const FormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please supply a valid email.')
    .required('This can’t be blank.'),
  password: Yup.string().required('This can’t be blank.'),
});

const LoginForm = ({
  onSubmit,
  features,
  handleActiveDirectoryLogin,
  activeDirectoryConnections,
  isProcessing,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const form = useRef(null);

  useEffect(() => {
    if (error && form.current) {
      // @ts-ignore
      form.current.setErrors({
        email: ' ',
        password: error,
      });
    }
  });

  return (
    <>
      {activeDirectoryConnections.length !== 0 && (
        <div className={styles.activeDirectoryContainer}>
          <ActiveDirectoryAccounts
            connections={activeDirectoryConnections}
            onConnectionChoice={handleActiveDirectoryLogin}
          />
        </div>
      )}

      {!features.SSO_LOGIN_ONLY && activeDirectoryConnections.length !== 0 && (
        <div className={styles.loginTypeDelimiter}>
          <span className={styles.loginTypeDelimiterText}>OR</span>
        </div>
      )}

      {!features.SSO_LOGIN_ONLY && (
        <Formik
          innerRef={form}
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={FormSchema}
          onSubmit={values => {
            onSubmit(values.email, values.password);
          }}
        >
          {({ isValid }) => (
            <Form>
              <div className={styles.form}>
                <div className={styles.emailContainer}>
                  <FieldContainer name='email' label='Email'>
                    {(field, meta) => (
                      <input
                        aria-label='Email'
                        className={classNames(styles.input, {
                          [styles.invalidInput]: meta.touched && meta.error,
                        })}
                        type='text'
                        {...field}
                      />
                    )}
                  </FieldContainer>
                </div>

                <div className={styles.passwordContainer}>
                  <FieldContainer name='password' label='Password'>
                    {(field, meta) => (
                      <div className={styles.passwordField}>
                        <input
                          aria-label='Password'
                          className={classNames(styles.input, {
                            [styles.invalidInput]: meta.touched && meta.error,
                          })}
                          type={showPassword ? 'text' : 'password'}
                          {...field}
                        />
                        <IconButton
                          className={styles.showPassword}
                          role='switch'
                          aria-checked={showPassword}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          <Icon>
                            {showPassword ? 'visibility_off' : 'visibility'}
                          </Icon>
                        </IconButton>
                      </div>
                    )}
                  </FieldContainer>
                </div>

                <div className={styles.submitBtnContainer}>
                  <button
                    className={classNames(styles.submitBtn, {
                      [styles.submitBtnInvalid]: (!isValid && !error) || isProcessing,
                    })}
                    type='submit'
                    disabled={(!isValid && !error) || isProcessing}
                  >
                    Login
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default injectFeatures(LoginForm);
