import React from 'react';
import UserSelector from 'components/Users/UserSelector';
import { filterToEligibleAuthors } from 'utils/assignees';
import styles from './AuthorSelector.css';


class AuthorSelector extends React.Component<any, any> {
  handleAuthorChange = newAuthor => {
    this.props.changeAuthor(newAuthor.userId);
  }

  render() {
    const {
      author,
      isPhone,
      users,
    } = this.props;
    const eligibleAuthors = filterToEligibleAuthors(author, users);
    return (
      <div className={styles.container} data-testid='author-selector'>
        <span className={styles.label}>Author:</span>
        <UserSelector
          data-testid='user-selector'
          onChangeUser={this.handleAuthorChange}
          shouldRepositionForMobile={isPhone}
          user={author}
          users={eligibleAuthors}
        />
      </div>
    );
  }
}

export default AuthorSelector;
