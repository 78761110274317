import React from 'react';
import { nanoid } from 'nanoid';
import StagesDropdown from './StagesDropdown';
import StageDot from 'components/Stages/StageDot';
import Tooltip from 'components/Tooltip';
import styles from './StageSelector.css';
import { DOWN_KEY, UP_KEY, SPACE_KEY, ENTER_KEY, _getKeyboardCode } from 'utils/keyboard';
import { joinClassNames } from 'utils/strings';
import { NO_PERMISSIONS_MESSAGE } from 'constants/stages';
import { getColor } from 'utils/stageColors';


class StageSelector extends React.Component<any, any> {
  static defaultProps = {
    canCreate: false,
    isReadOnly: true,
    maxWidth: '160px',
    stages: [],
  }

  state = {
    dropdownOpen: false,
    tooltipContent: '',
  }

  componentDidMount() {
    this.setTooltipContent();
    this.dropdownId = `stage-selector-dropdown-${nanoid()}`;
  }

  componentUid = nanoid();
  dropdownId = '';

  closeDropdown = () => {
    this.setState({ dropdownOpen: false }, () => (this.refs.bubble as any).focus());
  }

  setTooltipContent = (content?: string) => {
    const stageName = this.props.selectedStage ? this.props.selectedStage.name : '';
    const tooltipContent = content || stageName;
    this.setState({
      tooltipContent,
    });
  }

  openDropdown = () => {
    this.setState({ dropdownOpen: true });
  }

  renderChangeStageButton = stage => (
    <Tooltip title={this.state.tooltipContent}>
      <React.Fragment>
        <StageDot
          className={styles.dot}
          radiusInPx={7}
          color={stage.color} />
        <span className={styles.label}>
          {stage.name}
        </span>
      </React.Fragment>
    </Tooltip>
  )

  hideTooltip = () => this.setState({ tooltipContent: '' });
  resetTooltipContent = () => {
    /* For some reason, if the content of the tooltip changes while the dropdown is open,
    the dropdown changes its position to the lower left corner of the screen. */
    if (!this.state.dropdownOpen) this.setTooltipContent();
  }

  onSelectStage = stage => {
    if (!stage) return;
    this.closeDropdown();
    this.props.onSelectStage(stage);
  }

  onClearStage = () => {
    this.closeDropdown();
    this.props.onClearStage();
  }

  handleOnClick = event => {
    // Stoping the propagation here prevents the submission details panel
    // from opening when this is hosted in the submission table
    event.stopPropagation();
    if (this.props.isReadOnly) {
      this.setTooltipContent(NO_PERMISSIONS_MESSAGE);
      return;
    }
    this.openDropdown();
  }

  handleOnKeyDown = event => {
    const key = _getKeyboardCode(event);
    switch (key) {
      case UP_KEY:
      case DOWN_KEY:
      case SPACE_KEY:
      case ENTER_KEY:
        event.preventDefault();
        this.openDropdown();
        break;
      default:
        return;
    }
  }

  render() {
    const { canCreate, isReadOnly, maxWidth, selectedStage: stage, stages } = this.props;
    return (
      <div
        tabIndex={0}
        ref='bubble'
        className={
          joinClassNames(
            styles.bubble,
            !stage ? styles.empty : '',
            isReadOnly ? styles.isReadOnly : ''
          )}
        style={stage && { borderColor: getColor(stage.color), maxWidth }}
        onClick={this.handleOnClick}
        onKeyDown={this.handleOnKeyDown}
        aria-label={stage ? `${stage.name} (click to change)` : 'Click to add stage'}
        role='combobox'
        aria-haspopup='listbox'
        aria-expanded={this.state.dropdownOpen}
        aria-controls={this.state.dropdownOpen ? this.dropdownId : undefined}
        aria-owns={this.state.dropdownOpen ? this.dropdownId : undefined}
        key={this.componentUid}>
        {stage ? this.renderChangeStageButton(stage) : null}
        {!isReadOnly && <StagesDropdown
          id={this.dropdownId}
          canCreate={canCreate}
          dropdownOpen={this.state.dropdownOpen}
          onClearStage={this.onClearStage}
          onSelectStage={this.onSelectStage}
          requestClose={this.closeDropdown}
          requestOpen={this.openDropdown}
          selectedStage={stage}
          stages={stages}
          onMouseLeave={this.resetTooltipContent}
          onMouseEnter={this.hideTooltip} />}
      </div>
    );
  }
}

export default StageSelector;
