import React from 'react';

const StageIcon = ({ className }) => (
  <svg className={className} width='13' height='7' viewBox='0 0 13 7' fill='#1B2735' xmlns='http://www.w3.org/2000/svg' aria-label='Stage icon' role='img'>
    <rect opacity='0.3' x='0.322266' y='0.254822' width='2.34354' height='6.63974' rx='1.17177' />
    <rect opacity='0.6' x='5.39966' y='0.254822' width='2.34354' height='6.63974' rx='1.17177' />
    <rect x='10.4771' y='0.254822' width='2.34354' height='6.63974' rx='1.17177' />
  </svg>
);

export default StageIcon;
