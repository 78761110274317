import React, {
  memo,
  forwardRef,
  useState,
  useEffect,
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment, ShowLabelValue } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import { SERVER_URL } from '../../env';
import sharedStyle from '../shared.css';
import styles from './AddressAutocomplete.css';

export type Props = {
  excludedFromPreview: boolean;
  elementType: string;

  extraData: {
    label: string;
    labelAlign: LabelAlignment;
    showLabel: ShowLabelValue;
    width: string;
  };

  fieldState?: ControlState;
  hoverText?: string;
  id: string;
  required: boolean;
  value: string;

  specialSettings: {
    labelAlign: LabelAlignment;
    showLabel: boolean;
    hoverText: string;
  };
  updateForm?: (s: { fields: { [p: string]: string | null } }) => void;
}

const AddressAutocomplete = forwardRef(({
  id = '',
  required = false,
  elementType = '',
  fieldState,
  extraData: {
    label = '',
    width,
  },
  specialSettings: {
    showLabel = true,
    labelAlign = 'auto',
    hoverText = '',
  },
  updateForm = () => {},
}: Props, ref: ForwardedRef<any>) => {
  const value = fieldState?.fields?.[elementType] || '';
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [entries, setEntries] = useState<string[]>([]);
  const [shouldDisplayEntries, setShouldDisplayEntries] = useState<boolean>(false);
  const listboxId = `address-autocomplete-listbox-${id}`;

  useEffect((): void => {
    const s = {
      fields: { [elementType]: inputValue },
    };
    if (updateForm) {
      updateForm(s);
    }
  }, [inputValue]);

  const selfValidate = (): boolean => {
    const isValid: boolean = required ? !!inputValue.trim() : true;
    setError(isValid ? '' : 'This field is required');
    return isValid;
  };

  useImperativeHandle(
    ref,
    () => ({
      focus: () => inputRef.current?.focus(),
      validate: selfValidate,
    }));

  const getAutocompleteEntries = useCallback(debounce((val: string): void => {
    setError('');
    fetch(`${SERVER_URL}googleapis/maps/placeAutocomplete.json`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO: remove (useless) sessionToken once BE endpoint is updated
      body: JSON.stringify({ partialAddress: val, sessionToken: '' }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(({result}) => {
        setEntries(result);
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        setError(e?.message || 'There has been a problem with your fetch operation');
      });
  }, 1000), []);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const typedValue = e.target.value;
    setInputValue(typedValue);
    setShouldDisplayEntries(!!typedValue);
    getAutocompleteEntries(typedValue);
  };

  const getSelectHandler = (string: string) => (): void => {
    setInputValue(string);
    setShouldDisplayEntries(false);
  };

  const onBlurHandler = (e: FocusEvent<HTMLInputElement>): void => {
    e?.preventDefault();
    selfValidate();
  };

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}
    >
      <div
        ref={ref as React.RefObject<HTMLDivElement>}
        className={classNames(sharedStyle.FormControlGroup, 'form_control_group')}
      >
        {showLabel && (
          <label
            aria-label={label}
            htmlFor={id}
            className={classNames(
              sharedStyle.FormLabel,
              { [sharedStyle.Required]: required },
              'field_label',
              getAlignmentClass(labelAlign, sharedStyle),
            )}
          >
            {label}
          </label>
        )}
        <div>
          <ErrorContainer error={error}>
            <input
              ref={inputRef}
              id={id}
              width={width}
              value={inputValue}
              aria-autocomplete='list'
              role='combobox'
              aria-controls={shouldDisplayEntries ? listboxId : undefined}
              aria-expanded={shouldDisplayEntries}
              aria-haspopup='listbox'
              className={classNames(styles.autocomplete_input, 'field_input')}
              onChange={handleOnChange}
              required={required}
              onBlur={onBlurHandler}
            />
          </ErrorContainer>
          {shouldDisplayEntries &&
            <div
              id={listboxId}
              role='listbox'
              className={classNames(
                styles.autocomplete_entries,
                shouldDisplayEntries ? styles.showEntries : styles.hideEntries,
              )}
            >
              {entries.map((item, index) => (
                <button
                  className={styles.autocomplete_item}
                  onClick={getSelectHandler(item)}
                  key={item + index}
                  role='option'
                >
                  {item}
                </button>
              ))}
            </div>
          }
        </div>
      </div>
    </Tooltip>
  );
});

export default memo(AddressAutocomplete);
