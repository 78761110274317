import React, { useRef, useEffect, useImperativeHandle, forwardRef, useState, useCallback } from 'react';

function SignatureCanvas({ allowDrawing = true, typedSignature = '', onChange, ...rest }, ref) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [touched, setTouched] = useState(false);
  const [prev, setPrev] = useState({ x: 0, y: 0 });
  const prevRef = useRef<any>(null);
  prevRef.current = prev;

  const getContext = () => canvasRef.current?.getContext('2d');

  const clearCanvas = () => {
    const ctx = getContext();
    // @ts-ignore
    ctx?.clearRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);
    onChange('');
  };

  const textToCanvas = () => {
    try {
      setTouched(true);
      clearCanvas();
      const ctx = getContext();
      if ((!ctx || !canvasRef.current)) return;
      const w = canvasRef.current?.clientWidth; const h = canvasRef.current?.clientHeight;
      const fontHeight = 30;
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = fontHeight + 'px Meddon';
      ctx.fillText(typedSignature, w / 2, h / 2, w - 10);
      onChange(canvasRef.current.toDataURL());
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in textToCanvas', error);
    }
  };

  useEffect(() => {
    (!!typedSignature || touched) && textToCanvas();
  }, [typedSignature]);

  const drawToCanvas = useCallback(event => {
    let eventX;
    let eventY;
    if (event.type === 'touchmove') {
      const rect = event.target.getBoundingClientRect();
      eventX = event.targetTouches[0].clientX - rect.left;
      eventY = event.targetTouches[0].clientY - rect.top;
    } else {
      eventX = event.offsetX;
      eventY = event.offsetY;
    }
    event.preventDefault();
    const ctx = getContext();
    if (!ctx) return;
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(prevRef.current.x, prevRef.current.y);
    ctx.lineTo(eventX, eventY);
    ctx.stroke();
    ctx.closePath();
    setPrev({ x: eventX, y: eventY });
  }, []);

  const startDraw = e => {
    if (!allowDrawing) return;
    setTouched(true);
    const rect = e.target.getBoundingClientRect();
    setPrev(e.type === 'touchmove' ?
      { x: e.targetTouches[0].clientX - rect.left, y: e.targetTouches[0].clientY - rect.top } :
      { x: e.offsetX, y: e.offsetY });
    canvasRef.current?.addEventListener('mousemove', drawToCanvas);
    canvasRef.current?.addEventListener('touchmove', drawToCanvas);
    setIsDrawing(true);
  };

  const removeDraw = () => {
    canvasRef.current?.removeEventListener('mousemove', drawToCanvas);
    canvasRef.current?.removeEventListener('touchmove', drawToCanvas);
    isDrawing && onChange(canvasRef.current?.toDataURL());
    setIsDrawing(false);
  };

  useImperativeHandle(ref, () => ({
    clearCanvas,
    getDataUrl: () => {
      canvasRef.current?.toDataURL();
    },
  }));

  return (
    <>
      <canvas
        ref={canvasRef}
        onMouseDown={startDraw}
        onTouchStart={startDraw}
        onMouseUp={removeDraw}
        onTouchEnd={removeDraw}
        onMouseLeave={removeDraw}
        onTouchCancel={removeDraw}
        aria-label='Signature Canvas'
        aria-describedby='signature_description'
        tabIndex={0}
        {...rest}
      />
      <p
        id='signature_description'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '1px',
          height: '1px',
          padding: 0,
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
        }}>
        Canvas for capturing signature. Type or draw your signature here.
      </p>
    </>
  );
}

export default forwardRef(SignatureCanvas);
