export const databaseBackedFeatures = {
  ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT: true,
  AUTOMATED_PROCESS_BUILDER_SUPPORT: true,
  DISABLE_DOC_UPLOAD: true,
  SAVE_AND_CONTINUE: true,
  QUICK_FILTERS_SUPPORT: true,
  STAGES_IN_SUBMISSION_MANAGER_SUPPORT: true,
  SUBMISSION_RULE_BUILDER_TRIGGERS_SUPPORT: true,
  TAGS_IN_SUBMISSION_MANAGER_SUPPORT: true,
  TOOLTIP_IO_TOUR_SUPPORT: true,
  VISITOR_ACCESS_SUPPORT: true,
  VISITOR_USER_SELF_REGISTRATION_SUPPORT: true,
  SPREEDLY_SUPPORT: true,
  SUBMISSION_DETAILS_FULL_VIEW: true,
  GRM_WITH_SC: true,
  GRM_WITHOUT_SC: true,
  SSO_LOGIN_ONLY: false,
  GOVOS_PAY: false,
  ID_VERIFICATION: false,
  WEBFORM_NG_LIVE_VIEW: false,
};

export const GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS';

