import { useMemo } from 'react';
import { calculatePaymentForType } from 'utils/govOSPay/govOsPay';
import { FormData, FormState } from 'types/liveView';
import {
  FormDataGovOSPaymentConfig,
  GovOSPayAmounts,
} from 'types/govOsPay/govOsPaymentSettings';
import { calculateDigitalServiceFeeAmount } from 'utils/formLiveView/formLiveView';


export const useGetGovOsPayAmounts = (
  formData: FormData,
  formState: FormState,
): GovOSPayAmounts => useMemo(() => {
  const govOsPayConfig: FormDataGovOSPaymentConfig | null | undefined = formData?.form?.govOsPayConfig;

  if (!govOsPayConfig) {
    return {
      ccAmounts: { totalAmount: 0, baseAmount: 0, feeTotal: 0 },
      achAmounts: { totalAmount: 0, baseAmount: 0, feeTotal: 0 },
      digitalServiceFeeAmount: 0,
    };
  }

  const [ccTotal, ccBase, ccFee] = calculatePaymentForType(govOsPayConfig, formState, 'credit_card');
  const [achTotal, achBase, achFee] = calculatePaymentForType(govOsPayConfig, formState, 'ach');

  return {
    ccAmounts: {
      totalAmount: ccTotal,
      baseAmount: ccBase,
      feeTotal: ccFee,
    },
    achAmounts: {
      totalAmount: achTotal,
      baseAmount: achBase,
      feeTotal: achFee,
    },
    digitalServiceFeeAmount: calculateDigitalServiceFeeAmount(formData, formState),
  };
}, [formData, formState]);
