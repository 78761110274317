import React from 'react';
import { Provider } from 'react-redux';
import { parse } from 'query-string';
import { OneNavBarContextProvider } from 'components/OneNavBar';
import App from 'containers/App';
import Login from 'containers/Login';
import AccessCode from 'containers/Login/AccessCode/AccessCode';
import ForgotPassword from 'containers/Login/ForgotPassword';
import ResetPassword from 'containers/Login/ResetPassword';
import VerifyEmail from 'containers/Login/VerifyEmail';
import SignUp from 'containers/Login/SignUp/SignUp';

import CoregSignUp from 'containers/Login/CoregSignUp/CoregSignUp';

import SubmissionsManager from 'containers/SubmissionsManager';
import Lobby from 'containers/Lobby';
import RedirectOnLogin from 'containers/RedirectOnLogin';
import NotFoundPage from 'containers/ErrorPages/404/404';
import UpgradeBrowser from 'components/UpgradeBrowser';
import RegistrationInterstitial from 'containers/RegistrationInterstitial';
import LoginInterstitial from 'containers/LoginInterstitial';
import RegistrationCompletion from 'containers/RegistrationInterstitial/RegistrationCompletion';
import LiveViewGate from 'containers/LiveViewGate';
import SubmissionPdfFetcher from 'containers/AssetFetcher/SubmissionPdfFetcher';
import Healthz from 'components/Healthz';
import TestErrorHandling from 'components/TestErrorHandling';
import { Router, Route, IndexRoute, IndexRedirect } from 'react-router';
import { handleLogin, isLoggedIn } from 'utils/auth';
import * as route from 'constants/route';
import * as actions from 'actions/auth';
import { initialize } from 'actions/submissionsManager/tableApi';
import { getPredicateTokenFromLocalStorage, setPredicateTokenInLocalStorage } from 'utils/liveViewGating';
import JssProvider from 'react-jss/lib/JssProvider';

import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import * as authUtils from 'utils/auth';

import { SplitFactory } from '@splitsoftware/splitio-react';
import FormLiveView from './FormLiveView';
import ThankYouPage from './FormLiveView/ThankYouPage';
import PairAccount from './Login/PairAccount';
import SubmissionView from './SubmissionView/SubmissionView';
import MeasurePerformance from './MeasurePerformance';
import SSOFailure from './ErrorPages/SSOFailure/SSOFailure';
import NgSettings from 'containers/NgSettings';

// This and <JssProvider> in render allow us to change where Material UI injects its styles so we can override them
const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  // @ts-ignore
  insertionPoint: document.getElementById('jss-insertion-point'),
});


const CONFIG = {
  core: {
    authorizationKey: 'nf6la0prrjrks14opg5fuqno3s2nlje1njt8',
    key: '55epev8a305806cuqsclv2cs9ormosrk0o9d',
  },
};
interface Props {
  history: any,
  store: any,
}

export default class Root extends React.Component<Props> {
  componentDidMount() {
    this.props.history.listen(location => {
      (window as any).analytics.page(location.pathname + location.search + location.hash);
    });
  }

  sendToLogin = (nextState, replace) => replace({
    pathname: route.LOGIN,
    state: {nextPathname: nextState.location.pathname + nextState.location.hash},
  })

  authorizationCheck = (nextState, replace) => {
    const { store } = this.props;

    if (isLoggedIn()) {
      store.dispatch(actions.authorizationCheckSuccess());
    } else {
      store.dispatch(actions.authorizationCheckFailure());
      this.sendToLogin(nextState, replace);
    }
  };

  authorizationCheckWithoutRedirect = () => {
    const { store } = this.props;

    if (isLoggedIn()) {
      store.dispatch(actions.authorizationCheckSuccess());
    } else {
      store.dispatch(actions.authorizationCheckFailure());
    }
  }

  accessCheck = (nextState, replace) => {
    const { store } = this.props;
    const { accessCode } = store.getState()?.auth;

    if (!isLoggedIn() && !accessCode) {
      store.dispatch(actions.authorizationCheckFailure());
      replace({
        pathname: route.ACCESS_CODE,
        state: { nextPathname: nextState.location.pathname },
      });
    }
  };

  validateQueryParamsInterstitial = (nextState, replace) => {
    const { form_id: formId, maid } = nextState.location.query;
    if (!(formId && maid)) {
      this.sendToLogin(nextState, replace);
    }
  };

  ensureLiveViewPredicateTokenInQuery = (nextState, replace, done) => {
    const { predicateToken } = nextState.location.query;
    if (predicateToken) {
      setPredicateTokenInLocalStorage(predicateToken);
      done();
    } else {
      this.sendToLogin(nextState, replace);
    }
  };

  ensureLiveViewPredicateTokenInStorage = (nextState, replace) => {
    const token = getPredicateTokenFromLocalStorage();
    if (!token) this.sendToLogin(nextState, replace);
  };

  validateQueryAndEnsurePredicateToken = (nextState, replace) => {
    this.validateQueryParamsInterstitial(nextState, replace);
    this.ensureLiveViewPredicateTokenInStorage(nextState, replace);
  };

  handleCallbackOnEnter = (nextState, replace) => {
    const { store } = this.props;
    const { features } = store.getState()?.features;
    const hash = parse(window.location.hash);
    if (typeof hash.id_token === 'string') {
      const idToken = hash.id_token;
      const dispatch = store.dispatch.bind(store);
      // @ts-ignore
      const subdomain = JSON.parse(window.localStorage.getItem('subdomain'));

      if (parse(window.location.search).active_directory === 'true') {
        dispatch(
          actions.getInternalIdToken(idToken, subdomain, (error, internalIdToken) => {
            handleLogin(dispatch)(error, { idToken: internalIdToken });
            authUtils.redirectToLandingForVisitors(features);
          }),
        );
      } else {
        handleLogin(dispatch)(null, { idToken });
        authUtils.redirectToLandingForVisitors(features);
      }
    } else {
      store.dispatch(actions.loginError(new Error('Invalid callback URL')));
      this.sendToLogin(nextState, replace);
    }
  };

  handleEnterSubmissionsManager = (nextState, replace) => {
    const { store } = this.props;
    if (isLoggedIn() && nextState.params.formID) {
      const formId = nextState.params.formID;
      const selectedQuickFilter = nextState.params.filter || null;
      store.dispatch(initialize(formId, selectedQuickFilter));
    }
    this.authorizationCheck(nextState, replace);
  };

  render() {
    const { store, history } = this.props;

    return (
      <SplitFactory config={CONFIG} >
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <Provider store={store}>
            <OneNavBarContextProvider>
              <Router history={history}>
                <Route path='/'>
                  <Route path='ng' component={App}>
                    <IndexRoute
                      component={props =>
                        <MeasurePerformance name='Lobby'>
                          <Lobby {...props} />
                        </MeasurePerformance>}
                      onEnter={this.authorizationCheck} />
                    <Route
                      path='login'
                      component={props =>
                        <MeasurePerformance name='Login'>
                          <Login {...props} />
                        </MeasurePerformance>}
                      noHeader
                    />
                    <Route path='accesscode' component={AccessCode} noHeader />
                    <Route path='forgot' component={ForgotPassword} noHeader />
                    <Route path='forgot/expired' component={ForgotPassword} noHeader />
                    <Route path='forgot/:token' component={ResetPassword} noHeader />
                    <Route path='verify' component={VerifyEmail} noHeader>
                      <Route path=':token' component={VerifyEmail} noHeader />
                    </Route>
                    <Route path='signup' component={SignUp} noHeader />
                    <Route path='signup/pair' component={PairAccount} noHeader />
                    <Route path='invitation' component={CoregSignUp} noHeader />
                    {/* <Route path='invitation' component={CoregistrationInvitation} noHeader /> */}
                    <Route
                      path='lobby'
                      component={props =>
                        <MeasurePerformance name='Lobby'>
                          <Lobby {...props} />
                        </MeasurePerformance>}
                      onEnter={this.authorizationCheck}
                    />
                    <Route
                      path='filteredSubmissions/:filter/:formID'
                      component={props =>
                        <MeasurePerformance name='SubmissionsManager'>
                          <SubmissionsManager {...props} />
                        </MeasurePerformance>}
                      onEnter={this.handleEnterSubmissionsManager}
                    />
                    <Route
                      path='submissions/viewer/:formId/:submissionId'
                      component={SubmissionView}
                      onEnter={this.accessCheck}
                      noHeader
                    />
                    <Route
                      path='submissions/viewer/:formId/:submissionId/attachments/:attachmentId'
                      component={SubmissionView}
                      onEnter={this.accessCheck}
                      noHeader
                    />
                    <Route
                      path='submissions/:formID'
                      component={props =>
                        <MeasurePerformance name='SubmissionsManager'>
                          <SubmissionsManager {...props} />
                        </MeasurePerformance>}
                      onEnter={this.handleEnterSubmissionsManager}
                    />
                    <Route
                      path='submissions/archived/:formID'
                      component={props => <SubmissionsManager {...props} isArchiveView/>}
                      onEnter={this.handleEnterSubmissionsManager}
                    />
                    <Route path='callback' component={RedirectOnLogin} onEnter={this.handleCallbackOnEnter} />
                    <Route path='upgrade' component={UpgradeBrowser} noHeader />
                    <Route path='healthz' component={Healthz} />
                    <Route path='testErrorHandling' component={TestErrorHandling} />
                    <Route path='/ng/settings' component={NgSettings} onEnter={this.authorizationCheck} />
                    <Route
                      path='loginToViewForm'
                      component={LoginInterstitial}
                      onEnter={this.validateQueryParamsInterstitial}
                      noHeader
                    />
                    <Route
                      path='f/thankyou'
                      component={ThankYouPage}
                      noHeader
                    />
                    <Route
                      path='f/:formID'
                      component={props =>
                        <MeasurePerformance name='NGFormLiveView'>
                          <FormLiveView {...props} />
                        </MeasurePerformance>}
                      noHeader
                      onEnter={this.authorizationCheckWithoutRedirect}
                    />
                    <Route
                      path='fa/:formAlias'
                      component={props =>
                        <MeasurePerformance name='NGFormLiveView'>
                          <FormLiveView {...props} />
                        </MeasurePerformance>}
                      noHeader
                      onEnter={this.authorizationCheckWithoutRedirect}
                    />
                    <Route
                      path='registration'
                      component={RegistrationInterstitial}
                      onEnter={this.validateQueryParamsInterstitial}
                      noHeader
                    />
                    <Route path='verifyEmail' component={RegistrationCompletion} noHeader />
                    <Route path='liveViewGate' component={LiveViewGate} noHeader>
                      <IndexRoute onEnter={this.ensureLiveViewPredicateTokenInQuery} noHeader />
                      <Route
                        path='loginToViewForm'
                        component={LoginInterstitial}
                        onEnter={this.validateQueryAndEnsurePredicateToken}
                        noHeader
                      />
                      <Route
                        path='registration'
                        component={RegistrationInterstitial}
                        onEnter={this.validateQueryAndEnsurePredicateToken}
                        noHeader
                      />
                    </Route>
                    <Route
                      path='submissionPdf/:submissionId'
                      component={SubmissionPdfFetcher}
                      onEnter={this.authorizationCheck}
                      noHeader
                    />
                    <Route path='ssofailure' component={SSOFailure} />
                    <Route path='*' component={NotFoundPage} />
                    <IndexRedirect to='lobby' />
                  </Route>
                  <IndexRedirect to='/ng' />
                </Route>
              </Router>
            </OneNavBarContextProvider>
          </Provider>
        </JssProvider>
      </SplitFactory>
    );
  }
}
