import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { joinClassNames, formatPaymentAmount } from 'utils/strings';
import * as dateHelper from 'utils/dateHelper';
import paymentsIcon from 'icons/paymentsIcon.svg';
import FieldItem from 'components/FieldItem';
import ExpansionField from 'components/ExpansionField/ExpansionField';
import Button from 'components/Button';
import { PaymentStatus, PaymentTypeLabel, PaymentType as PaymentTypeEnum } from 'constants/payments';
import { processRefund, processPayment, setHasSubmissionStatusError } from 'actions/submissionsManager/tableApi';
import { getHasStatusError, getIsProcessPaymentPending } from '../../reducers';
import styles from './SubmissionPaymentStyles.css';
import { PaymentType } from 'types/submissionsManager/tableTypes';
import { State as Features } from 'types/features';
import * as Colors from 'utils/colors';
import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';

import ProcessPaymentModal from './Modals/ProcessPaymentModal';

import { DEFAULT_CONVENIENCE_FEE_LABEL } from 'constants/payments';
import { calculateTotalCollectedValue } from 'utils/submissionsManager/payment';
import { selectTreatmentValue } from '@splitsoftware/splitio-redux';

type PropsFromState = {
  hasStatusError: boolean,
  isPaymentProcessing: boolean,
  isSubmissionsLoading: boolean,
  isFortePaymentDisabled: boolean,
};

type PropsFromDispatch = {
  dispatch: Dispatch<any>,
  processPayment: typeof processPayment,
  processRefund: typeof processRefund,
  setHasSubmissionStatusError: typeof setHasSubmissionStatusError,
};

type Props =
  & PropsFromState
  & PropsFromDispatch
  & {
  features: Features,
  isReadOnly: boolean,
  paymentDetails: PaymentType[],
};


const renderPaymentHeader = () => (
  <div className={styles.headerWrapper}>
    <img alt='headerIcon' src={paymentsIcon} className={styles.headerIcon} />
    <span className={styles.headerLabel}>Payment Transaction Details</span>
  </div>
);

const getExpansionItems = transactionInfo =>
  transactionInfo.feeTotal
    ? [
      { label: 'Subtotal', value: formatPaymentAmount(transactionInfo.baseAmount) },
      {
        label: transactionInfo.customFeeName || DEFAULT_CONVENIENCE_FEE_LABEL,
        value: formatPaymentAmount(transactionInfo.feeTotal || 0),
      },
    ]
    : [];

const PaymentContent = (props: Props) => {
  const [shouldRenderRefundConfirmation, setShouldRenderRefundConfirmation] = useState(false);
  const [isProcessPaymentModalVisible, setProcessPaymentModalVisibility] = useState(false);

  const [isExpanded, toggleExpandedValue] = useState(true);

  const activePayment = props.paymentDetails.filter(item => item.status === 'active').pop();
  const authorizeTransaction = props.paymentDetails
    .filter(item => PaymentStatus[item.transactionType] === 'pre-authorized')
    .pop();
  const processedTransaction = props.paymentDetails
    .filter(item => PaymentStatus[item.transactionType] === 'processed')
    .pop();
  const refundTransaction = props.paymentDetails
    .filter(item => PaymentStatus[item.transactionType] === 'refunded')
    .pop();
  const canceledTransaction = props.paymentDetails
    .filter(item => PaymentStatus[item.transactionType] === 'cancelled')
    .pop();

  const renderTransactionHistory = () => (
    <>
      {authorizeTransaction && (
        <>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <ExpansionField
                label='Pre-Authorized amount'
                value={formatPaymentAmount(authorizeTransaction.amount)}
                expansionItems={getExpansionItems(authorizeTransaction)}
                isOpenByDefault={false}
              />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Pre-Authorized ID' fieldValue={authorizeTransaction.transactionId} isRowView />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem
                label='Pre-Authorized timestamp'
                fieldValue={dateHelper.getFormattedTime(
                  authorizeTransaction.actionCreatedTs,
                  dateHelper.TIME_FORMAT_LONG
                )}
                isRowView
              />
            </div>
          </li>
          {authorizeTransaction?.description && <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment description' fieldValue={authorizeTransaction.description} isRowView />
            </div>
          </li>}
        </>
      )}
      {processedTransaction && (
        <>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              {authorizeTransaction ? (
                <FieldItem
                  label='Processed amount'
                  fieldValue={formatPaymentAmount(processedTransaction.amount)}
                  isRowView
                />
              ) : (<ExpansionField
                label='Processed amount'
                value={formatPaymentAmount(processedTransaction.amount)}
                expansionItems={getExpansionItems(processedTransaction)}
                isOpenByDefault={false}
              />)
              }
            </div>
          </li>

          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Processed ID' fieldValue={processedTransaction.transactionId} isRowView />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem
                label='Processed timestamp'
                fieldValue={dateHelper.getFormattedTime(
                  processedTransaction.actionCreatedTs,
                  dateHelper.TIME_FORMAT_LONG
                )}
                isRowView
              />
            </div>
          </li>
          {processedTransaction.description && <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment description' fieldValue={processedTransaction.description} isRowView />
            </div>
          </li>}
        </>
      )}
      {refundTransaction && (
        <>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Refund amount' fieldValue={formatPaymentAmount(refundTransaction.amount)} isRowView />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Refund ID' fieldValue={refundTransaction.transactionId} isRowView />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem
                label='Refund timestamp'
                fieldValue={dateHelper.getFormattedTime(refundTransaction.actionCreatedTs, dateHelper.TIME_FORMAT_LONG)}
                isRowView
              />
            </div>
          </li>
        </>
      )}
      {canceledTransaction && (
        <>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Cancel ID' fieldValue={canceledTransaction.transactionId} isRowView />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem
                label='Cancel timestamp' fieldValue={dateHelper
                  .getFormattedTime(canceledTransaction.actionCreatedTs, dateHelper.TIME_FORMAT_LONG)}
                isRowView />
            </div>
          </li>
        </>
      )}
    </>
  );

  const renderErrorMessage = paymentStatus => (
    <div className={styles.errorWrapper}>
      <div className={styles.errorHeader}>
        {paymentStatus === 'pre-authorized' && <div className={styles.errorLabel}>Error processing payment</div>}
        {paymentStatus === 'processed' && <div className={styles.errorLabel}>Error processing refund</div>}
        <Icon
          style={{
            margin: '0 15px',
            color: Colors.errorRed,
            fontSize: '20px',
            position: 'absolute',
            right: '-25px',
            top: '-5px',
          }}
          onClick={() => props.setHasSubmissionStatusError(false)}
        >
          close
        </Icon>
      </div>
      {paymentStatus === 'pre-authorized' && (
        <div className={styles.errorText}>There was an error processing payment. Please try again.</div>
      )}
      {paymentStatus === 'processed' && (
        <div className={styles.errorText}>There was an error processing a refund. Please try again.</div>
      )}
    </div>
  );

  const achRefundGateways = [
    'authorize.net',
    'bridgepay',
    'forte',
    'heartland payment systems',
    'payeezy',
    'payflow pro',
    'usa epay',
    'worldpay us',
  ];

  const allowRefund = () => {
    switch (activePayment?.paymentType) {
      case PaymentTypeEnum.ACH:
        return achRefundGateways.includes(activePayment?.gatewayName.toLowerCase());
      case PaymentTypeEnum.CREDIT_CARD:
      case PaymentTypeEnum.DEBIT_CARD:
        if (props.isFortePaymentDisabled) {
          return !activePayment?.gatewayName.toLowerCase().includes('forte');
        }
        return true;
      default: return false;
    }
  };

  const renderPaymentButton = paymentStatus => {
    if (paymentStatus === 'pre-authorized') {
      return (
        <Button
          label='Process payment'
          primary={false}
          primaryBlue
          secondaryBlack={false}
          onClick={() => {
            setProcessPaymentModalVisibility(true);
          }}
        />
      );
    } else if (paymentStatus === 'processed' && allowRefund()) {
      return (
        <div
          className={styles.refundButton}
          onClick={() => {
            setShouldRenderRefundConfirmation(true);
          }}
        >
          Process refund
        </div>
      );
    }
    return null;
  };

  const renderStatusBar = status => {
    const shouldDisplayButton =
      !!props.features.SPREEDLY_SUPPORT &&
      !props.isPaymentProcessing &&
      !props.isSubmissionsLoading &&
      !props.isReadOnly;

    return (
      <div className={styles.statusWrapper}>
        <div className={joinClassNames(styles.status, styles[status])}>{status}</div>
        {shouldDisplayButton && renderPaymentButton(status)}
      </div>
    );
  };

  return (
    <div>
      {renderPaymentHeader()}
      {renderStatusBar(PaymentStatus[activePayment?.transactionType || ''])}
      <span>
        <ul className={styles.boxList}>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={classNames(styles.boxListContent, styles.boxListContent_totalValue)}>
              <FieldItem
                label='Total Collected'
                fieldValue={formatPaymentAmount(calculateTotalCollectedValue(props.paymentDetails))}
                isRowView
              />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment Method' fieldValue={PaymentTypeLabel[activePayment?.paymentType || '']} isRowView />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payee Name' fieldValue={activePayment?.cardholderName || ''} isRowView />
            </div>
          </li>
          {isExpanded && (
            <>
              <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
                <div className={styles.boxListContent}>
                  <FieldItem label='Gateway' fieldValue={activePayment?.gatewayName || ''} isRowView />
                </div>
              </li>
              {renderTransactionHistory()}
            </>
          )}

          <li>
            <div className={classNames(styles.expansionBtnContainer)}>
              <IconButton
                onClick={() => {
                  toggleExpandedValue(!isExpanded);
                }}
                classes={{ root: styles.expansionBtn }}
                aria-label='Expansion Button'
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </li>
        </ul>
      </span>
      {props.hasStatusError && renderErrorMessage(PaymentStatus[activePayment?.transactionType || ''])}
      {shouldRenderRefundConfirmation && (
        <ProcessPaymentModal
          onClose={() => setShouldRenderRefundConfirmation(false)}
          type={'refund'}
          totalValue={activePayment?.amount || 0}
          onSubmit={value => {
            setShouldRenderRefundConfirmation(false);
            props.processRefund(
              activePayment?.submissionId,
              activePayment?.transactionToken,
              value,
              activePayment?.gatewayName,
              activePayment?.paymentType,
              props.dispatch
            );
          }}
        />
      )}
      {isProcessPaymentModalVisible && (
        <ProcessPaymentModal
          onClose={() => setProcessPaymentModalVisibility(false)}
          type={'payment'}
          totalValue={activePayment?.amount || 0}
          onSubmit={value => {
            setProcessPaymentModalVisibility(false);
            props.processPayment(
              activePayment?.submissionId,
              activePayment?.transactionToken,
              value,
              activePayment?.gatewayName,
              activePayment?.paymentType,
              props.dispatch
            );
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state): PropsFromState => ({
  hasStatusError: getHasStatusError(state),
  isPaymentProcessing: getIsProcessPaymentPending(state),
  isSubmissionsLoading: state.submissionTable.isSubmissionsLoading,
  isFortePaymentDisabled: selectTreatmentValue(state.splitio, 'FORTE_PAYMENT_DISABLED') === 'on',
});

const mapDispatchToProps = (dispatch: Dispatch<any>): PropsFromDispatch => ({
  ...bindActionCreators(
    {
      processPayment,
      processRefund,
      dispatch,
      setHasSubmissionStatusError,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContent);
