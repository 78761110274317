import React, {memo, forwardRef, useState, useCallback, useMemo} from 'react';
import moment from 'moment';
import {getAlignmentClass} from 'utils/formLiveView/formLiveView';
import classNames from 'classnames';
import sharedStyle from '../shared.css';
import styles from './AddThisEvent.css';
import {
  timeFormatToAMOrPM,
  concatDateAndTimePropsToISO,
  generateGoogleEventURL,
  generateOutlookEventURL,
  generateYahooEventURL,
  iCalFileUrl,
  arePropsValid,
} from 'utils/formLiveView/calendarEvents/calendarEventHelpers';
import { LabelAlignment, ShowLabelValue } from 'types/liveView';

export interface AddThisEventProps {
  id: string;
  label: string;
  elementType: string;
  required: boolean;
  validationType?: any;
  formula?: any;

  extraData: {
    label: string;
    showLabel: ShowLabelValue;
    labelAlign: LabelAlignment;
  };

  properties?: any;
  excludeFromPreview: boolean;
  isDisabled: boolean;
  hoverText?: string;

  specialSettings: {
    labelAlign: LabelAlignment;
    endDate: string;
    description: string;
    location: string;
    startTime: string;
    label: string;
    endTime: string;
    title: string;
    showLabel: boolean;
    startDate: string;
    url: string;
  };

}

const AddThisEvent = forwardRef((props: AddThisEventProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const [openLinksDropdown, setOpenLinksDropdown] = useState<boolean>(false);
  const {
    id,
    required,
    specialSettings: {
      showLabel,
      label,
      labelAlign,
      startDate,
      title,
      location,
      startTime,
      endTime,
      endDate,
      description, url,
    },
  } = props;


  const formatDisplayDate = useCallback(
    (eventDate: string | number | Date) => moment(eventDate, 'MM DD YYYY').format('MMM DD'),
    []);

  const areDatePropsValid = arePropsValid(startDate, endDate, startTime, endTime, 'MM-DD-YYYY', 'HH:mm:ss');

  const eventObject = useMemo(() => ({
    title,
    description,
    location,
    url,
    start: concatDateAndTimePropsToISO(startDate, startTime),
    end: concatDateAndTimePropsToISO(endDate, endTime),
  }), [props.specialSettings]);

  const downloadIcalFile = useCallback(() => {
    const iCal: string = iCalFileUrl(eventObject);
    const blob: Blob = new Blob([iCal], {type: 'text/calendar;charset=utf-8'});
    const fileName = 'my-event.ics';
    if (navigator?.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [eventObject, iCalFileUrl]);

  const toggleLinksDropdown = useCallback(
    () => setOpenLinksDropdown(prevState => !prevState),
    []);

  return (
    <div id={id} ref={ref}>
      {showLabel && (
        <label
          aria-label={label}
          htmlFor={id}
          className={classNames(
            sharedStyle.FormLabel,
            { [sharedStyle.Required]: required },
            'field_label',
            getAlignmentClass(labelAlign, sharedStyle)
          )}
        >
          {label}
        </label>
      )}
      {areDatePropsValid ? (<div
        className={styles.addThisEvent_drop}
        onClick={toggleLinksDropdown}
        role='button'
        tabIndex={0}
        aria-expanded={openLinksDropdown}
      >
        <div className={styles.date}>
          <span className={styles.mon}>
            {formatDisplayDate(startDate).split(' ')[0].toUpperCase() || 'DD'}
          </span>
          <span className={styles.day}>
            {formatDisplayDate(startDate).split(' ')[1] || 'MM'}
          </span>
          <div className={styles.bdr1} />
          <div className={styles.bdr2} />
        </div>
        <div className={styles.desc}>
          <p data-dynamite-selected='true'>
            <strong className={styles.hed}>{title}</strong>
            <span className={styles.desc}>
              {`Location: ${location}`}
              <br />
              {`When: ${timeFormatToAMOrPM(startTime)} - ${timeFormatToAMOrPM(endTime)}`}
            </span>
          </p>
        </div>
        <div className={styles.dropdown_arrow} aria-hidden='true' />
        {openLinksDropdown && (
          <div className={styles.addThisEvent_dropdown} data-testid='addThisEventDropdown'>
            <button
              id={`${id}-google`}
              aria-label='Add to Google Calendar'
              onClick={() => window.open(generateGoogleEventURL(eventObject))}
            >
              <span>Google Calendar</span>
            </button>

            <button
              id={`${id}-yahoo`}
              aria-label='Add To Yahoo Calendar'
              onClick={() => window.open(generateYahooEventURL(eventObject))}
            >
              <span>Yahoo Calendar</span>
            </button>

            <button
              id={`${id}-outlook`}
              aria-label='Add To Outlook Calendar'
              onClick={() =>
                window.open(generateOutlookEventURL(eventObject))
              }
            >
              <span>Outlook Calendar</span>
            </button>

            <button
              id={`${id}-ical`}
              aria-label='Download iCal event'
              onClick={() => downloadIcalFile()}
            >
              <span>iCal</span>
            </button>
          </div>
        )}
      </div>) : (
        <div className={styles.addThisEvent_error}>
          <p>
            <strong>Invalid Date/Time</strong>
            <br />
            Please provide valid date and time.
          </p>
        </div>
      )}
    </div>
  );
});
export default memo(AddThisEvent);

