import React, { FC, MouseEvent, KeyboardEvent } from 'react';
import MaterialUIIconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { EditorState } from 'draft-js';
import styles from './styles.css';

interface ButtonProps {
  onToggle: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, type: string) => void;
  type: string;
  tooltip: string;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({ onToggle, type, tooltip, children }) => {
  const handleOnToggle = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    onToggle(event, type);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOnToggle(event);
    }
  };

  return (
    <Tooltip classes={{ tooltip: styles.tooltip }} placement='top' title={tooltip} aria-label={tooltip}>
      <MaterialUIIconButton
        disableRipple
        classes={{ root: styles.button }}
        onMouseDown={handleOnToggle}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        {children}
      </MaterialUIIconButton>
    </Tooltip>
  );
};

interface LabelButtonProps extends ButtonProps {
  label: string;
  active?: boolean;
}

export const LabelButton: FC<LabelButtonProps> = ({ label, active = false, ...props }) => (
  <Button aria-pressed={active} {...props}>
    <span
      className={active ? styles.labelActive : styles.labelInactive}
    >
      {label}
    </span>
  </Button>
);

interface IconButtonProps extends ButtonProps {
  Icon: React.ComponentType<any>;
  active?: boolean;
}

export const IconButton: FC<IconButtonProps> = ({ Icon, active = false, ...props }) => (
  <Button aria-pressed={active} {...props}>
    <Icon className={active ? styles.labelActive : styles.labelInactive} />
  </Button>
);

export const getCurrentBlockType = (editorState: EditorState): string => {
  const selection = editorState.getSelection();
  return editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
};
