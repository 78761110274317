import React from 'react';
import { connect } from 'react-redux';
import { memoize, values, omit, size } from 'lodash';
import { changeRuleTags } from 'actions/automatedProcessBuilder';
import { createTags } from 'actions/tags';
import TagMultiSelector from 'components/Tags/TagMultiSelector';
import { getTags } from 'reducers/index';
import {
  triggerTypeEnum,
} from 'constants/triggers';
import { getTagNamesFromRule, getOtherTagNamesFromRule } from 'utils/automatedProcessBuilder';


export class TagTrigger extends React.Component<any, any> {
  static defaultProps = {
    allTags: {},
    noTagsInAccountMessage: null,
    notTaggedMessage: null,
    tagNames: [],
  }

  state = {
    footerText: '',
    modalOpen: false,
    typeaheadResults: [],
  }

  getTagsFromName = memoize(
    (tagNames, allTags) => tagNames.map(tagName => allTags[tagName]).filter(Boolean),
    (tagNames, allTags) => `${tagNames.join()}-${size(allTags)}`
  );

  filterTags = searchValue => {
    const { tagNames, otherTagNames, allTags } = this.props;
    const allTagNames = [...tagNames, ...otherTagNames];
    const tagsAvailableToSelect = this.getTagsAvailableToSelect(allTagNames, allTags) as any[];
    const searchRegExp = RegExp(searchValue, 'i');
    return tagsAvailableToSelect.filter(current =>
      searchRegExp.test(current.name)
    );
  }

  getTagsAvailableToSelect = memoize(
    (selectedTagNames, allTags) => values(omit(allTags, selectedTagNames)),
    (selectedTagNames, allTags) => `${selectedTagNames.join()}-${size(allTags)}`
  )

  removeTag = tag => {
    const {
      allTags,
      formId,
      rule,
      tagNames,
      triggerType,
    } = this.props;
    const currentTags = this.getTagsFromName(tagNames, allTags);
    const updatedTags = currentTags.filter(currentTag => currentTag.name !== tag.name);
    const updatedTagNames = updatedTags.map(updatedTag => updatedTag.name);
    this.props.changeRuleTags(formId, rule.ruleId, triggerType, updatedTagNames);
  }

  tagExists = tagName => !!this.props.allTags[tagName];

  handleCreateTag = name => {
    this.props.createTags([name], newTagNames => {
      if (newTagNames.includes(name)) {
        this.handleAddTags([{ name }]);
        this.setState({ footerText: '' });
      }
    });
  }

  handleAddTags = tags => {
    const {
      allTags,
      formId,
      rule,
      triggerType,
      tagNames,
    } = this.props;
    if (triggerType === triggerTypeEnum.IF) {
      this.props.changeRuleTags(formId, rule.ruleId, triggerType, [tags[0].name]);
    } else {
      const currentTags = this.getTagsFromName(tagNames, allTags);
      const updatedTags = currentTags.concat(tags);
      const updatedTagNames = updatedTags.map(tag => tag.name);
      this.props.changeRuleTags(formId, rule.ruleId, triggerType, updatedTagNames);
    }
  }

  handleRemoveTag = tag => this.removeTag(tag);


  handleInputTypeahead = searchValue => {
    const trimmedValue = searchValue.trim();
    const typeaheadResults = trimmedValue ? this.filterTags(trimmedValue) : [];
    const footerText = trimmedValue && !this.tagExists(trimmedValue) ? searchValue : '';
    this.setState({
      typeaheadResults,
      footerText,
    });
  };

  render() {
    const {
      allTags,
      isReadOnly,
      tagNames,
    } = this.props;
    const tags = this.getTagsFromName(tagNames, allTags);
    return (
      <TagMultiSelector
        footerText={this.state.footerText}
        hidePeak
        isReadOnly={isReadOnly}
        maxElements={1}
        onAddTags={this.handleAddTags}
        onCreateTag={this.handleCreateTag}
        onInputTypeahead={this.handleInputTypeahead}
        onRemoveTag={this.handleRemoveTag}
        tags={tags}
        typeaheadResults={this.state.typeaheadResults} />
    );
  }
}

const mapStateToProps = (state, { rule, triggerType }) => ({
  allTags: getTags(state),
  tagNames: getTagNamesFromRule(rule, triggerType),
  otherTagNames: getOtherTagNamesFromRule(rule, triggerType),
});

const mapDispatchToProps = {
  changeRuleTags,
  createTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagTrigger);
