import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classNames from 'classnames';
import * as authActions from 'actions/auth';
import { getMasterAccountsByEmail, removeLineBreaks } from 'utils/auth';
import NewAuthLayout from 'components/AuthLayout/NewAuthLayout';
import Form from './Form/Form';
import TermsOfUse from 'containers/Login/TermsOfUse';
import Loader from 'components/Loader';
import { getWindowSizeData } from 'components/WindowSize';
import style from 'containers/Login/SignUp/SignUp.css';

interface Props {
  dispatch: Function,
  maid: string,
  isError: boolean,
  errorMsg?: any,
  signUpEmailErrorMsg?: string,
  router: any,
  companyName: string,
  organizationLogo: string,
  isProcessing: boolean,
  isLoading: boolean,
}

class SignUp extends React.PureComponent<Props> {
  componentDidMount() {
    const { dispatch, maid } = this.props;
    if (!maid) {
      // if we have a maid, the state has already been filled
      dispatch(authActions.getAccountKey(dispatch));
    }
  }

  onCreate = async(data: any) => {
    const {dispatch, maid, router} = this.props;

    // do not send request if hidden field was filled by a bot
    if (data.hpValue) {
      return;
    }

    const trimmedData = {
      ...data,
      firstName: removeLineBreaks(data.firstName),
      lastName: removeLineBreaks(data.lastName),
      email: removeLineBreaks(data.email),
      password: removeLineBreaks(data.password),
      phoneNumber: removeLineBreaks(data.phoneNumber),
    };

    const accounts = await getMasterAccountsByEmail(trimmedData.email, maid);
    if (Array.isArray(accounts) && accounts?.length && !accounts.find(a => a.maid === maid)) {
      const companyNames = accounts.map(a => a.companyName);
      if (companyNames.length) {
        router.push({pathname: '/ng/signup/pair', state: {email: trimmedData.email, companyNames}});
        return;
      }
    }
    dispatch(authActions.visitorSignUp(maid, trimmedData, dispatch));
  }

  render() {
    const {
      companyName,
      organizationLogo,
      isLoading,
      maid,
      isProcessing,
      errorMsg,
      signUpEmailErrorMsg,
      isError,
    } = this.props;
    const { isMobile } = getWindowSizeData();

    if (!maid) {
      return <Loader />;
    }
    const message = errorMsg?.message || errorMsg || 'Something went wrong ...';

    return (
      <NewAuthLayout
        title='Let’s set up your account.'
        subtitle={
          isMobile
            ? 'Create a login to access form submissions.'
            : 'You’ll be able to see the progress of your submissions, save your work to continue later, download your completed documents, and share your submissions with others.'
        }
        fetching={isLoading}
        companyName={companyName}
        logo={organizationLogo}
      >
        <div className={style.signUpContainer}>
          <div
            className={classNames(style.signUpFormContainer, {
              [style.signUpFormContainerMobile]: isMobile,
            })}
          >
            {isError && !signUpEmailErrorMsg && (
              <div className={style.errorMsg}>
                {message}
              </div>
            )}
            <div className={style.signUpForm}>
              <Form
                isProcessing={isProcessing}
                onCreate={this.onCreate}
                error={signUpEmailErrorMsg}
              />
            </div>
            <div
              className={classNames(style.loginContainer, {
                [style.loginContainerMobile]: isMobile,
              })}
            >
              Already have an account? <Link to='/ng/login'>Log in</Link>
            </div>
          </div>

          <TermsOfUse />
        </div>
      </NewAuthLayout>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      companyName,
      isFetchingOrganizationInfo,
      isSubdomainExists,
      organizationLogo,
      maid,
      isSignUpVisitorProcessing,
      signUpVisitorErrorMsg,
      signUpEmailErrorMsg,
      signUpVisitorSuccess,
      signUpVisitorError,
    },
  } = state;

  return {
    isLoading: isFetchingOrganizationInfo || !isSubdomainExists,
    companyName,
    organizationLogo,
    maid,
    signUpEmailErrorMsg,
    errorMsg: signUpVisitorErrorMsg,
    isProcessing: isSignUpVisitorProcessing,
    isSignUpSuccess: signUpVisitorSuccess,
    isError: signUpVisitorError,
  };
};

export default connect(mapStateToProps)(SignUp);
