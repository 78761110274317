import React, {ReactElement} from 'react';
import styles from './newAuthStyles.css';
import Loader from '../Loader';
import brandLogo from 'images/brand-logo.svg';

interface Props {
  children: React.ReactNode,
  title: string,
  subtitle?: string | JSX.Element,
  logo: string,
  companyName:string,
  fetching: boolean,
  error?: string | null,
}

function NewAuthLayout({ children, title, subtitle, logo, companyName, fetching}: Props): ReactElement {
  return (
    fetching ? <Loader /> :
      (<div className={styles.pageWrapper}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={logo || brandLogo} alt='logo' />
            <h1>{companyName}</h1>
          </div>
          <h2>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
          {children}
        </div>
      </div>)
  );
}

export default NewAuthLayout;
