import React, {forwardRef, useImperativeHandle, useState} from 'react';
import classNames from 'classnames';
import style from './AcuityScheduling.css';
import sharedStyle from '../shared.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import { ShowLabelValue } from 'types/liveView';
import { showLabelToBoolean } from 'utils/formLiveView/formLiveView';

interface Props {
  id: string,
  name: string,
  value: any,
  label?: string,
  fieldState: any,
  cssClass?: string,
  labelCssClass?: string,
  updateForm?: Function,
  extraData?: {
    showLabel: ShowLabelValue,
    alignment?: string,
    label?: string,
    userId?: string,
  },
}

const AcuityScheduling = ({
  id,
  name,
  value,
  updateForm,
  fieldState,
  label = '',
  cssClass = sharedStyle.FormControlGroup,
  labelCssClass = sharedStyle.FormLabel,
  extraData = {
    showLabel: 't',
  },
  ...props
}: Props & Partial<any>, ref: React.Ref<unknown>) => {
  const [error, setError] = useState('');

  const selfValidate = () => {
    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  return (
    <div id={`acuity_${id}`} className={cssClass}>
      {label && showLabelToBoolean(extraData?.showLabel) && <label
        aria-label={label}
        className={classNames(labelCssClass, {[sharedStyle.Required]: props.required}, 'acuity_label')}>
        {label}
      </label>}
      <div className={style.AcuitySchedulingWrapper}>
        <ErrorContainer error={error}>
          <iframe
            title={label || 'Acuity Scheduling'}
            aria-label={label || 'Acuity Scheduling'}
            data-testid='acuity-scheculing-iframe'
            src={`https://app.acuityscheduling.com/schedule.php?owner=${(extraData && extraData.userId) || ''}`} />
        </ErrorContainer>
      </div>
    </div>
  );
};

export default React.memo(forwardRef(AcuityScheduling));
