import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { joinClassNames, formatPaymentAmount } from 'utils/strings';
import * as dateHelper from 'utils/dateHelper';
import paymentsIcon from 'icons/paymentsIcon.svg';
import FieldItem from 'components/FieldItem';
import ExpansionField from 'components/ExpansionField/ExpansionField';
import { PaymentTypeLabel } from 'constants/payments';
import { getHasStatusError, getIsProcessPaymentPending } from '../../reducers';
import styles from './SubmissionPaymentStyles.css';
import { GovOSPaymentType } from 'types/submissionsManager/tableTypes';
import { DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL } from 'constants/payments';

type PropsFromState = {
  hasStatusError: boolean,
  isPaymentProcessing: boolean,
  isSubmissionsLoading: boolean,
};

export type Props =
  & PropsFromState
  & {
  paymentDetails: GovOSPaymentType[],
};


const renderPaymentHeader = () => (
  <div className={styles.headerWrapper}>
    <img alt='headerIcon' src={paymentsIcon} className={styles.headerIcon} />
    <span className={styles.headerLabel}>Payment Transaction Details</span>
  </div>
);

const getExpansionItems = transactionInfo =>
  transactionInfo.feeTotal
    ? [
      { label: 'Subtotal', value: formatPaymentAmount(transactionInfo.baseAmount) },
      {
        label: transactionInfo.customFeeName || DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL,
        value: formatPaymentAmount(transactionInfo.feeTotal || 0),
      },
    ]
    : [];

const renderTransactionHistory = (activePayments: GovOSPaymentType[]) => activePayments.map((payment, index) => (<>
  <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <FieldItem label='Transaction Type' fieldValue={payment.digitalServiceFeeConfigId ? 'GovOS Digital Service Fee' : 'Document Fee'} isRowView />
    </div>
  </li>
  <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <FieldItem label='Transaction ID' fieldValue={payment.platformTransactionId || ''} isRowView />
    </div>
  </li>
  <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <FieldItem
        label='Timestamp'
        fieldValue={dateHelper.getFormattedTime(
          payment.createdTs,
          dateHelper.TIME_FORMAT_LONG
        )}
        isRowView
      />
    </div>
  </li>
  <li key={index} className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <ExpansionField
        label='Processed Amount'
        value={formatPaymentAmount(payment.amount)}
        expansionItems={getExpansionItems(payment)}
        isOpenByDefault={false}
      />
    </div>
  </li>
</>));

export const GovOSPaymentContent = (props: Props) => {
  const [isExpanded, toggleExpandedValue] = useState(true);

  const isSplitPayment = props.paymentDetails.some(item => item.platformTransactionGroupId !== null);
  const activePayments = props.paymentDetails.filter(item => item.transactionStatus === 'SUCCESS');
  const activePaymentsTotal = activePayments.reduce((acc, item) => acc + item.amount, 0);
  const paymentMethod = activePayments[0]?.paymentType ?? ''; // We can assume that the payment method is the same for all transactions
  const platformTransactionGroupId = props.paymentDetails[0]?.platformTransactionGroupId ?? ''; // We can assume that the group id is the same for all transactions

  return (
    <div>
      {renderPaymentHeader()}
      <span>
        <ul className={styles.boxList}>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={classNames(styles.boxListContent, styles.boxListContent_totalValue)}>
              <FieldItem
                label='Total Collected'
                fieldValue={formatPaymentAmount(activePaymentsTotal)}
                isRowView
              />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment Method' fieldValue={PaymentTypeLabel[paymentMethod]} isRowView />
            </div>
          </li>
          {isExpanded && (
            <>
              {isSplitPayment && (
                <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
                  <div className={styles.boxListContent}>
                    <FieldItem label='Transaction Group ID' fieldValue={platformTransactionGroupId} isRowView />
                  </div>
                </li>
              )}
              {renderTransactionHistory(activePayments)}
            </>
          )}

          <li>
            <div className={classNames(styles.expansionBtnContainer)}>
              <IconButton
                onClick={() => {
                  toggleExpandedValue(!isExpanded);
                }}
                classes={{ root: styles.expansionBtn }}
                aria-label='Expansion Button'
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </li>
        </ul>
      </span>
    </div>
  );
};

const mapStateToProps = (state): PropsFromState => ({
  hasStatusError: getHasStatusError(state),
  isPaymentProcessing: getIsProcessPaymentPending(state),
  isSubmissionsLoading: state.submissionTable.isSubmissionsLoading,
});

export default connect(mapStateToProps)(GovOSPaymentContent);
