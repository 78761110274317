import api from './api';
import { GET_FEATURE_FLAGS } from 'constants/types/features';
import { Action, State } from 'types/features';
import { TOGGLE_NG_LIVE_VIEW_API_CALL } from 'constants/types/ngSettings';

export default function features(state: State = {}, action: Action): State {
  switch (action.type) {
    case TOGGLE_NG_LIVE_VIEW_API_CALL:
      return api(action, state, {
        success: () => ({
          ...state,
          WEBFORM_NG_LIVE_VIEW: !state.WEBFORM_NG_LIVE_VIEW,
        }),
      });

    case GET_FEATURE_FLAGS:
      return api(action, state, {
        success: () => ({
          ...action.payload.features,
        }),
      });

    default:
      return state;
  }
}

export const getFeatureState = (state: State): State => state;
