import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Select, { Option } from 'react-select';
import styles from './FormLiveView.css';
import arrowBack from 'icons/arrowBack.svg';
import inputErrorIcon from 'icons/inputErrorIcon.svg';
import inputCorrectIcon from 'icons/inputCorrectIcon.svg';
import authorizedPaymentIcon from 'icons/authorizedPaymentIcon.svg';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { ACHPaymentPayload, FormState, LiveViewPaymentConfig } from 'types/liveView';
import Tooltip from 'components/Tooltip';
import { calculatePayment } from 'utils/formLiveView/formLiveView';

type Props = {
  open: boolean,
  closeModalHandler: () => void,
  formState: FormState,
  paymentConfig: LiveViewPaymentConfig,
  onSubmit: (achPayData: ACHPaymentPayload) => void,
}

type InputFieldProps = {
  name: string,
  label: string,
}

const accountTypeOptions = [
  { value: 'checkingPersonal', label: 'Checking (Personal)' },
  { value: 'savingsPersonal', label: 'Savings (Personal)' },
  { value: 'checkingBusiness', label: 'Checking (Business)' },
  { value: 'savingsBusiness', label: 'Checking (Business)' },
];

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required field'),
  lastName: Yup.string().required('Required field'),
  bankRoutingNumber: Yup.string().required('Required field')
    .matches(/^[0-9]+$/, 'Must be a number')
    .min(9, 'Routing number structure is not valid')
    .max(9, 'Routing number structure is not valid'),
  bankAccountNumber: Yup.string().required('Required field')
    .matches(/^[0-9]+$/, 'Must be a number')
    .min(4, 'Account number structure is not valid')
    .max(17, 'Account number structure is not valid'),
  bankAccountType: Yup.string().required('Required field'),
});

const SELECT_CUSTOM_STYLES = {
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  valueContainer: provided => ({ ...provided, padding: '0' }),
  indicatorSeparator: provided => ({ ...provided, display: 'none' }),
};

const InputFiled = ({ name, label }: InputFieldProps) =>
  (<Field name={name}>
    {({ field, meta }) => (
      <div className={styles.inputFieldContainer}>
        <div className={styles.inputField}>
          <div className={styles.inputFieldLabelContainer}>
            <label htmlFor={name} className={styles.inputFieldLabel}>{label}</label>
            {meta.touched && meta.error && (
              <Tooltip title={meta.error} className={styles.tooltip} placement='top'>
                <img
                  src={inputErrorIcon}
                  alt='Error indicator'
                  className={styles.inputIcon}
                  aria-hidden='true'
                />
              </Tooltip>
            )}
            {meta.touched && !meta.error && (
              <img
                src={inputCorrectIcon}
                alt='Valid input indicator'
                className={styles.inputIcon}
                aria-hidden='true'
              />
            )}
          </div>
          <input
            type='text'
            id={name}
            aria-label={label}
            aria-invalid={meta.touched && meta.error ? 'true' : 'false'}
            {...field}
          />
        </div>
      </div>
    )}
  </Field>
  );

const SelectField = ({
  options,
  field,
  form,
}) => (
  <Select
    styles={SELECT_CUSTOM_STYLES}
    options={options}
    name={field.label}
    value={options ? options.find(option => option.value === field.value) : ''}
    onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
    placeholder='Select Account Type...'
    aria-label='Account type'
    inputId={`${field.name}-select`}
  />
);

const ACHPaymentModal = ({
  open,
  closeModalHandler,
  formState,
  paymentConfig,
  onSubmit,
}: Props) => {
  const [calculations, setCalculations] = useState<number[]>([0, 0, 0]);
  const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(false);
  // Add focus trap for modal
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (paymentConfig && formState) {
      setCalculations(calculatePayment(paymentConfig, formState, 'ach'));
    }
  }, [paymentConfig, formState]);
  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.focus();
    }
  }, [open]);
  const onSubmitHandler = values => {
    const [totalAmount, baseAmount, feeTotal] = calculations;
    onSubmit({
      ...values,
      amount: totalAmount,
      baseAmount,
      feeTotal,
      feeAmount: paymentConfig?.feeStructure?.fees?.[0]?.amount || 0,
      feePercentage: paymentConfig?.feeStructure?.fees?.[0]?.percentage || 0,
      currency: 'USD',
      gatewayToken: paymentConfig.gatewayToken,
      paymentType: 'ach',
      paymentsConfigId: paymentConfig.paymentConfigId,
    });
  };

  const renderModal = () => (
    <div
      aria-modal='true'
      aria-labelledby='ach-payment-title'
      className={styles.modalOverlay} role='dialog'
    >
      <div
        className={styles.ACHModal}
        ref={modalRef}
        tabIndex={-1}
      >
        <div className={styles.backToFormButton} onClick={closeModalHandler} aria-label='Close modal and return to form'>
          <img
            src={arrowBack}
            alt=''
            className={styles.arrowBack}
            aria-hidden='true'
          />
          Back to form
        </div>
        <div className={styles.ACHModalContent}>
          <div className={styles.ACHInfoPanel}>
            <h2 id='ach-payment-title' className={styles.ACHPaymentHeader}>ACH Payment</h2>
            <span className={styles.divider} aria-hidden='true' />
            <span className={styles.ACHAmount}>{`$${calculations[0].toFixed(2)}`}</span>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              bankAccountNumber: '',
              bankRoutingNumber: '',
              bankAccountType: '',
            }}
            validationSchema={FormSchema}
            onSubmit={values => onSubmitHandler(values)}
            validateOnMount
          >
            {({ isValid }) => (
              <Form>
                <div className={styles.ACHform}>
                  <InputFiled name='firstName' label='First Name' />
                  <InputFiled name='lastName' label='Last Name' />
                  <InputFiled name='bankRoutingNumber' label='Routing #' />
                  <InputFiled name='bankAccountNumber' label='Account #' />
                  <div className={styles.accountTypeDropdownContainer}>
                    <span>Account Type</span>
                    <Field name='bankAccountType' component={SelectField} options={accountTypeOptions} />
                  </div>
                  <button
                    className={styles.submitACHBtn}
                    type='submit'
                    onClick={() => setDisplayErrorMessage(!isValid)}
                    aria-live='polite'
                  >
                    <img
                      src={authorizedPaymentIcon}
                      alt=''
                      aria-hidden='true'
                    />
                    <span>Authorize Payment</span>
                  </button>
                  {displayErrorMessage &&
                  <div
                    className={styles.errorMessage}
                    role='alert'
                    aria-live='assertive'>Please correct invalid fields</div>}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );

  return open ? renderModal() : null;
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(null, mapDispatchToProps)(ACHPaymentModal);
