import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import parse from 'html-react-parser';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router';
import {
  shareSubmissionFromThankYouPage as shareSubmissionFromThankYouPageAction,
  toggleIsShareModalOpen as toggleIsShareModalOpenAction,
  getThankYouPagePDFUrl as getThankYouPagePDFUrlAction,
  resetLiveViewSubmit as resetLiveViewSubmitAction,
  resetThankYouPage as resetThankYouPageAction,
} from 'actions/forms';
import {
  getIsThankYouPageShareModalOpen,
  getIsShareSubmissionLoading,
  getShareSubmissionError,
  getThankYouPagePdfUrl,
} from 'reducers/index';
import ShareModal from './ShareModal';
import style from 'styles/shared/containerStyles.css';
import formStyle from './FormLiveView.css';
import {shouldDisplayDownloadLink, shouldDisplayExpiredLinkMessage} from 'utils/formLiveView/thankYouPageHelpers';

type PropsFromState = {
  disableDownloadAndShareButtons: boolean,
  formId: string,
  formName: string,
  header: string,
  isShareModalOpen: boolean,
  isShareSubmissionLoading: boolean,
  maid: string,
  message: string,
  shareSubmissionError: string | null,
  pdfUrl: string | null,
  tokenFromStore: string | null,
};

type PropsFromDispatch = {
  shareSubmissionFromThankYouPage: typeof shareSubmissionFromThankYouPageAction,
  toggleIsShareModalOpen: typeof toggleIsShareModalOpenAction,
  getThankYouPDFUrl: typeof getThankYouPagePDFUrlAction,
  resetLiveViewSubmit: typeof resetLiveViewSubmitAction,
  resetThankYouPage: typeof resetThankYouPageAction,
};

export type Props =
 & PropsFromState
 & PropsFromDispatch;

export function ThankYouPage({
  disableDownloadAndShareButtons,
  formId = '',
  formName = '',
  header = 'Thank you!',
  isShareModalOpen = false,
  isShareSubmissionLoading = false,
  maid = '',
  message = 'Your submission is complete.',
  pdfUrl,
  shareSubmissionError = '',
  shareSubmissionFromThankYouPage,
  toggleIsShareModalOpen,
  getThankYouPDFUrl,
  resetLiveViewSubmit,
  resetThankYouPage,
  tokenFromStore,
}: Props) {
  // We move the token to a local state because we will remove it from the store on mount
  const [token] = useState<string | null>(tokenFromStore);
  const [pdfUrlClicked, setPdfUrlClicked] = useState<boolean>(false);

  /*
   * We are removing the form submit result from the store on mount to avoid
   * instantly redirecting to the thank you page if the user navigates back to the form
   * or accessing sensitive data navigating away from the thank you page and back.
   */
  useEffect(() => {
    resetLiveViewSubmit();
    /*
     * We reset the thank you page on unmount to avoid accessing sensitive or
     * incorrect data by navigating away from the thank you page and back.
     */
    return () => {resetThankYouPage();};
  }, []);

  const MAX_ATTEMPTS = 10; // 10 MAX_ATTEMPTS to get the PDF URL
  const DELAY = 1250; // 1.25 seconds DELAY between each attempt
  useEffect(() => {
    // If the download button is disabled, we don't need to poll for the PDF URL
    if (disableDownloadAndShareButtons) {return () => {};}
    if (!token) return () => {};

    let attempts = 0;
    const intervalPDFPolling = setInterval(() => {
      if (pdfUrl || attempts >= MAX_ATTEMPTS) {
        clearInterval(intervalPDFPolling);
      } else {
        attempts++;
        getThankYouPDFUrl(token); // Dispatch action to update the Redux state
      }
    }, DELAY);

    return () => clearInterval(intervalPDFPolling); // Cleanup on component unmount
  }, [pdfUrl, token, getThankYouPDFUrl, disableDownloadAndShareButtons]);

  const mailClicked = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    toggleIsShareModalOpen(true);
  }, [toggleIsShareModalOpen]);


  const pdfLinkClicked = useCallback((): void => {
    if (pdfUrl) {
      setPdfUrlClicked(true);
    }
  }, [pdfUrl]);

  return (
    <div className={`${style.Container} ${formStyle.FormLiveViewContainer}`}>
      {isShareModalOpen &&
        <ShareModal
          closeModal={() => toggleIsShareModalOpen(false)}
          formId={formId}
          formName={formName}
          isLoading={isShareSubmissionLoading}
          isOpen={isShareModalOpen}
          maid={maid}
          shareSubmissionError={shareSubmissionError}
          shareSubmissionFromThankYouPage={shareSubmissionFromThankYouPage}
          token={token || ''}
        />}
      <div className={formStyle.FormLiveViewSubContainer}>
        <div className={formStyle.FormThankYou}>
          {!disableDownloadAndShareButtons && token && (
            <IconButton
              onClick={mailClicked}
              className={formStyle.mailButton}
              title='Email a copy of your completed document'
              aria-label='Email a copy of your completed document'
              id='thankYouPageShareButton'
            >
              <MailIcon />
            </IconButton>
          )}
          <br />
          <h1 className={formStyle.thankYouPageHeader}>{header}</h1>
          <h2 className={formStyle.thankYouPageMessage}>{parse(message)}</h2>
          {!disableDownloadAndShareButtons && shouldDisplayDownloadLink(pdfUrl, pdfUrlClicked, token) && (
            <a
              className={formStyle.pdfUrlLink}
              href={pdfUrl || ''}
              target='_blank'
              rel='noopener noreferrer'
              onClick={pdfLinkClicked}
              title={'This link will expire after it\'s clicked.'}
              aria-label='Download your PDF'
              id='thankYouPageDownloadPdfLink'
            >
              Click here to download your PDF. The link will expire after you click it.
            </a>
          )}
          {!disableDownloadAndShareButtons && shouldDisplayExpiredLinkMessage(pdfUrl, pdfUrlClicked, token) && (
            <>
              <h2 className={formStyle.thankYouPageMessage}>Download link expired</h2>
              <p>Please <Link to='/ng/login'>log in</Link> to view your submission.</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state): PropsFromState => {
  const form = state?.forms?.liveView?.form?.form;
  const disableDownloadAndShareButtons = !!form
    ? form.formSettings.isDownloadAndShareDisabled || false // Setting should be false by default
    : true; // If no form data is in state, disable the buttons
  return {
    disableDownloadAndShareButtons,
    formId: form?.formId,
    formName: form?.formName,
    header: form?.formSettings?.thankYouCustomSettings?.header,
    isShareModalOpen: getIsThankYouPageShareModalOpen(state),
    isShareSubmissionLoading: getIsShareSubmissionLoading(state),
    maid: state?.auth?.maid || '',
    message: form?.formSettings?.thankYouCustomSettings?.body,
    shareSubmissionError: getShareSubmissionError(state),
    pdfUrl: getThankYouPagePdfUrl(state),
    tokenFromStore: state?.forms?.formSubmit?.submitResult?.thankYouPageToken || null,
  };
};

const mapDispatchToProps = (dispatch): PropsFromDispatch => ({
  shareSubmissionFromThankYouPage: bindActionCreators(shareSubmissionFromThankYouPageAction, dispatch),
  toggleIsShareModalOpen: bindActionCreators(toggleIsShareModalOpenAction, dispatch),
  getThankYouPDFUrl: bindActionCreators(getThankYouPagePDFUrlAction, dispatch),
  resetLiveViewSubmit: bindActionCreators(resetLiveViewSubmitAction, dispatch),
  resetThankYouPage: bindActionCreators(resetThankYouPageAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
