import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import FormInput from '../FormInput/FormInput';
import style from './FormFullName.css';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { LabelAlignment, ShowLabelValue } from 'types/liveView';
import { getAlignmentClass, showLabelToBoolean } from 'utils/formLiveView/formLiveView';

interface Props {
  id: string,
  label?: string,
  fieldState?: any,
  onChange?: Function,
  updateForm?: Function,
  validationType?: string,
  showHideCondition?: any,
  extraData?: {
    label?: string,
    labelAlign: LabelAlignment,
    showLabel?: ShowLabelValue,
  },
  isDisabled?: boolean,
  hoverText?: string,
  firstNameLabel?: string,
  lastNameLabel?: string,
  initialLabel?: string,
  firstName?: string,
  lastName?: string,
  initial?: string,
  fullNameSettings?: {
    isFirstNameRequired: boolean,
    isInitialRequired: boolean,
    isLastNameRequired: boolean,
    showMiddleInitial: boolean,
  },
}

function FormFullName({
  id,
  label = 'Full Name',
  firstNameLabel = 'First Name',
  lastNameLabel = 'Last Name',
  initialLabel = 'Initial',
  firstName = '',
  lastName = '',
  initial = '',
  fieldState,
  onChange = undefined,
  updateForm = undefined,
  isDisabled = false,
  validationType,
  showHideCondition,
  hoverText = '',
  fullNameSettings = {
    isFirstNameRequired: false,
    isLastNameRequired: false,
    isInitialRequired: false,
    showMiddleInitial: false,
  },
  extraData = {
    showLabel: 't',
    labelAlign: 'auto',
  },
  ...props
}: Props & Partial<any>, ref) {
  const [fnVal, setFirstName] = useState(firstName);
  const [lnVal, setLastName] = useState(lastName);
  const [initialVal, setInitial] = useState(initial);

  let fnRef;
  let lnRef;
  let initialRef;

  const updateFormState = () => {
    const s = {
      fields: {
        firstName: fnVal,
        lastName: lnVal,
        initial: initialVal,
      },
    };
    updateForm && updateForm(s);
  };
  const handleChangeFirst = event => {
    event.preventDefault();
    setFirstName(event.target.value);
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: () => fnRef.validate() && lnRef.validate() && (initialRef ? initialRef.validate() : true),
    }));

  const handleChangeLast = event => {
    event.preventDefault();
    setLastName(event.target.value);
  };

  const handleChangeInitial = event => {
    event.preventDefault();
    setInitial(event.target.value);
  };

  useEffect(() => {
    onChange && onChange(initialVal ? `${fnVal} ${initialVal} ${lnVal}`.trim() : `${fnVal} ${lnVal}`.trim());
    updateFormState();
  }, [fnVal, lnVal, initialVal]);

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div id={`container_${id}`} className={classNames(style.Container, 'form_control_group')} {...props}>
        <fieldset name={id} id={id} className={sharedStyle.Fieldset} disabled={isDisabled}>
          { showLabelToBoolean(extraData?.showLabel) &&
            <legend className={getAlignmentClass(extraData.labelAlign, sharedStyle)}>{label}</legend> }
          <div className={style.SubContainerLeft}>
            <FormInput
              value={fnVal}
              onChange={handleChangeFirst}
              label={firstNameLabel}
              id={`first_name_${id}`}
              required={fullNameSettings.isFirstNameRequired}
              type='text'
              autoComplete='given-name'
              ref={el => { fnRef = el; }}
            />
          </div>
          { fullNameSettings.showMiddleInitial &&
            (<div className={classNames(style.SubContainerMiddle, style.SubContainerLeft)}>
              <FormInput
                value={initialVal}
                onChange={handleChangeInitial}
                label={initialLabel}
                id={`initial_${id}`}
                required={fullNameSettings.isInitialRequired}
                type='text'
                autoComplete='initial'
                ref={el => { initialRef = el; }}
              />
            </div>) }
          <div className={style.SubContainer}>
            <FormInput
              value={lnVal}
              onChange={handleChangeLast}
              label={lastNameLabel}
              id={`last_name_${id}`}
              required={fullNameSettings.isLastNameRequired}
              type='text'
              autoComplete='family-name'
              ref={el => { lnRef = el; }}
            />
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormFullName));
