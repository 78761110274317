import React, { memo, useMemo, SVGProps, ReactNode } from 'react';
import { nanoid } from 'nanoid';

export interface Props extends SVGProps<SVGSVGElement> {
  children: ReactNode;
  desc?: string;
  title?: string;
  onCLick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const AccessibleSVG = ({ children, title, desc, ...rest }: Props) => {
  const [titleId, descId] = useMemo(() => [nanoid(), desc ? nanoid() : undefined], [desc]);

  const accessibilityProps = useMemo(() => title
    ? {
      'aria-labelledby': `${titleId}${desc ? ` ${descId}` : ''}`,
    }
    : {
      'aria-label': rest['aria-label'] || 'SVG image',
    }, [title, desc, titleId, descId, rest]);

  return (
    <svg {...rest} role='img' {...accessibilityProps}>
      {title && <title id={titleId}>{title}</title>}
      {desc && <desc id={descId}>{desc}</desc>}
      {children}
    </svg>
  );
};

export default memo(AccessibleSVG);
