import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import FormInput from '../FormInput/FormInput';
import FormDropDown from '../FormDropDown/FormDropDown';
import style from './FormFullAddress.css';
import sharedStyle from '../shared.css';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';
import { STATES_AND_TERRITORIES } from 'constants/states';

const stateOptions: { label: string, value: string }[] = Object
  .entries(STATES_AND_TERRITORIES)
  .map(([abbrev, state]) => ({ label: state, value: abbrev }));

type RefType = {
  validate: () => boolean,
};

type Props = {
  id: string,
  label?: string,
  fieldState?: ControlState | null,
  updateForm: Function | null,
  cssClass?: string,
  specialSettings?: {
    label?: string,
    labelAlign: LabelAlignment,
    showLabel?: boolean,
    requiredFields?: {
      address0?: boolean,
      address1?: boolean,
      city?: boolean,
      state?: boolean,
      zip?: boolean,
    },
  },
  required?: boolean,
  hoverText?: string,
};

function FormFullAddress({
  id,
  label = 'Full Address',
  fieldState,
  updateForm = null,
  cssClass = sharedStyle.FormControlGroup,
  specialSettings = {
    showLabel: true,
    labelAlign: 'auto',
    requiredFields: {
      address0: true,
      address1: true,
      city: true,
      state: true,
      zip: true,
    },
  },
  hoverText = ''}: Props & Partial<any>, ref) {
  const address0Ref: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const address1Ref: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const cityRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const stateRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const zipRef: React.MutableRefObject<RefType> = useRef({ validate: () => true });
  const selfValidate = (): boolean => {
    const isValid: boolean = address0Ref?.current?.validate() && address1Ref?.current?.validate() &&
      cityRef?.current?.validate() && stateRef?.current?.validate() && zipRef?.current?.validate();
    return isValid;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  const onFieldChanged = field => event => {
    const fs = {
      ...fieldState?.fields,
      [field]: event.target.value,
    };
    updateForm && updateForm({ fields: fs });
  };

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'form_control_group')} id={`container_${id}`}>
        <fieldset id={id} className={sharedStyle.Fieldset}>
          { specialSettings?.showLabel &&
            <legend className={getAlignmentClass(specialSettings.labelAlign, sharedStyle)}>{label}</legend> }
          <FormInput
            ref={address0Ref}
            id={`address0_${id}`}
            label='Street Address'
            autoComplete='address-line4'
            required={specialSettings?.requiredFields?.address0}
            onChange={onFieldChanged('address0')}
            value={fieldState?.fields?.address0 || ''}
          />
          <FormInput
            ref={address1Ref}
            extraData={{ showLabel: false }}
            id={`address1_${id}`}
            label='Street Address Line 2'
            autoComplete='address-line3'
            required={specialSettings?.requiredFields?.address1}
            onChange={onFieldChanged('address1')}
            value={fieldState?.fields?.address1 || ''}
          />
          <div className={style.SubContainer}>
            <div>
              <FormInput
                ref={cityRef}
                id={`city_${id}`}
                label='City'
                autoComplete='address-level2'
                required={specialSettings?.requiredFields?.city}
                onChange={onFieldChanged('city')}
                value={fieldState?.fields?.city || ''}
              />
            </div>
            <div>
              <FormDropDown
                ref={stateRef}
                id={`state_${id}`}
                options={stateOptions}
                label='State'
                autoComplete='address-level1'
                required={specialSettings?.requiredFields?.state}
                onChange={onFieldChanged('state')}
                value={fieldState?.fields?.state || ''}
              />
            </div>
            <div>
              <FormInput
                ref={zipRef}
                id={`zip_${id}`}
                label='Zip'
                autoComplete='postal-code'
                required={specialSettings?.requiredFields?.zip}
                onChange={onFieldChanged('zip')}
                value={fieldState?.fields?.zip || ''}
              />
            </div>
          </div>
        </fieldset>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormFullAddress));
