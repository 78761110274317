import React, { FC, useEffect, useRef } from 'react';
import styles from '../NgSettingsStyles.css';

interface ConfirmationModalProps {
  onCancel: () => void;
  onContinue: () => void;
  ngViewOnAllForms: boolean;
}

const NG_VIEW_ON_TEXT = 'All eligible forms will render in Spark view without any additional steps. Would you like to proceed?';

const NG_VIEW_OFF_TEXT = 'All forms will render in legacy view without any additional steps. Would you like to proceed?';

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ onCancel, onContinue, ngViewOnAllForms }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Focus the modal when it opens
    if (modalRef.current) {
      modalRef.current.focus();
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onCancel();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onCancel]);

  return (
    <div className={styles.confirmationModalOverlay} role='dialog' aria-modal='true'>
      <div
        className={styles.confirmationModalContainer}
        aria-labelledby='modal-header'
        tabIndex={-1}
        ref={modalRef}
      >
        <div className={styles.confirmationModal}>
          <div className={styles.confirmationModalTitleContainer}>
            <h1 id='modal-header' className={styles.confirmationModalTitle}>
              Are you sure?
            </h1>
          </div>
          <div className={styles.confirmationModalTextContainer}>
            <p className={styles.confirmationModalText} data-testid='confirmation-modal-text'>
              {ngViewOnAllForms ? NG_VIEW_ON_TEXT : NG_VIEW_OFF_TEXT}
            </p>
          </div>
          <div className={styles.confirmationModalButtonsContainer}>
            <button className={styles.cancelButton} onClick={onCancel} data-testid='cancel-button'>
              Cancel
            </button>
            <button className={styles.continueButton} onClick={onContinue} data-testid='continue-button'>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
