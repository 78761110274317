import {
  SAVE_DIGITAL_SERVICE_FEES,
  SET_FORM_ID,
  TOGGLE_MODAL,
} from 'constants/types/digitalServiceFeeActionTypes';
import api from 'reducers/api';

export type State = {
  formId: string,
  isSidebarOpen: boolean,
};

const initialState:State = {
  formId: '',
  isSidebarOpen: false,
};

export default (state:State = initialState, action):State => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    case SET_FORM_ID:
      return {...state, formId: action.formId};
    case SAVE_DIGITAL_SERVICE_FEES:
      return api(action, state, {
        success: (): State => ({ ...state, isSidebarOpen: false }),
      });
    default:
      return state;
  }
};

export const isSidebarOpen = (state:State) => state.isSidebarOpen;

export const getFormId = (state: State) => state.formId;
