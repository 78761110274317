import api from './api';
import { TOGGLE_NG_LIVE_VIEW_API_CALL } from 'constants/types/ngSettings';

export const setNgLiveFormsInAccount = (value: boolean) => api.postWithAuth({
  endpoint: 'upsertWebformNgLiveView.json',
  baseType: TOGGLE_NG_LIVE_VIEW_API_CALL,
  data: {value},
  failure: (state, error) => ({ error }),
  success: (_:any, { response }) => response.status,
});
