import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@material-ui/core';
import Button from 'components/Button';
import { joinClassNames, formatPaymentAmount } from 'utils/strings';
import {
  processGovOsPayRefund as processGovOsPayRefundAction,
  setHasSubmissionStatusError as setHasSubmissionStatusErrorAction,
} from 'actions/submissionsManager/tableApi';
import * as dateHelper from 'utils/dateHelper';
import paymentsIcon from 'icons/paymentsIcon.svg';
import FieldItem from 'components/FieldItem';
import ExpansionField from 'components/ExpansionField/ExpansionField';
import { PaymentStatus, PaymentTypeLabel } from 'constants/payments';
import { getHasStatusError, getIsProcessPaymentPending } from '../../reducers';
import styles from './SubmissionPaymentStyles.css';
import { GovOSPaymentType } from 'types/submissionsManager/tableTypes';
import { State as Features } from 'types/features';
import { DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL } from 'constants/payments';
import ProcessPaymentModal from './Modals/ProcessPaymentModal';
import * as Colors from 'utils/colors';

type PropsFromState = {
  hasStatusError: boolean,
  isPaymentProcessing: boolean,
  isSubmissionsLoading: boolean,
};

type PropsFromDispatch = {
  dispatch: Dispatch<any>,
  processGovOsPayRefund: typeof processGovOsPayRefundAction,
  setHasSubmissionStatusError: typeof setHasSubmissionStatusErrorAction,
};

export type Props =
  & PropsFromState
  & PropsFromDispatch
  & {
  features: Features,
  isReadOnly: boolean,
  paymentDetails: GovOSPaymentType[],
};


const renderPaymentHeader = () => (
  <div className={styles.headerWrapper}>
    <img alt='headerIcon' src={paymentsIcon} className={styles.headerIcon} />
    <span className={styles.headerLabel}>Payment Transaction Details</span>
  </div>
);

const getExpansionItems = transactionInfo =>
  transactionInfo.feeTotal
    ? [
      { label: 'Subtotal', value: formatPaymentAmount(transactionInfo.baseAmount) },
      {
        label: transactionInfo.customFeeName || DEFAULT_GOVOS_PAY_CONVENIENCE_FEE_LABEL,
        value: formatPaymentAmount(transactionInfo.feeTotal || 0),
      },
    ]
    : [];

const renderTransactionHistory = (activePayments: GovOSPaymentType[]) => activePayments.map((payment, index) => (<>
  <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <FieldItem label='Transaction Type' fieldValue={payment.digitalServiceFeeConfigId ? 'GovOS Digital Service Fee' : 'Document Fee'} isRowView />
    </div>
  </li>
  <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <FieldItem label='Transaction ID' fieldValue={payment.platformTransactionId || ''} isRowView />
    </div>
  </li>
  <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <FieldItem
        label='Timestamp'
        fieldValue={dateHelper.getFormattedTime(
          payment.createdTs,
          dateHelper.TIME_FORMAT_LONG
        )}
        isRowView
      />
    </div>
  </li>
  <li key={index} className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
    <div className={styles.boxListContent}>
      <ExpansionField
        label='Processed Amount'
        value={formatPaymentAmount(payment.amount)}
        expansionItems={getExpansionItems(payment)}
        isOpenByDefault={false}
      />
    </div>
  </li>
</>));

const renderErrorMessage = (paymentStatus, setHasSubmissionStatusError: typeof setHasSubmissionStatusErrorAction) => (
  <div className={styles.errorWrapper}>
    <div className={styles.errorHeader}>
      {paymentStatus === 'pre-authorized' && <div className={styles.errorLabel}>Error processing payment</div>}
      {paymentStatus === 'processed' && <div className={styles.errorLabel}>Error processing refund</div>}
      <Icon
        style={{
          margin: '0 15px',
          color: Colors.errorRed,
          fontSize: '20px',
          position: 'absolute',
          right: '-25px',
          top: '-5px',
        }}
        onClick={() => setHasSubmissionStatusError(false)}
      >
        close
      </Icon>
    </div>
    {paymentStatus === 'pre-authorized' && (
      <div className={styles.errorText}>There was an error processing payment. Please try again.</div>
    )}
    {paymentStatus === 'processed' && (
      <div className={styles.errorText}>There was an error processing a refund. Please try again.</div>
    )}
  </div>
);

export const GovOSPaymentContent = ({
  hasStatusError,
  isPaymentProcessing,
  isReadOnly,
  isSubmissionsLoading,
  dispatch,
  features,
  paymentDetails,
  processGovOsPayRefund,
  setHasSubmissionStatusError,
}: Props) => {
  const [isExpanded, toggleExpandedValue] = useState(true);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);

  const isSplitPayment = paymentDetails.some(item => item.platformTransactionGroupId !== null);
  const activePayments = paymentDetails.filter(item => item.transactionStatus === 'SUCCESS');
  const activePaymentsTotal = activePayments.reduce((acc, item) => acc + item.amount, 0);
  const paymentMethod = activePayments[0]?.paymentType ?? ''; // We can assume that the payment method is the same for all transactions
  const platformTransactionId = activePayments[0]?.platformTransactionId ?? '';
  const platformTransactionGroupId = paymentDetails[0]?.platformTransactionGroupId ?? ''; // We can assume that the group id is the same for all transactions

  const renderProcessButton = () => (
    <Button
      // className={styles.refundButton}
      onClick={() => {
        setIsRefundModalVisible(true);
      }}
      label='Process Refund'
      primary={false}
      primaryBlue
    />
  );

  const renderStatusBar = status => {
    const shouldDisplayButton =
      !!features.GOVOS_PAY &&
      !isPaymentProcessing &&
      !isReadOnly &&
      !isSubmissionsLoading &&
      activePayments.length > 0;

    return (
      <div className={styles.statusWrapper}>
        <div className={joinClassNames(styles.status, styles[status])}>{status}</div>
        {shouldDisplayButton && renderProcessButton()}
      </div>
    );
  };

  return (
    <div>
      {renderPaymentHeader()}
      {renderStatusBar(PaymentStatus[activePayments[0]?.transactionType || ''])}
      <span>
        <ul className={styles.boxList}>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={classNames(styles.boxListContent, styles.boxListContent_totalValue)}>
              <FieldItem
                label='Total Collected'
                fieldValue={formatPaymentAmount(activePaymentsTotal)}
                isRowView
              />
            </div>
          </li>
          <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
            <div className={styles.boxListContent}>
              <FieldItem label='Payment Method' fieldValue={PaymentTypeLabel[paymentMethod]} isRowView />
            </div>
          </li>
          {isExpanded && (
            <>
              {isSplitPayment && (
                <li className={joinClassNames(styles.boxListItem, styles.rowListItem)}>
                  <div className={styles.boxListContent}>
                    <FieldItem label='Transaction Group ID' fieldValue={platformTransactionGroupId} isRowView />
                  </div>
                </li>
              )}
              {renderTransactionHistory(activePayments)}
            </>
          )}

          <li>
            <div className={classNames(styles.expansionBtnContainer)}>
              <IconButton
                onClick={() => {
                  toggleExpandedValue(!isExpanded);
                }}
                classes={{ root: styles.expansionBtn }}
                aria-label='Expansion Button'
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </li>
        </ul>
      </span>
      {hasStatusError && renderErrorMessage(PaymentStatus[activePayments[0]?.transactionType || ''], setHasSubmissionStatusError)}
      {isRefundModalVisible && (
        <ProcessPaymentModal
          onClose={() => setIsRefundModalVisible(false)}
          type={'refund'}
          totalValue={activePaymentsTotal}
          onSubmit={(value: number) => {
            setIsRefundModalVisible(false);
            processGovOsPayRefund(
              activePayments[0].submissionId,
              platformTransactionId,
              value,
              dispatch,
            );
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state): PropsFromState => ({
  hasStatusError: getHasStatusError(state),
  isPaymentProcessing: getIsProcessPaymentPending(state),
  isSubmissionsLoading: state.submissionTable.isSubmissionsLoading,
});

const mapDispatchToProps = (dispatch: Dispatch<any>): PropsFromDispatch => ({
  ...bindActionCreators(
    {
      processGovOsPayRefund: processGovOsPayRefundAction,
      setHasSubmissionStatusError: setHasSubmissionStatusErrorAction,
    },
    dispatch
  ),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(GovOSPaymentContent);
