import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, Ref, memo } from 'react';
import sharedStyles from '../shared.css';
import { LabelAlignment } from 'types/liveView';
import { getAlignmentClass } from 'utils/formLiveView/formLiveView';

interface Props {
  id: string,
  label: string,
  cssClass?: string,
  tag: keyof JSX.IntrinsicElements
  extraData?: {
    alignment: LabelAlignment,
  }
}

export type FormHeadingHandle = {
  validate: () => boolean;
};

function FormHeading({
  id,
  label,
  cssClass = '',
  tag: Tag = 'h1',
  extraData = {
    alignment: 'left',
  },
}: Props, ref: Ref<FormHeadingHandle>): React.ReactElement | null {
  useImperativeHandle(
    ref,
    () => ({
      validate: () => true,
    })
  );

  if (!label) return null;

  // Change h1 to h2 if necessary for accessibility
  const ActualTag = Tag === 'h1' ? 'h2' : Tag;

  return (
    <ActualTag
      id={id}
      className={classNames(
        cssClass,
        'form_heading',
        sharedStyles[Tag] ?? '',
        getAlignmentClass(extraData?.alignment, sharedStyles),
      )}
    >
      {label}
    </ActualTag>
  );
}

const exported = memo(forwardRef(FormHeading)) as unknown as
  (typeof FormHeading & {willBeNull: Function});

exported.willBeNull = ({ label }) => !label;

export default exported;
