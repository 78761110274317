import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { isVisitor } from 'utils/auth';
import { getUserRole } from 'reducers/index';

type PropsFromState = {
  isVisitorUser: boolean;
};
type Props = PropsFromState;

const Zendesk = ({
  isVisitorUser = true,
}: Props) => (
  isVisitorUser ?
    null :
    <Helmet>
      <script id='ze-snippet' src='https://static.zdassets.com/ekr/snippet.js?key=c2475f9a-f583-43d1-a3af-66a18c097aa5' />
    </Helmet>
);

const mapStateToProps = state => ({
  isVisitorUser: isVisitor(getUserRole(state)),
});

export default connect(mapStateToProps)(Zendesk);
