import React, { forwardRef, useImperativeHandle, useRef, useState, memo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import ErrorContainer from '../ErrorContainer/ErrorContainer';
import { RECAPTCHA_SITE_KEY } from '../../env';

import { ControlState } from 'types/liveView';


type Props = {
  updateForm: (controlState: ControlState) => void;
}


const Recaptcha = ({
  updateForm,
}: Props, ref: React.Ref<unknown>) => {
  const [token, setToken] = useState<string | null>('');
  const [error, setError] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const selfValidate = () => {
    if (!token) {
      setError('This field is required.');
      return false;
    }
    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      focus: () => wrapperRef.current?.focus(),
      validate: selfValidate,
    }));

  const onChange = (value: string | null) => {
    setToken(value);
    updateForm && updateForm({
      fields: { text: value },
    });
  };

  return (<div ref={wrapperRef}>
    <ErrorContainer error={error}>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onChange}
        ref={null}
      />
    </ErrorContainer>
  </div>);
};

export default memo(forwardRef(Recaptcha));
