import React from 'react';
import { connect } from 'react-redux';
import styles from './Archive.css';
import AccessibleSVG from 'components/AccessibleSVG';
import { Link } from 'react-router';
import { getSubmissionManagerRoute } from 'reducers/index';
import { joinClassNames } from 'utils/strings';

const ArchiveIcon = () => (
  <div className={styles.iconContainer}>
    <AccessibleSVG
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      title='Archive'
      aria-hidden='true' // Since we'll provide the text alternative in the parent
    >
      <path d='M9.82143 7.32143C9.82143 7.65346 9.54632 7.92857 9.21429 7.92857H6.78571C6.45368 7.92857 6.17857 7.65346 6.17857 7.32143C6.17857 6.9894 6.45368 6.71429 6.78571 6.71429H9.21429C9.54632 6.71429 9.82143 6.9894 9.82143 7.32143ZM15.2857 5.5C15.2857 5.16797 15.0106 4.89286 14.6786 4.89286H1.32143C0.989397 4.89286 0.714286 5.16797 0.714286 5.5V14.6071C0.714286 14.9392 0.989397 15.2143 1.32143 15.2143H14.6786C15.0106 15.2143 15.2857 14.9392 15.2857 14.6071V5.5ZM15.8929 1.25C15.8929 0.917968 15.6177 0.642856 15.2857 0.642856H0.714286C0.382255 0.642856 0.107143 0.917968 0.107143 1.25V3.67857C0.107143 4.0106 0.382255 4.28571 0.714286 4.28571H15.2857C15.6177 4.28571 15.8929 4.0106 15.8929 3.67857V1.25Z' fill='#BABABA'/>
    </AccessibleSVG>
  </div>
);

const ArchiveTitle = props => {
  const { formId, submissionManagerRoute, sidebarExpanded } = props;
  const classes = joinClassNames(
    styles.archiveTitle,
    sidebarExpanded ? styles.expanded : ''
  );

  return (
    <Link
      to={`${submissionManagerRoute}/archived/${formId}`}
      className={classes}
      aria-label={sidebarExpanded ? undefined : 'Archive'} // Only needed when collapsed
    >
      <ArchiveIcon />
      {sidebarExpanded && <span>Archive</span>}
    </Link>
  );
};

const mapStateToProps = state => {
  const submissionManagerRoute = getSubmissionManagerRoute(state);
  const { submissionTable: { formId } } = state;
  return {
    submissionManagerRoute,
    formId,
  };
};

export default connect(
  mapStateToProps,
)(ArchiveTitle);
