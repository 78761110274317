import api from '../api';
import * as types from 'constants/types/submissionsActionTypes';
import {
  RECORD_DETAILS,
  SELECT_TABLE_RECORD,
  SELECT_ALL_RECORDS,
  EXPORT_SUBMISSION_API,
  RENOTIFY_API,
  PROCESS_PAYMENT,
  PROCESS_REFUND,
  PROCESS_GOVOS_PAY_REFUND,
  FIND_AND_REPLACE_STAGES,
  FIND_AND_REPLACE_TAGS,
  REPLACE_ASSIGNEES,
  FIND_AND_REPLACE_ASSIGNEES,
  REPLACE_TAGS,
  APPLY_FILTERS,
  APPLY_STAGE_FILTER,
  REMOVE_STAGE_FILTERS,
  SORT_SUBMISSIONS,
  UNSORT_SUBMISSIONS,
} from 'constants/types/submissionsTableActionTypes';
import {
  ASSIGN_STAGES,
  UNASSIGN_STAGES,
} from 'constants/types/stageActionTypes';
import {
  EXPORT_SUBMISSION_SUCCESS_TITLE,
  EXPORT_SUBMISSION_SUCCESS_BODY,
  EXPORT_SUBMISSION_LOADING_TITLE,
  EXPORT_SUBMISSION_LOADING_BODY,
  EXPORT_SUBMISSION_FAILURE_TITLE,
  EXPORT_SUBMISSION_FAILURE_BODY,
  SEND_NOTIFICATION_SUCCESS_TITLE,
  SEND_NOTIFICATION_SUCCESS_BODY,
  SEND_NOTIFICATION_FAILURE_TITLE,
  SEND_NOTIFICATION_FAILURE_BODY,
} from 'constants/applicationCopy';
import {
  SET_APB_VISIBILITY,
} from 'constants/types/automatedProcessBuilderActionTypes';
import { ASSIGN_USERS_TO_MULTIPLE, UNASSIGN_USERS_TO_MULTIPLE } from 'constants/types/assignmentsActionTypes';
import { ASSIGN_TAGS_TO_MULTIPLE, UNASSIGN_TAGS_TO_MULTIPLE } from 'constants/types/tagActionTypes';
import { SHARE_SUBMISSIONS } from 'constants/types/submissionsActionTypes';
import { NUM_OF_SELECTED_SUBMISSIONS_TO_SHOW_BULK_PROCESSING } from 'constants/bulkActions';

const initialState = {
  sidebarOpen: false,
  downloadVisible: false,
  shareVisible: false,
  decryptModalVisible: false,
  loaderVisibile: false,
  loaderStatus: 'loading',
  loaderTitle: '',
  loaderBody: '',
  loaderActionHandler: null,
  refundVisible: false,
  editSignerVisible: false,
  signerDetailsVisible: false,
  error: {},
  searchbarOpen: false,
  searchbarInput: [],
  downloadOptions: {},
  selectedRowIndex: null,
  scrollToRow: null,
  toolbarMobileMenuVisible: false,
  isPaymentProcessPending: false,
  isBulkProcessing: false,
  bulkProcessingState: {
    title: '',
    bodyText: '',
  },
};

const selectRow = (state, action) => {
  const {selectedRowIndex} = action;
  let newState;
  let sidebarOpen;
  const oldSubmissionIndex = state.selectedRowIndex;
  const newSubmissionIndex = selectedRowIndex;
  // We want to compare undefined == null here
  if (oldSubmissionIndex == newSubmissionIndex) { // eslint-disable-line eqeqeq
    newState = state;
    sidebarOpen = !state.sidebarOpen;
  } else {
    const {recordDetails, ...restState} = state;// eslint-disable-line no-unused-vars
    newState = restState;
    sidebarOpen = true;
  }
  return {
    ...newState,
    sidebarOpen,
    selectedRowIndex,
  };
};

export default function submissions(state = initialState, action) {
  switch (action.type) {
    case SET_APB_VISIBILITY:
      if (action.isVisible) {
        return {
          ...state,
          sidebarOpen: false,
          selectedRowIndex: null,
        };
      }
      return state;
    case types.HIDE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: false,
        selectedRowIndex: null,
      };
    case types.SCROLL_TO_ROW:
      return {...state, scrollToRow: action.rowIndex};
    case types.RESET_SCROLL_TO_ROW:
      return {...state, scrollToRow: null};
    case types.DOWNLOAD_VISIBILITY:
      return {...state, downloadVisible: action.downloadVisible};
    case types.SHARE_VISIBILITY:
      return {...state, shareVisible: action.shareVisible};
    case types.DECRYPT_MODAL_VISIBILITY:
      const {rawValue, rowIndex, inputName} = action;
      return {
        ...state,
        decryptModalVisible: !state.decryptModalVisible,
        decryptionData: {
          rawValue,
          rowIndex,
          inputName,
        },
      };
    case types.LOADER_VISIBILITY:
      return {
        ...state,
        loaderVisibile: action.visibility,
        loaderStatus: action.status || 'loading',
        loaderBody: action.body || '',
        loaderTitle: action.title || '',
        loaderActionHandler: action.actionHandler || null,
      };
    case types.SEARCHBAR_INPUT_CHANGE:
      return {...state, searchbarInput: action.searchbarInput};
    case types.SELECT_ROW:
      return selectRow(state, action);
    case APPLY_FILTERS:
    case APPLY_STAGE_FILTER:
    case REMOVE_STAGE_FILTERS:
    case SORT_SUBMISSIONS:
    case UNSORT_SUBMISSIONS:
      return initialState;
    case types.REFUND_VISIBILITY:
      return {...state, refundVisible: action.refundVisible};
    case types.EDIT_SIGNER_VISIBILITY:
      return {...state, editSignerVisible: action.editSignerVisible};
    case types.SIGNER_DETAILS_VISIBILITY:
      return {...state, signerDetailsVisible: action.signerDetailsVisible};
    case RECORD_DETAILS:
      return api(action, state, {
        success: () => ({...state, recordDetails: action.payload}),
      });
    case SELECT_TABLE_RECORD:
      return {
        ...state,
        sidebarOpen: false,
        selectedRowIndex: null,
      };
    case SELECT_ALL_RECORDS:
      return {
        ...state,
        sidebarOpen: false,
        selectedRowIndex: null,
      };
    case types.TRACK_DOWNLOAD_FILE:
      return {
        ...state,
        fileType: action.fileType,
        fileUrl: action.fileUrl,
      };
    case EXPORT_SUBMISSION_API:
      return api(action, state, {
        pending: () => ({
          ...state,
          loaderVisibile: true,
          loaderStatus: 'loading',
          loaderBody: EXPORT_SUBMISSION_LOADING_BODY,
          loaderTitle: EXPORT_SUBMISSION_LOADING_TITLE,
        }), success: () => {
          const {isExportSuccess, recipientEmail} = action.payload;
          return {
            ...state,
            loaderVisibile: true,
            loaderStatus: isExportSuccess ? 'successful' : 'failed',
            loaderBody: isExportSuccess ?
              EXPORT_SUBMISSION_SUCCESS_BODY(recipientEmail) : EXPORT_SUBMISSION_FAILURE_BODY,
            loaderTitle: isExportSuccess ? EXPORT_SUBMISSION_SUCCESS_TITLE : EXPORT_SUBMISSION_FAILURE_TITLE,
          };
        }, failure: () => ({
          ...state,
          loaderVisibile: true,
          loaderStatus: 'failed',
          loaderBody: EXPORT_SUBMISSION_FAILURE_BODY,
          loaderTitle: EXPORT_SUBMISSION_FAILURE_TITLE,
        }),
      });
    case RENOTIFY_API:
      return api(action, state, {
        success: () => ({
          ...state,
          loaderVisibile: true,
          loaderStatus: 'successful',
          loaderBody: SEND_NOTIFICATION_SUCCESS_BODY,
          loaderTitle: SEND_NOTIFICATION_SUCCESS_TITLE,
        }),
        failure: () => ({
          ...state,
          loaderVisibile: true,
          loaderStatus: 'failed',
          loaderBody: SEND_NOTIFICATION_FAILURE_BODY,
          loaderTitle: SEND_NOTIFICATION_FAILURE_TITLE,
        }),
      });
    case types.TOOLBAR_MOBILE_MENU_VISIBILITY:
      return {...state, toolbarMobileMenuVisible: action.toolbarMobileMenuVisible};
    case PROCESS_PAYMENT:
    case PROCESS_REFUND:
    case PROCESS_GOVOS_PAY_REFUND:
      return api(action, state, {
        pending: () => ({...state, isPaymentProcessPending: true}),
        success: () => ({...state, isPaymentProcessPending: false}),
        failure: () => ({...state, isPaymentProcessPending: false}),
      });
    case ASSIGN_USERS_TO_MULTIPLE:
    case UNASSIGN_USERS_TO_MULTIPLE:
    case ASSIGN_STAGES:
    case UNASSIGN_STAGES:
    case ASSIGN_TAGS_TO_MULTIPLE:
    case UNASSIGN_TAGS_TO_MULTIPLE:
    case FIND_AND_REPLACE_STAGES:
    case FIND_AND_REPLACE_TAGS:
    case FIND_AND_REPLACE_ASSIGNEES:
    case REPLACE_TAGS:
    case REPLACE_ASSIGNEES:
      return api(action, state, {
        success: () => ({
          ...state,
          isBulkProcessing: false,
        }),
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
          isBulkProcessing: false,
        }),
        pending: () => ({
          ...state,
          isBulkProcessing: action.payload.numOfSelected >= NUM_OF_SELECTED_SUBMISSIONS_TO_SHOW_BULK_PROCESSING,
          bulkProcessingState: {
            title: 'Bulk Processing...',
            bodyText: 'Please wait as this may take a few minutes. Do not refresh the browser.',
          },
        }),
      });
    case SHARE_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          isBulkProcessing: false,
          shareVisible: false,
        }),
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
          isBulkProcessing: false,
        }),
        pending: () => ({
          ...state,
          isBulkProcessing: action.payload.numOfSelected >= NUM_OF_SELECTED_SUBMISSIONS_TO_SHOW_BULK_PROCESSING,
          bulkProcessingState: {
            title: 'Sharing submissions...',
            bodyText: 'Please wait as this may take a few minutes. Do not refresh the browser.',
          },
        }),
      });
    default:
      return state;
  }
}

export const getIsPaymentProcessPending = state => state.isPaymentProcessPending;

export const getSearchbarInput = state => state.searchbarInput;

export const getSelectedSubmissionRowIndex = state => state.selectedRowIndex;
