import React from 'react';
import Bold from '@material-ui/icons/FormatBold';
import Italic from '@material-ui/icons/FormatItalic';
import Underlined from '@material-ui/icons/FormatUnderlined';
import FontColor from '@material-ui/icons/FormatColorText';
import InsertLink from '@material-ui/icons/Link';
import { IconButton, LabelButton } from './shared';


const inlineTypes = [
  { Icon: Bold, type: 'BOLD', tooltip: 'Bold' },
  { Icon: Italic, type: 'ITALIC', tooltip: 'Italic' },
  { Icon: Underlined, type: 'UNDERLINE', tooltip: 'Underline' },
  { Icon: InsertLink, type: 'LINK', tooltip: 'Link' },
  { Icon: FontColor, type: 'FONT_COLOR', tooltip: 'Font Color' },
];

const headerInlineTypes = [
  { label: 'H1', type: 'H1', tooltip: 'Heading 1' },
  { label: 'H2', type: 'H2', tooltip: 'Heading 2' },
  { label: 'H3', type: 'H3', tooltip: 'Heading 3' },
  { label: 'H4', type: 'H4', tooltip: 'Heading 4' },
  { label: 'H5', type: 'H5', tooltip: 'Heading 5' },
  { label: 'H6', type: 'H6', tooltip: 'Heading 6' },
];


const isActive = (type, props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  switch (type) {
    case 'LINK':
      return props.isLinkToggled;
    case 'FONT_COLOR':
      return props.isFontColorPickerToggled;
    default:
      return currentStyle.has(type);
  }
};

export const Inline = ({ onToggle, ...props }) => (
  <span role='toolbar' aria-label='Text formatting'>
    {inlineTypes.map(({ Icon, tooltip, type }) => (
      <IconButton
        key={type}
        active={isActive(type, props)}
        Icon={Icon}
        onToggle={onToggle}
        tooltip={tooltip}
        type={type}
        aria-label={tooltip}
      />
    ))}
  </span>
);

export const Header = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <span role='toolbar' aria-label='Header formatting'>
      {headerInlineTypes.map(({ label, tooltip, type }) => (
        <LabelButton
          key={type}
          active={currentStyle.has(type)}
          label={label}
          onToggle={onToggle}
          tooltip={tooltip}
          type={type}
          aria-label={tooltip}
        />
      ))}
    </span>
  );
};
