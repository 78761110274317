import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import LoginForm from './LoginForm/LoginForm';
import RedirectOnLogin from 'containers/RedirectOnLogin';
import { getAuthSubmissionState } from 'reducers/index';
import { redirectToNotFound } from 'utils/routing';
import * as utils from 'utils/auth';
import NewAuthLayout from 'components/AuthLayout/NewAuthLayout';
import { TERMS_OF_USE_URL } from 'constants/links';

import style from 'containers/Login/Login/Login.css';


class LoginPage extends React.Component<any, any> {
  componentDidUpdate() {
    const {location, maid, accountKey, dispatch} = this.props;
    if (maid && location.query.loginToken && location.query.uid) {
      utils.fakeLogin(
        location.query.loginToken,
        maid,
        accountKey,
        location.query.uid,
        dispatch,
      );
    }
  }

  shouldShowADButtons() {
    return !!this.props.activeDirectoryConnections.length;
  }

  redirectIfSubdomainDoesNotExists(isSubdomainExists) {
    if (isSubdomainExists === false) {
      redirectToNotFound();
    }
  }


  onSubmit = (email, password) => {
    const subdomain = this.props.accountKey ? this.props.accountKey : '';

    utils.signIn(
      {
        username: email,
        password,
        subdomain,
      },
      this.props.dispatch
    );
  };

  handleActiveDirectoryLogin = connection => {
    utils.activeDirectorySignIn(connection, this.props.dispatch);
  };

  render() {
    const {
      activeDirectoryConnections,
      error,
      isFetchingOrganizationInfo,
      location,
      companyName,
      organizationLogo,
      processingSubmission,
      isSubdomainExists,
      features,
    } = this.props;

    this.redirectIfSubdomainDoesNotExists(isSubdomainExists);

    const showADButtons = this.shouldShowADButtons();

    return (
      <React.Fragment>
        <RedirectOnLogin location={location} />

        <NewAuthLayout
          title='Welcome back'
          subtitle={
            showADButtons ? 'Log in with the Identity Provider below:' : ''
          }
          fetching={isFetchingOrganizationInfo || !isSubdomainExists}
          companyName={companyName}
          logo={organizationLogo}
        >
          <LoginForm
            activeDirectoryConnections={activeDirectoryConnections}
            handleActiveDirectoryLogin={this.handleActiveDirectoryLogin}
            isProcessing={processingSubmission}
            onSubmit={this.onSubmit}
            error={utils.processLoginError(error)}
          />

          {!features.SSO_LOGIN_ONLY && <div className={style.authLinksContainer}>
            <Link to='/ng/signup'>Don’t have an account?</Link>
            <Link to='/ng/forgot'>Forgot password?</Link>
            <Link to='/ng/accesscode'>Use Access Code?</Link>
          </div>}

          <div className={style.termsContainer}>
            {features.SSO_LOGIN_ONLY ? 'By logging into your account' : 'By creating an account'}, you agree to our{' '}
            <a
              className={style.termsLink}
              href={TERMS_OF_USE_URL}
              target='_blank'
            >
              Terms of Use
            </a>
            .
          </div>
        </NewAuthLayout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      accountKey,
      isFetchingOrganizationInfo,
      error,
      activeDirectoryConnections,
      companyName,
      organizationLogo,
      isSubdomainExists,
      maid,
    },
    features,
  } = state;
  return {
    error,
    isFetchingOrganizationInfo,
    companyName,
    activeDirectoryConnections,
    accountKey,
    organizationLogo,
    processingSubmission: getAuthSubmissionState(state),
    isSubdomainExists,
    features,
    maid,
  };
};

export default connect(mapStateToProps)(LoginPage);
