import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { LabelAlignment, ShowLabelValue } from 'types/liveView';
import { getAlignmentClass, showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import style from './SurveyMatrix.css';
import sharedStyle from '../shared.css';

type MatrixElement = {
  id: string,
  value: string,
};

interface Props {
  id: string,
  elementType: string,
  label?: string,
  fieldState: any,
  updateForm?: Function,
  hoverText?: string,
  labelCssClass?: string,
  specialSettings?: {
    align: 'left' | 'right' | 'center',
    columns: string[],
    rows: string[],
    labelAlign: LabelAlignment,
    selectMultiple: boolean,
  },
  extraData?: {
    showLabel: ShowLabelValue,
  }
}

const getEmptyMatrix = (rows: string[]): MatrixElement[][] =>
  new Array(rows.length).fill([]).map((a, i) => [{ value: rows[i], id: 'colname' }]);

const SurveyMatrix = ({
  id,
  elementType,
  label = '',
  fieldState = null,
  updateForm = undefined,
  hoverText = '',
  labelCssClass = sharedStyle.FormLabel,
  specialSettings = {
    align: 'left',
    columns: [],
    rows: [],
    labelAlign: 'auto',
    selectMultiple: false,
  },
  extraData = {
    showLabel: 't',
  },
}: Props) => {
  const [selected, setSelected] =
    useState<MatrixElement[][]>(fieldState?.fields[label] || getEmptyMatrix(specialSettings?.rows || []));

  useEffect(() => {
    const s = {
      fields: {
        [label]: selected,
      },
      extraData: {
        elementType,
      },
    };
    updateForm && updateForm(s);
  }, [selected]);

  const handleChoiceChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const { checked, value } = e.target;
    if (specialSettings?.selectMultiple) {
      const newValue: MatrixElement[] = checked ?
        [...selected[index], { id: e.target.id, value }] :
        selected[index].filter(v => v.id !== e.target.id);
      setSelected(selected.map((val: MatrixElement[], i: number) => index === i ? newValue : val));
    } else {
      setSelected(selected.map((val: MatrixElement[], i: number) =>
        index === i ? [val[0], {id: e.target.id, value}] : val));
    }
  };

  const renderCell = (
    i: number,
    j: number,
    row: string,
    col: string,
  ): JSX.Element => <td key={j} className={style.select}>
    <label htmlFor={`${id}_${i}_${j}_${col}`} style={{ display: 'none' }}>{col}</label>
    <input
      type={specialSettings?.selectMultiple ? 'checkbox' : 'radio'}
      value={col}
      id={`${id}_${i}_${j}_${col}`}
      name={`${id}_${i}`}
      onChange={e => handleChoiceChange(e, i)}
      aria-label={`${row} / ${col}`}
      checked={!!selected.flat().find(v => v.id === `${id}_${i}_${j}_${col}`)}/>
  </td>;

  return (
    <div className={style.surveyMatrixContainer}>
      {label && showLabelToBoolean(extraData?.showLabel) &&
        <label
          aria-label={label}
          className={classNames(
            labelCssClass,
            getAlignmentClass(specialSettings?.labelAlign || 'auto', sharedStyle))}
        >{label}</label>
      }
      <Tooltip
        title={hoverText}
        placement='top'
        disabled={!hoverText}>
        <table id={id} role='presentation' aria-label={label || 'Survey matrix'}>
          <tbody>
            <tr>
              <td/>
              {specialSettings?.columns.map((col, index) =>
                <th
                  key={index}
                  className={classNames(style.col, style.th)}>
                  {col}
                </th>)}
            </tr>
            {specialSettings?.rows.map((row, i) => (<tr key={i}>
              <th style={{textAlign: specialSettings?.align}} className={style.th}>{row}</th>
              {specialSettings?.columns.map((col, j) =>
                renderCell(i, j, row, col))}
            </tr>))}
          </tbody>
        </table>
      </Tooltip>
    </div>
  );
};

export default React.memo(forwardRef(SurveyMatrix));
