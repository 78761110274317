import api from './api';
import { TOGGLE_NG_LIVE_VIEW_API_CALL } from 'constants/types/ngSettings';

export default function ngLiveSettings(state = {status: false}, action:any) {
  switch (action.type) {
    case TOGGLE_NG_LIVE_VIEW_API_CALL:
      return api(action, state, {
        success: () => window.location.reload(),
      });
    default:
      return state;
  }
}
