import React from 'react';
import injectPermissions from 'decorators/injectPermissions';
import Tree from 'components/Tree';
import * as VFS from 'utils/vfs';
import cssStyle from './folder-tree.css';
import Node from './Node';


export class FolderTree extends React.Component<any, any> {
  static defaultProps = {
    levelIndent: 28,
  };

  createNode = (tree, path) => {
    const {
      currentNode,
      levelIndent,
      onClick,
      onCollapse,
      onCreateFolder,
      onDeleteFolder,
      onDrop,
      onExpand,
      onNameChange,
      canUserEditFolderTree,
    } = this.props;

    const level = path.length - 1; // level start from 0
    const isSelected = currentNode.meta.folderId === tree.meta.folderId;
    return (
      <Node
        tree={tree}
        indent={level * levelIndent}
        isSelected={isSelected}
        path={path}
        onClick={onClick}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onCreateFolder={level === 0 ? onCreateFolder : null}
        onDrop={onDrop}
        onDeleteFolder={onDeleteFolder}
        onNameChange={onNameChange}
        canEditFolderTree={canUserEditFolderTree()}
        editable
        draggable
      />
    );
  };

  createArchiveNode = (tree, path) => {
    const { onClick, onExpand, onCollapse, currentNode, onArchive } = this.props;

    const isSelected = currentNode.meta.folderId === tree.meta.folderId;

    return (
      <Node
        tree={tree}
        indent={0}
        isSelected={isSelected}
        path={path}
        onClick={onClick}
        onExpand={onExpand}
        onCollapse={onCollapse}
        onDrop={onArchive}
        draggable={false}
      />
    );
  };

  createArchive = () => {
    const { archive } = this.props;
    return (
      <Tree
        tree={archive}
        createNode={this.createArchiveNode}
        hasSubTree={() => false}
        path={(['', archive.name])}
      />
    );
  };

  hasSubTree = tree => tree.type === VFS.VFS_DIR && !tree.meta.collapsed;

  render() {
    const { canUserAccessFolderTree, canUserReadArchivedForms } = this.props;
    return (
      <div className={cssStyle.folderTree} data-testid='folderTreeContainer'>
        {canUserAccessFolderTree() && (
          <React.Fragment>
            <div className={cssStyle.treeDivider} />
            <Tree
              tree={this.props.root}
              onCreateFolder={this.props.onCreateFolder}
              createNode={this.createNode}
              hasSubTree={this.hasSubTree}
            />
            <div className={cssStyle.treeDivider} />
          </React.Fragment>
        )}
        {canUserReadArchivedForms() && this.createArchive()}
      </div>
    );
  }
}

export default injectPermissions(FolderTree);
