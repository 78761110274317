import {
  SAVE_DIGITAL_SERVICE_FEES,
  GET_DIGITAL_SERVICE_FEES,
  SET_FEE_ACTIVE_STATUS,
  GET_FORM_FIELDS_ALL,
} from 'constants/types/digitalServiceFeeActionTypes';
import api from 'reducers/api';
import { DigitalServiceFees } from 'types/digitalServiceFees';
import { FormFields } from 'types/formFields';


export const defaultFees: DigitalServiceFees = {
  id: null,
  digitalServiceFeeEnabled: false,
  idVerificationFeeAmount: 0,
  digitizationFlatFeeAmount: 0,
};

type State = {
  digitalServiceFees: DigitalServiceFees;
  fields: FormFields;
  isDigitalServiceFeesLoading: boolean;
}

export const initialState: State = {
  digitalServiceFees: defaultFees,
  fields: [],
  isDigitalServiceFeesLoading: false,
};

export default function paymentConfiguration(state: State = initialState, _action): State {
  const action = _action;
  switch (action.type) {
    case SET_FEE_ACTIVE_STATUS:
      return {
        ...state,
        digitalServiceFees: { ...state.digitalServiceFees, digitalServiceFeeEnabled: action.payload },
      };
    case SAVE_DIGITAL_SERVICE_FEES:
      return api(action, state, {
        pending: () => ({ ...state, isDigitalServiceFeesLoading: true }),
        failure: () => ({ ...state, isDigitalServiceFeesLoading: false }),
        success: () => ({ ...state, isDigitalServiceFeesLoading: false }),
      });
    case GET_DIGITAL_SERVICE_FEES:
      return api(action, state, {
        pending: () => ({ ...state, isDigitalServiceFeesLoading: true }),
        failure: () => ({ ...state, isDigitalServiceFeesLoading: false }),
        success: () => ({ ...state, isDigitalServiceFeesLoading: false, digitalServiceFees: action.payload.config }),
      });
    case GET_FORM_FIELDS_ALL:
      return api(action, state, {
        success: (): State => {
          const formFields = action.payload?.formFields || [];
          return {
            ...state,
            fields: formFields,
          };
        },
        failure: () => ({...state}),
        pending: () => ({...state}),
      });
    default:
      return state;
  }
}

export const getIsDigitalServiceFeesLoading = (state: State): boolean => state.isDigitalServiceFeesLoading;

export const getDigitalServiceFees = (state: State): DigitalServiceFees => state.digitalServiceFees;

export const getFormFields = (state: State): FormFields => state.fields;
