import React from 'react';
import styles from './icons.css';
import AccessibleSVG from 'components/AccessibleSVG';

// type Props = {
//   onClick: () => void,
// };

const HamburgerIcon = props => (
  <button {...props} className={styles.hamburgerContainer}>
    <AccessibleSVG className={styles.centeredIcon} width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg' title='Expand sidebar' aria-label='Expand sidebar'>
      <line x1='1.15674' y1='0.888184' x2='18.4142' y2='0.888184' stroke='#6D7D8F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='1.15674' y1='7.2146' x2='10.1699' y2='7.2146' stroke='#6D7D8F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <line x1='1.15674' y1='13.541' x2='18.4142' y2='13.541' stroke='#6D7D8F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </AccessibleSVG>
  </button>
);

export default HamburgerIcon;
