import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import classNames from 'classnames';
import FormInput from '../FormInput/FormInput';
import sharedStyle from '../shared.css';
import style from './FormChoiceGroup.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import { ControlState, LabelAlignment, ShowLabelValue } from 'types/liveView';
import { getAlignmentClass, showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import Tooltip from 'components/Tooltip';

type SelectedOption = {
  label: string,
  value: string,
}

type Props = {
  id: string,
  elementType: string,
  label?: string,
  options?: any[],
  selected?: any,
  multiple: boolean,
  updateForm?: Function | null,
  onChange?: Function | null,
  validationType?: string,
  showHideCondition?: any,
  fieldState?: ControlState | null,
  extraData?: {
    alignment?: string,
    label?: string,
    labelAlign: LabelAlignment,
    showLabel?: ShowLabelValue,
    twoColumns: 'true' | 'false',
    readonly: 't' | 'f',
  },
  cssClass?: string,
  required?: boolean,
  showOtherOption: boolean,
  hoverText: string,
  isDisabled: boolean,
}

function FormChoiceGroup({
  id,
  elementType,
  label = 'Choice Group',
  options = [] as any[],
  selected = [],
  multiple = false,
  updateForm = null,
  onChange = null,
  fieldState,
  required,
  extraData = {
    showLabel: 't',
    twoColumns: 'false',
    labelAlign: 'auto',
    readonly: 'f',
  },
  showOtherOption = false,
  cssClass = sharedStyle.FormControlGroup,
  hoverText = '',
  isDisabled = false,
}: Props, ref) {
  const otherValue: string = `other_choice_${id}`;
  const otherTextId: string = `other_choice_text_${id}`;
  const otherTextRef: React.MutableRefObject<null> = useRef(null);
  const [selectedValues, setSelectedValues] = useState<SelectedOption[]>(fieldState?.fields?.selected || selected);
  const [otherTextValue, setOtherTextValue] =
    useState<string>(fieldState?.fields?.selected?.find(v => v.value === otherValue)?.label || '');
  const [error, setError] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);
  const requiredMsg: string = multiple ? 'Please check at least 1 box(es)' : 'Please select an option';
  const isTwoColumns: boolean = extraData.twoColumns === 'true' ? true : false;
  const isReadOnly: boolean = extraData.readonly === 't' ? true : false;

  const selfValidate = (): boolean => {
    if (required && (!selectedValues || selectedValues.length === 0)) {
      setError(requiredMsg);
      return false;
    }

    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  useEffect(() => {
    touched && selfValidate();
  }, [selectedValues]);

  const handleChoiceChange = (event: React.ChangeEvent<HTMLInputElement>, lbl: string): void => {
    const { value, checked } = event.target;
    setTouched(true);
    if (!multiple) {
      setSelectedValues([{ value, label: lbl }]);
    } else {
      const values: SelectedOption[] = checked
        ? Array.from(new Set([{ value, label: lbl }, ...selectedValues]))
        : selectedValues.filter(v => v.value !== value);
      setSelectedValues(values);
    }
    onChange && onChange(event);
  };

  const handleOtherChoice = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleChoiceChange(event, otherTextValue);
    if (event.target.checked) {
      setTimeout(() => { // wait for disabled state to be removed by react render
        // @ts-ignore
        otherTextRef.current.focus();
      }, 100);
    } else {
      setOtherTextValue('');
    }
  };

  const onOtherChangeHandler = (newVal: string): void => {
    setOtherTextValue(newVal);
    const newSelectedValues: SelectedOption[] = selectedValues.map(v =>
      v.value === otherValue ? { value: otherValue, label: otherTextValue } : v);
    setSelectedValues(newSelectedValues);
  };

  useEffect(() => {
    const fields = { selected: selectedValues };
    updateForm && updateForm({
      fields,
      extraData: {
        elementType,
      },
      calculations: options.filter(o =>
        selectedValues.find(v => v.value === o.originalElementId || v.value === o.value))
        .reduce((obj, o) => {
          if (o.calculation === null) return obj;
          obj[o.originalElementId || o.value] = o.calculation;
          obj[`total_${id}`] += o.calculation;
          return obj;
        }, {[`total_${id}`]: 0}),
    });
  }, [selectedValues]);

  const renderSingleOption = ({ value, label: l, calculation, originalElementId }): JSX.Element => {
    const key = originalElementId || value;
    return (
      <div key={value} className='choice_container'>
        <input
          className={classNames(style.ChoiceInput, 'choice_input')}
          onChange={e => handleChoiceChange(e, l)}
          checked={!!selectedValues.find(v => v.value === key)}
          id={key}
          name={id}
          type={multiple ? 'checkbox' : 'radio'}
          value={key}
          disabled={isDisabled || isReadOnly}
          data-calc-value={calculation}
        />
        <label
          className={classNames(style.ChoiceLabel, 'choice_label')}
          htmlFor={key}>
          {l}
        </label>
      </div>
    );
  };

  const renderOtherOption = (): JSX.Element | null => {
    if (!showOtherOption) { return null; }
    const isSelected = !!selectedValues.find(v => v.value === otherValue);
    return (
      <div>
        <input
          className={style.OtherChoiceInput}
          type={multiple ? 'checkbox' : 'radio'}
          value={otherValue}
          onChange={handleOtherChoice}
          checked={isSelected}
          aria-label='Other option'
        />
        <div className={style.OtherChoiceInputContainer}>
          <FormInput
            ref={otherTextRef}
            disabled={!isSelected || isDisabled || isReadOnly}
            showLabel={false}
            id={otherTextId}
            type='text'
            value={otherTextValue}
            onChange={e => onOtherChangeHandler(e.target.value)}
            extraData={{ placeholder: 'Other', readonly: extraData.readonly }}
          />
        </div>
      </div>
    );
  };
  const renderOptions = (): JSX.Element[] => options.map(renderSingleOption);

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'form_control_group')} id={`container_${id}`}>
        <fieldset className={classNames(sharedStyle.Fieldset, 'choice_group')} id={id}>
          { showLabelToBoolean(extraData?.showLabel) &&
            <legend className={classNames({ [sharedStyle.Required]: required }, getAlignmentClass(extraData?.labelAlign, sharedStyle), 'choice_group_label')}>{label}</legend>
          }
          <ErrorContainer error={error}>
            <div className={classNames(style.OptionsContainer, {[style.TwoColumns]: isTwoColumns})}>
              {renderOptions()}
              {renderOtherOption()}
            </div>
          </ErrorContainer>
        </fieldset>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormChoiceGroup));
