import {
  AtomicBlockUtils,
  EditorState,

} from 'draft-js';
import {composeDecorators} from 'draft-js-plugins-editor';
import createFocusPlugin from 'draft-js-focus-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createAlignmentPlugin from './alignmentPlugin';
import createUnstyledNewLinePlugin from './unstyledNewLinePlugin';


export const addImage = (editorState, src, width) => {
  // Copypasta of `draft-js-image-plugin/src/modifiers/addImage`, updated to accept a starting width.
  const urlType = 'image';
  const newContentState = editorState.getCurrentContent().createEntity(urlType, 'IMMUTABLE', {src, width});
  const entityKey = newContentState.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    ' '
  );
  return EditorState.forceSelection(
    newEditorState,
    editorState.getCurrentContent().getSelectionAfter()
  );
};


export default ({ onImageBlur, onImageFocus }) => {
  const alignment = createAlignmentPlugin({
    onBlur: onImageBlur,
    onFocus: onImageFocus,
  });
  const focus = createFocusPlugin();
  const resizeable = createResizeablePlugin({horizontal: 'absolute', vertical: 'auto'});
  const image = createImagePlugin({
    decorator: composeDecorators(
      alignment.decorator,
      resizeable.decorator,
      focus.decorator
    ),
  });
  const unstyledNewLine = createUnstyledNewLinePlugin({
    affectedTypes: ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  });
  return {
    alignment, image, focus, resizeable, unstyledNewLine,
  };
};
