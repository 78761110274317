import React, { memo, useCallback, lazy, Suspense } from 'react';
import styles from '../govOsPay.css';
import { GovOSPayAmounts, GovOsPaymentObject, GovOSPayModalOpenHandlers, PaymentType } from 'types/govOsPay/govOsPaymentSettings';
import { getFilteredPaymentObject, getTransactionDataForInvoiceSummaryModal } from 'utils/govOSPay/govOsPay';
import OverlayModalContainer from '../OverlayModalContainer';

const InvoiceSummary = lazy(() => import('@kofile/platform-react-payrix').then(module => ({ default: module.InvoiceSummary })));

type SummaryModalProps = {
  accountName: string;
  amounts: GovOSPayAmounts;
  closeModal: () => void;
  openGovOSPaymentModalHandlers: GovOSPayModalOpenHandlers
  paymentObjects: GovOsPaymentObject[]
  selectedPaymentSummaryType: PaymentType | null;
}

const REFUND_POLICY_LINK = 'https://help.seamlessdocs.com/en/articles/9659753-refund-policy';
const SUPPORT_CHANNELS_LINK = 'https://help.seamlessdocs.com/en/articles/9659758-support-channels';

const SummaryModal = ({
  accountName,
  amounts,
  closeModal,
  openGovOSPaymentModalHandlers: {
    openGovOSACHPaymentModal,
    openGovOSCardPaymentModal,
  },
  paymentObjects,
  selectedPaymentSummaryType,
}: SummaryModalProps) => {
  const summary = getFilteredPaymentObject(paymentObjects, selectedPaymentSummaryType);

  const openPaymentModalHandler = useCallback(() => {
    if (selectedPaymentSummaryType === 'ach') {
      openGovOSACHPaymentModal();
    }
    if (selectedPaymentSummaryType === 'credit_card') {
      openGovOSCardPaymentModal();
    }
  }, [selectedPaymentSummaryType, openGovOSACHPaymentModal, openGovOSCardPaymentModal]);

  if (!selectedPaymentSummaryType) return null;

  return (
    <OverlayModalContainer open={selectedPaymentSummaryType !== null} closeModal={closeModal} isSummaryModal>
      <Suspense fallback={<div>{''}</div>}>
        <h2 id='modal-header' className={styles.visuallyHidden}>Invoice Summary</h2>
        <InvoiceSummary
          transactionData={getTransactionDataForInvoiceSummaryModal(summary, amounts, accountName)}
          onSubmit={openPaymentModalHandler}
        />
      </Suspense>
      <div className={styles.summaryLinksContainer}>
        <a
          href={REFUND_POLICY_LINK}
          aria-label='Read our Refund Policy'
          target='_blank'
          rel='noopener noreferrer'
        >
          Refund Policy
        </a>
        <a
          href={SUPPORT_CHANNELS_LINK}
          aria-label='Visit our Support Channels'
          target='_blank'
          rel='noopener noreferrer'
        >
          Support Channels
        </a>
      </div>
    </OverlayModalContainer>
  );
};

export default memo(SummaryModal);
