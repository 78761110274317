import React, { ChangeEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import style from './FormUpload.css';
import sharedStyle from '../shared.css';
import Icon from '@material-ui/core/Icon';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import { getAlignmentClass, showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import { LabelAlignment, ShowLabelValue } from 'types/liveView';

const FILE_SIZE_LIMIT = 262144000;

function getUploadItems(files, attachments) {
  const items = {};
  files.forEach(file => {
    items[file.name] = {
      file,
      displayName: file.name,
      link: URL.createObjectURL(file),
      isImage: file.type.includes('image/'),
    };
  });

  attachments.forEach(att => {
    const name = att.fileName;
    const item = items[name] || {};
    item.attachment = att;
    item.displayName = name;
    item.link = att.url;
    item.isImage = /.(png|jpg|jpeg|tiff|gif)$/.test(att.url.toLowerCase());
    items[name] = item;
  });
  return Object.values(items);
}
const acceptedExtensions = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .txt';
const extensionRegExp = new RegExp(`(${acceptedExtensions.split(', ').join('|')})$`);
function validateFileExtensions(files: File[]) {
  return files.every(f => extensionRegExp.test(f.name));
}
const validateFileSizes = (files: File[]) => files.every(f => f.size < FILE_SIZE_LIMIT);

const invalidCharactersRegExp = /[\\\/~"#%&*:<>?{|}]/g;
function replaceInvalidCharacters(fileName: string) {
  return fileName.replace(invalidCharactersRegExp, '_');
}
function renameFile(file: File) {
  const name = replaceInvalidCharacters(file.name);
  return new File([file], name, { type: file.type });
}

const FileLine = ({ item, onRemove }) => (
  <div className={style.FileLineContainer}>
    {item.isImage ?
      <img src={item.link} className={style.FilePreview} alt={item.displayName} /> :
      <div className={classNames(style.FilePreview, style.DefaultPreview)} />
    }
    <div className={style.FileLine}>
      <div className={style.FileLineName}>
        {item.attachment
          ? (<a target='_blank' href={item.link}>{item.displayName}</a>)
          : item.displayName}
      </div>
      <div className={style.FileLineSize}>{item.file ? `${item.file?.size} Bytes` : null}&nbsp;</div>
    </div>
    <div className={style.FileLineRemove} onClick={onRemove} data-testid='remove-file-icon'><Icon style={{ fontSize: '20px' }}>delete</Icon></div>
  </div>
);

const Upload = ({ onUploadAttachment, onRemoveAttachment, attachments, error, setError }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any[]>([]);
  const items = getUploadItems(files, attachments);

  const initUpload = uploadFiles => {
    if (uploadFiles && uploadFiles.length) {
      uploadFiles.forEach(onUploadAttachment);
    }
  };

  const doUpload = (uploads: File[]) => {
    if (!validateFileExtensions(uploads)) {
      setError('File(s) rejected. Format not allowed.');
      return;
    }
    if (!validateFileSizes(uploads)) {
      setError('File(s) rejected. File size should not exceed 250 MB.');
      return;
    }
    setError('');
    const renamedUploads = uploads.map(renameFile);
    initUpload(renamedUploads);
    setFiles([...files, ...renamedUploads]);
    if (fileRef.current) {
      fileRef.current.value = ''; // reset files in html input to allow re-upload of deleted files
    }
  };

  const uploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const uploads = Array.from(event.target.files || []);
    doUpload(uploads);
  };

  const selectFiles = useCallback(event => {
    try {
      event.preventDefault();
      event.stopPropagation();
      fileRef.current?.click();
    } catch (err) {
      datadogLogs.logger.error('FILE-UPLOAD: Select Files clicked error: ' + err.message);
    }
  }, [fileRef]);

  const onRemove = item => event => {
    event.preventDefault();
    onRemoveAttachment([item.attachment || {fileName: item.file.name}]);
    setFiles(files.filter(file => file !== item.file));
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const uploads = Array.from(event.dataTransfer.files);
    doUpload(uploads);
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element: EventTarget = event.target;
    if (element instanceof HTMLInputElement) {
      element.value = '';
    }
    // element.value = '';
  };

  const renderFileList = () => {
    if (!items.length) return null;
    return (<div>
      <div className={style.FilesContainer}>
        {items.map((item, key) => (
          <FileLine key={key} item={item} onRemove={onRemove(item)} />
        ))}
      </div>
    </div>);
  };

  return (
    <div className={classNames(style.FormUploadContainer, 'upload_file_container')}>
      <ErrorContainer error={error}>
        <div
          className={style.FormUploadDroppable}
          onDrop={onDrop}
          onDragOver={event => {
            event.preventDefault();
          }}
        >
          <div className={style.LargeText}>Drag and drop files onto this window to upload</div>
          <div className={style.SmallText}> - OR - </div>
          <button
            type='button'
            onClick={selectFiles}
            className={classNames(style.SelectFilesButton, 'upload_file_select_button')}>
            Select Files to Upload
          </button>
        </div>
      </ErrorContainer>
      <input
        type='file'
        className={style.FormUploadInput}
        ref={fileRef}
        onChange={uploadFiles}
        accept={acceptedExtensions}
        multiple
        title='Choose files' // avoid WCAG error
        onClick={onInputClick}
      />
      {renderFileList()}
    </div>
  );
};

type Props = {
  id: string,
  label?: string,
  name?: string,
  fieldState,
  updateForm,
  uploadAttachment,
  removeAttachments,
  cssClass?: string,
  labelCssClass?: string,
  attachments?: any[],
  extraData?: {
    showLabel: ShowLabelValue,
    alignment?: string,
    label?: string,
    labelAlign: LabelAlignment,
  },
}

const FormUpload = ({
  id,
  label,
  name,
  fieldState,
  updateForm,
  uploadAttachment,
  removeAttachments,
  cssClass = sharedStyle.FormControlGroup,
  labelCssClass = sharedStyle.FormLabel,
  attachments = [],
  extraData = {
    showLabel: 't',
    labelAlign: 'auto',
  },
  ...props
}: Props & Partial<any>, ref) => {
  const [error, setError] = useState('');

  useEffect(() => {
    setTimeout(() => setError(''), 3000);
  }, [error]);

  const selfValidate = () => {
    if (props.required && attachments.length === 0) {
      setError('This field is required.');
      return false;
    }
    setError('');
    return true;
  };

  useEffect(() => {
    if (attachments.length > 0) {
      selfValidate();
    }
  }, [attachments]);

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  return (
    <div id={id} className={classNames(cssClass, 'form_control_group', 'upload_container')}>
      { showLabelToBoolean(extraData?.showLabel) && <label
        aria-label={label}
        className={classNames(labelCssClass, { [sharedStyle.Required]: props.required }, 'upload_label', getAlignmentClass(extraData.labelAlign, sharedStyle))}>
        {label ? label : 'Upload File(s)'}
      </label> }
      <Upload
        onUploadAttachment={uploadAttachment}
        onRemoveAttachment={removeAttachments}
        attachments={attachments}
        error={error}
        setError={setError}
      />
    </div>
  );
};

export default React.memo(forwardRef(FormUpload));
